import { greetActions } from "../../features/greet/greetSlice";
import { useAppDispatch, useAppSelector } from "../store";
import SelectFx, { ISelectFxProps } from "./SelectFx/SelectFx";

const SelectFxWithState = (opitons: any ,props: ISelectFxProps) => {

    const spotTop  =  useAppSelector(state => state.greet.spotTop);
	const dispatch = useAppDispatch();
	
    return (<SelectFx selected={spotTop.toString()} onChange={e => { 
        const  mx = e ? e : spotTop.toString();        
        dispatch(greetActions.setSpotTop(mx));        
        }
    } options={[
        { value:'100', text:'Spot Top 100'},
        { value:'200', text:'Spot Top 200'},
        { value:'500', text:'Spot Top 500'}]} />)
}

export default SelectFxWithState;