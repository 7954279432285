
import { useCallback, useEffect, useRef, useState } from "react";

enum iTradeType {
    OpenLog = 0,
    OpenShort = 1,
    CloseLong = 2,        
    CloseShort = 3,
    Funding = 13,
	CloseLongStop = 22,
	CloseShortStop = 23,
	CloseLongLiq = 32,
	CloseShortLiq = 33,

}


const TradeTypeText = ({tradeType} : {tradeType: number}) => {
    // Using a switch statement to handle each case
    switch (tradeType) {
        case iTradeType.OpenLog:
            return <span>Open Log</span>;
        case iTradeType.OpenShort:
            return <span>Open Short</span>;
        case iTradeType.CloseLong:
            return <span>Close Long</span>;
        case iTradeType.CloseShort:
            return <span>Close Short</span>;
        case iTradeType.Funding:
            return <span>Funding</span>;
        case iTradeType.CloseLongStop:
            return <span>Close Long Stop</span>;
        case iTradeType.CloseShortStop:
            return <span>Close Short Stop</span>;
        case iTradeType.CloseLongLiq:
            return <span>Close Long Liquidation</span>;
        case iTradeType.CloseShortLiq:
            return <span>Close Short Liquidation</span>;
        default:
            return <span>Unknown Trade Type</span>;
    }
};


export { iTradeType, TradeTypeText };

