import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CandleChangeRow, CurrencyInfo, GetApiGreetGetPairChangeResp, useGetApiGreetGetPairChange2ByExchangeQuery } from "../api/greetApi";
import SelectFx from "../components/SelectFx/SelectFx";
import PriceDisplay from "../components/PriceDisplay";
import CurrencyView from "../components/CurrencyView";
import { debounce } from 'lodash';
import { useFetch } from "usehooks-ts";
import { Link } from "react-router-dom";
import DebounceInput from "../components/DebounceInput";
import { useAppDispatch, useAppSelector } from "../store";
import { greetActions } from "../../features/greet/greetSlice";
import SelectFxWithState from "../components/SelectFxWithState";

import { RestClientV5 as bybitFutures } from 'bybit-api';
import { string } from "zod";
import useBodyClassnames from "../components/useBodyClassnames";
import useSound from "use-sound";
//const alarm = require("../components/alarm.mp3");
import alarm from '../components/alarm.mp3';
import PageHeading from "../components/pageHeading/PageHeading";


interface ApiInfo {
	ApiKey:string;
	ApiSecret:string;	
}

const CurrenciesChangePage = () => {


	
	const OpenPosition = (_symbol:string, _qty:string, _side: 'Buy' | 'Sell') => {
/*	
		var client = new bybitFutures({ key: bybitApiCred.ApiKey, secret: bybitApiCred.ApiSecret });
	
		client.submitOrder({
			category: 'linear',
			symbol: _symbol,
			orderType: 'Market',
			qty: _qty,			
			side: _side,
		 });
*/
	}

	const [ bybitApiCred, setBybitApiCred ] = useState<ApiInfo>({ ApiKey:"", ApiSecret: ""});

	const [searchTerm, setSearchTerm] = useState("Bybit");

	const [sortField, setSortField] = useState("bucket"); //
	const [sortDir, setSortDir] = useState(true); //trıu asc
		
	const { data, isLoading, isError, isSuccess, error, isFetching } =  useGetApiGreetGetPairChange2ByExchangeQuery({ exchangeName: searchTerm }, 
		{
			pollingInterval:1000,
			refetchOnMountOrArgChange:true
		  });

	useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);

	useEffect( ()=> {

		console.log("new term ", searchTerm)
	},[searchTerm])
	
    return(
        <main className="site-content site-content--center page" id="wrapper">
            <div className="container container--large">
                <div className="page-heading page-heading--default">                
					<PageHeading title="Coin" subTitle="Live Changes" smallSubTitle={true}>
						<span className="text-muted" style={{'fontSize':'1rem'}}>There is a delay of around 1-2 mintues for some exchanges</span>
					</PageHeading>
                </div>
                

				<div className="row">
					<div className="col-sm text-start">
						<div className="form-group" style={{'background':'#13151e'}}>
							<label className="control-label" htmlFor="input-default">Search</label>							
							<DebounceInput onChange={e =>  setSearchTerm(e)} placeHolder="search" value={searchTerm} bounce={100} key={'a'} />
						</div>
						</div>
					<div className="col-sm text-end">
						<button className="btn btn-sm btn-secondary position-relative">Data provided by Exchanges</button>
					</div>
				</div>

			
			{false && isSuccess && <div className="alert alert-success" role="alert">refresh automatically...</div>}
			{isError && <div className="alert alert-danger d-flex align-items-center" role="alert">Something went wrong, please try again later!</div>}
			<div className="table-responsive mb-sm-auto">
			<table className="table matches-table standings-table">
				<thead>
					<tr>
						<th style={{minWidth:'200px'}} onClick={e => { if(sortField!="bucket") setSortField('bucket'); else setSortDir(!sortDir);   }   }>Date/Time</th>
						<th onClick={e => { if(sortField!="PairName") setSortField('PairName'); else setSortDir(!sortDir);   }}>Pair</th>
						<th onClick={e => { if(sortField!="ExchangeName") setSortField('ExchangeName'); else setSortDir(!sortDir);   } }>Exchange</th>
						<th onClick={e => { if(sortField!="last_close") setSortField('last_close'); else setSortDir(!sortDir);   } }>Price</th>
						<th>Open</th>						
						<th>High</th>
						<th>Low</th>
						<th>Diff Op/Cl</th>
						<th>Diff Hi/Lo</th>
					</tr>
				</thead>
				<tbody>
					{data && data.data?.length == 0 && <tr><td colSpan={10} className="text-center">There is no data found at {new Date().toLocaleString()}</td></tr> }
					{data && data.data?.filter( (t:CandleChangeRow) => 
						(t.pairName && t.pairName.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())>-1) ||
						(t.exchangeName && t.exchangeName.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())>-1) || searchTerm==="")
						.sort( (a:any, b:any ) => 
								sortField=="PairName" ?  sortDir ?  a[sortField].localeCompare(b[sortField]) :  b[sortField].localeCompare(a[sortField]) :
								sortDir ? a[sortField] - b[sortField] : b[sortField] - a[sortField]  )
						.map( (cur: CandleChangeRow) =>  <MemoLiveChangeRow key={cur.intv+''+cur.bucket+''+cur.exchangeName+''+cur.pairName} cur={cur} /> )
					}
					</tbody>
			</table>			
		</div>
            </div>
        </main>
    )

}


  

const MemoLiveChangeRow = memo(({ cur } : {cur: CandleChangeRow}) => {

	useEffect(() => {
		return () => {
		  console.log("cleaned up", cur);
		};
	  }, []);

	
	  
	const openExternal = (u:string)  => window.open(u,"_blank");
	//openExternal('https://www.bybit.com/trade/usdt/'+cur.pairName?.replace('/',''))
	const [play, {duration }] = useSound(alarm, { volume: 0.9 });
	
		console.log("playing for = ", cur);
		play();
	

	return (<tr>
		<td>{cur.bucket && new Date(cur.bucket).toLocaleString()}</td>
		<td>{cur.pairName} ({cur.intv})</td>								
		<td>
			<span onClick={e => openExternal('https://www.bybit.com/trade/usdt/'+cur.pairName?.replace('/',''))}  className="bi bi-box-arrow-up-right"> {' '} {cur.exchangeName}</span>
		</td>
		<td><PriceDisplay pre={true} sign={'$'} value={cur.last_close} /></td>
		<td><PriceDisplay pre={true} sign={'$'} value={cur.minopen} /></td>																
		<td><PriceDisplay pre={true} sign={'$'} value={cur.maxhigh} /></td>
		<td><PriceDisplay pre={true} sign={'$'} value={cur.minlow} /></td>
		<td>{cur.op_cl_diff && cur.op_cl_diff.toFixed(2)}%</td>
		<td>{cur.hi_low_diff && cur.hi_low_diff.toFixed(2)}%</td>
	</tr>);
}, (a:any,b: any) => {	
	return a.cur.intv === b.cur.intv && a.cur.bucket === b.cur.bucket && a.cur.exchangeName === b.cur.exchangeName && a.cur.pairName === b.cur.pairName

});


export default CurrenciesChangePage;
