import { Link } from "react-router-dom";
import LandingNavigationLeft from "./LandingNavigationLeft";
import LandingNavigationRight from "./LandingNavigationRight";

const HeaderLanding = () => {
    return(<header className="site-header site-header--landing">
        <LandingNavigationLeft />
        <div className="header-logo header-logo--img">
            <Link to={"/home"}><img src="/assets/img/logo.png" srcSet="/assets/img/logo@2x.png" alt="" /></Link>
        </div>
        <LandingNavigationRight />
    </header>);
}

export default HeaderLanding;