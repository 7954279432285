import { useTranslation } from "react-i18next";
import { supportedLngs } from "./config";
/*// src/i18n/LocaleSwitcher.tsx

import { useTranslation } from "react-i18next";


export default function LocaleSwitcher() {
  const { i18n } = useTranslation();

  return (
    <div className="d-flex flex-row-reverse">
      <div className="p-2">
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.52 4.392A8.009 8.009 0 0 0 4.061 11h2.997c.227-2.363 1.078-4.627 2.46-6.608Zm4.96 0c1.383 1.981 2.234 4.245 2.461 6.608h2.997a8.009 8.009 0 0 0-5.457-6.608ZM14.93 11c-.269-2.373-1.28-4.642-2.93-6.54C10.35 6.357 9.339 8.626 9.07 11h5.86Zm-5.86 2h5.86c-.269 2.373-1.28 4.642-2.93 6.54-1.65-1.898-2.661-4.167-2.93-6.54Zm-2.011 0H4.062a8.009 8.009 0 0 0 5.457 6.608C8.137 17.627 7.286 15.363 7.06 13Zm7.422 6.608c1.382-1.981 2.233-4.245 2.46-6.608h2.997a8.008 8.008 0 0 1-5.457 6.608ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z" fill="#fff"></path></svg>
        <select
          value={i18n.resolvedLanguage}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
        >
          {Object.entries(supportedLngs).map(([code, name]) => (
            <option value={code} key={code}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
*/
import React, { useState, useEffect, useRef } from 'react';

import { useMediaQuery  } from 'react-responsive';

const LocaleSwitcher: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();

  // Toggle dropdown visibility on hover
  const toggleDropdown = (state: boolean) => {
    setIsOpen(state);
  };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 
  return (

    <div className="d-flex flex-row-reverse">
     {!isTabletOrMobile && <div className="p-2">
        <div
          className="locale-switcher"
          ref={dropdownRef}
          onMouseEnter={() => toggleDropdown(true)}
          onMouseLeave={() => toggleDropdown(false)}
        >
          {/* SVG icon */}
          <div style={{ cursor: 'pointer' }}>
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.52 4.392A8.009 8.009 0 0 0 4.061 11h2.997c.227-2.363 1.078-4.627 2.46-6.608Zm4.96 0c1.383 1.981 2.234 4.245 2.461 6.608h2.997a8.009 8.009 0 0 0-5.457-6.608ZM14.93 11c-.269-2.373-1.28-4.642-2.93-6.54C10.35 6.357 9.339 8.626 9.07 11h5.86Zm-5.86 2h5.86c-.269 2.373-1.28 4.642-2.93 6.54-1.65-1.898-2.661-4.167-2.93-6.54Zm-2.011 0H4.062a8.009 8.009 0 0 0 5.457 6.608C8.137 17.627 7.286 15.363 7.06 13Zm7.422 6.608c1.382-1.981 2.233-4.245 2.46-6.608h2.997a8.008 8.008 0 0 1-5.457 6.608ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z" fill="#fff" />
            </svg>
          </div>

          {/* Dropdown */}
          {isOpen && (
            <div className="dropdown">
              {Object.entries(supportedLngs).map(([code, name]) => (
                <div
                  key={code}
                  className="dropdown-item"
                  onClick={() => {
                    i18n.changeLanguage(code);
                    setIsOpen(false); // Close dropdown after selection
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          )}
        </div>
     
      </div>
    }
      <style>{`
        .locale-switcher {
          position: relative;
          display: inline-block;
          
        }
        .locale-switcher:hover {
          
          
            color: black;
            min-width:120px;
            text-align:right;
          
        }

        /* Dropdown is hidden initially, appears on hover */
        .locale-switcher:hover .dropdown {
          display: block;
        }

        .dropdown {
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
          
          z-index: 100;
          min-width: 120px; /* Optional: Adjust for dropdown width */
        }

        .dropdown-item {
          padding: 4px 6px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          color:white;
          font-size:1rem;
        }

        .dropdown-item:hover {
          background-color: #fafafa;
        }
      `}</style>
    </div>
  );
};

export default LocaleSwitcher;
