import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CurrencyInfo, useGetApiGreetGetCurrenciesQuery } from "../api/greetApi";
import SelectFx from "../components/SelectFx/SelectFx";
import PriceDisplay from "../components/PriceDisplay";
import CurrencyView from "../components/CurrencyView";
import { debounce } from 'lodash';
import { useFetch } from "usehooks-ts";
import { Link } from "react-router-dom";
import ExchangesBasePage from "./partials/ExchangesBasePage";
const DexExchangesPage = () => {

	return(<ExchangesBasePage dex={true} />)
}

export default DexExchangesPage;