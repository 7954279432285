import { useCallback, useEffect, useRef, useState } from "react";
import useBodyClassnames from "../../components/useBodyClassnames";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TradingViewWidget from "../../components/TradingViewWidget";
import { ExchangeInfo, ExchangeInfoRead, PairInfo,  PairInfoRead,  useGetApiGreetGetExchangesPairsQuery, 
	useGetApiGreetGetExchangesSupportQuery 
} from "../../api/greetApi";
import { ChannelModel, useLazyGetApiChannelGetChannelsMineQuery } from '../../api/channelApi';
import NiceDropdown, { OptionType } from "../../components/NiceDropdown";
import { ActionMeta, SingleValue } from "react-select";
import { ITraderPage } from "./interfaces/ITraderPage";
import TraderPagePositions from "./trader.page.positions";
import TraderPageOrderBox from "./trader.page.orderbox";
import TraderPageOrders from "./trader.page.orders";
import TraderPageClosed from "./trader.page.closed";
import PageHeading from "../../components/pageHeading/PageHeading";

import {  useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../store";
import { Form } from "react-bootstrap";


const TraderPage: React.FC = () => {
	
	const { rexchange, rpair, spair }  = useParams();
	const navigate = useNavigate();
	const [pageState, setPageState] = useState<ITraderPage>({ 
		exchangeValue:null, 
		pairValue:null,  
		exchangeId:"", 
		exchangeName:"", 
		pairId:"", 
		pairName:"", 				
		TWValue:"",		
		firstTime:true,		
		curChannel: undefined
	});


	const [ get_mc , { data: mc_data, isLoading: mc_isLoading, isFetching: mc_isFetching  } ] = useLazyGetApiChannelGetChannelsMineQuery();

	const { data :ex_data, isLoading: ex_isloading, isError: ex_iserror, isSuccess: ex_issuccess, error: ex_error, isFetching: ex_isfetching  } 
		= useGetApiGreetGetExchangesSupportQuery({ ws:true, rest:true })
	
	const { data :pr_data, isLoading: pr_isloading, isError: pr_iserror, isSuccess: pr_issuccess, error: pr_error, isFetching: pr_isfetching  } 
		= useGetApiGreetGetExchangesPairsQuery({ exchangeId: parseInt(pageState.exchangeId??"0") }, { skip: pageState.exchangeId=='' }) 


    const [exkey, setexKey] = useState<string>('positions');
    const [opkey, setopKey] = useState<string>('channels');
	const refTradingView = useRef(null);

	const gotoPair = ( exchangeId:string, exchangeName:string, pairId:string, pairName:string)=> {		
		navigate("/trader/"+exchangeName+"/"+pairName);		
	}

	const pageStatetoExchange = (ei: ExchangeInfoRead) => {
		setPageState({...pageState, 
			exchangeId: (ei.id||0).toString(),
			exchangeName: (ei.name||"").toString(),
			exchangeValue: {  
				imageSrc: ei.icon||"",
				label : (ei.name||"").toString(),
				value : (ei.id||0).toString(),
			}
		});
	}
	const pageStateToPair = (ei: PairInfoRead) => {
		setPageState({...pageState,
			firstTime:false,
			pairId: (ei.id||0).toString(),
			pairName: (ei.name||"").toString(),
			pairValue: {
				imageSrc: (ei.icon||""),
				label: (ei.name||""),
				value : (ei.id||0).toString()
			},
			TWValue: pageState.exchangeName.toUpperCase()+':'+(ei.name||"").replace("/","")
		});
	}
	
	//First Exchange by Index
	useEffect( ()=> {
		if(ex_issuccess) {
			console.log("when data loaded, we select 1st one");
			let ei = ex_data!.data![0];
			if(rexchange) {
				const cl = ex_data!.data!.filter(p => p.name == rexchange)
				if(cl.length>0)
					ei = cl[0];				
			}
			pageStatetoExchange(ei);
		}
	},[ex_issuccess]);

	//First Pair
	useEffect( () => {
		if(pageState.firstTime && pr_issuccess) {			
			let cn = pr_data!.data![0];
			if(rpair && spair) {
				const cl = pr_data!.data!.filter(t => t.name == rpair+"/"+spair);
				if(cl.length>0)
					cn = cl[0];			
			}
			pageStateToPair(cn);

		}
		else if(pr_issuccess) {
			console.log("***** need change to rpair", rpair);
			const cl = pr_data!.data!.filter(t => t.name == rpair+"/"+spair);
			if(cl.length>0) {
				const cn = cl[0];	
				pageStateToPair(cn);
			}
			
		}
	},[pr_issuccess, pr_data])
	


	useEffect(()=> {		
		if(!pageState.firstTime) {
			if(rexchange && rpair) {				
				const cl = ex_data!.data!.filter(p => p.name == rexchange)
				if(cl.length>0) {
					const ei = cl[0];
					if(pageState.exchangeId != ei.id?.toString()) {
						console.log("switching....", rexchange, rpair, spair);
						pageStatetoExchange(ei);
					}
					else {

						console.log("***** need change to rpair", rpair);
						const cl = pr_data!.data!.filter(t => t.name == rpair+"/"+spair);
						if(cl.length>0) {
							const cn = cl[0];	
							pageStateToPair(cn);
						}

					}
				}
			}
		}
	},[rexchange, rpair, spair])

	const userAccessTokenExpiration = useAppSelector(p=>p.auth?.userToken?.accessTokenExpiration);
	
	const [fullWidth, setFullWidth] = useState<boolean>(true);
	const [showChart, setShowChart] = useState<boolean>(true);

	useEffect(() => {
		if(userAccessTokenExpiration)
			get_mc();
	},[]);

	const handleChannelChange = (e: SingleValue<OptionType>,t: ActionMeta<OptionType>) => {
		setPageState(
			{...pageState, 			 
			   curChannel: e
		   }
	   );
	}
	const handlePairChange = (e: SingleValue<OptionType>,t: ActionMeta<OptionType>) => {
		setPageState(
			{...pageState, 			 
			   pairId:e?.value||"",
			   pairName:e?.label||"",
			   pairValue:e,
			   TWValue:pageState.exchangeName.toUpperCase()+':'+e?.label.replace("/","")
		   }
	   );
	}
	const handleExchangeChange = (e: SingleValue<OptionType>,t: ActionMeta<OptionType>) => {				
		setPageState(
			 {...pageState, 
				exchangeId:e?.value||"", 
				exchangeName:e?.label||"",
				exchangeValue :e,
				pairId:"",
				pairName:"",				
				pairValue:null,
				TWValue:""
			}
		);
	}

    useBodyClassnames(["bg--matches-03","scroll-is--active"]);
	
    return (
		<main className="site-content team-info-page page" id="wrapper">
			<div className={'' + (fullWidth ? 'container-fluid' : 'container')}>   				
				<PageHeading title="Trader" subTitle={pageState.pairName || 'Pair'} smallSubTitle={true}  />				
			<div style={{'backgroundColor':'#111519', 'padding':'0px 10px'}}>
				<div className="row" >
					<div className="col-md-3" >
						{rexchange} {rpair} {spair}
							<label className="p-2 text-white font-weight-bold">Exchange</label>
									<NiceDropdown value={pageState.exchangeValue} disabled={ex_isfetching||ex_isloading}  onChange={handleExchangeChange}  options={									
										 ex_data && 
											((ex_data as any).data as any).map((d:ExchangeInfo) =>{
												return { label: d.name, value: d.id, imageSrc: d.icon  }
											})
										} />								
					</div>
					<div className="col-md-3">
							<label className="p-2 text-white font-weight-bold">Pair</label>							
									<NiceDropdown  value={pageState.pairValue} disabled={pr_isfetching || pr_isloading} onChange={handlePairChange}  options={
										pr_data && 
											((pr_data as any).data as any).map((d:PairInfo) => {
												return { label: d.name, value: d.id, imageSrc: d.icon  }
											})
										} />			
					</div>
					<div className="col-md-3">
						<label className="p-2 text-white font-weight-bold">Channel</label>
						<NiceDropdown  value={pageState.curChannel} disabled={mc_isFetching || mc_isLoading} 
						onChange={handleChannelChange}  options={
										mc_data && 
											((mc_data as any).data as any).map((d:ChannelModel) => {
												return { label: d.name, value: d.id   }
											})
										} />
					</div>
					<div className="col-md-3 d-flex align-items-center justify-content-end">
						<label className="p-2 text-white font-weight-bold" style={{'fontSize':'.825rem'}}>
							<Form.Check
								type="switch"
								id="custom-switch"
								label="Chart"
								checked={showChart}
								onClick={e => setShowChart(!showChart)}
							/>
						</label>
						<label className="p-2 text-white font-weight-bold" style={{'fontSize':'.825rem'}}>
							<Form.Check
								type="switch"
								id="custom-switch"
								label="Expand View"
								checked={fullWidth}
								onClick={e => setFullWidth(!fullWidth)}
							/>
						</label>
					</div>
				</div>
				
				<div className="row">
					<div className="col-md-9 col-xs-12 mb-3">						
						{showChart && <div ref={refTradingView} ><TradingViewWidget symbol={pageState.TWValue} /></div>}
					</div>
					<div className="col-md-3 col-xs-12">
						<TraderPageOrderBox 
						exchangeId={pageState.exchangeId} pairId={pageState.pairId} exchangeName={pageState.exchangeName} 
						channelId={pageState.curChannel?.value}
						pairName={pageState.pairName} />																																		
					</div>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col-12">
                <Tabs id="exchange-tab" activeKey={exkey} onSelect={(k) => { if(k) { console.log("active k",k); setexKey(k); } } } 
                className="matches-tabs__navigation nav nav-trader mt-0" role="tablist"
                >
                    <Tab eventKey="positions" title="Positions">                        
						{exkey == "positions" &&  <TraderPagePositions gotoPair={gotoPair} /> }
                    </Tab>
                    <Tab eventKey="orders" title="Orders">
						{exkey =="orders" && <TraderPageOrders gotoPair={gotoPair}  /> }
                    </Tab>
                    <Tab eventKey="closed_positions" title="Closed P&L">
						{exkey == "closed_positions" && <TraderPageClosed /> }
                    </Tab>
                </Tabs>
				</div>
			</div>
{ /*
                <Tabs id="operation-tab" activeKey={opkey} onSelect={(k) => { if(k) setopKey(k) } } 
                className="matches-tabs__navigation nav nav-trader" role="tablist"
                >
                    <Tab eventKey="mychannels" title="Channels">  
					<div className="row">
						<div className="col-md-6 col-xs-12">							
							<table className="table matches-table table-hover table-dark table-insite table-responsive-sm table text-center">
								<thead>
								<tr><th colSpan={4}>My Channels</th></tr>
									<tr>
									<th style={{'width':'50%', 'textAlign':'left'}} >Name</th>
										<th>Members</th>
										<th>Active Orders</th>
										<th>Active Positions</th>
										</tr>
								</thead>
								<tbody>
										<tr><td style={{'textAlign':'left'}}>Chan Chin</td><td >1</td><td>0</td><td>0</td></tr>
									</tbody>
							</table>
						</div>
						<div className="col-md-6 col-xs-12">
							
							<table className="table matches-table table-hover table-dark table-insite table-responsive-sm table text-center">
									<thead>
										<tr><th colSpan={4}>Member Channels</th></tr>
										<tr>
											<th style={{'width':'50%', 'textAlign':'left'}} >Name</th>
											<th>Members</th>
											<th>Active Orders</th>
											<th>Active Positions</th>
											</tr>
									</thead>
									<tbody>
										<tr><td style={{'textAlign':'left'}}>Chan1</td><td>1</td><td>0</td><td>0</td></tr>
									</tbody>
								</table>
						</div>
					</div>                      
                    </Tab>                    
                </Tabs>
*/ }                    

{ /*
                <div className="matches-tabs mt-sm-auto mb-sm-auto">
					<ul className="matches-tabs__navigation nav" role="tablist">
						<li className="nav-item">
							<a href="#tab-1" role="tab" data-toggle="tab" className="active">Game 01</a>
						</li>
						<li className="nav-item">
							<a href="#tab-2" role="tab" data-toggle="tab" className="">Game 02</a>
						</li>
						<li className="nav-item">
							<a href="#tab-3" role="tab" data-toggle="tab" className="">Game 03</a>
						</li>
					</ul>
					<div className="matches-tabs__content tab-content">
						<div className="match-stats-container tab-pane fade show active" id="tab-1" role="tabpanel">
							<div className="match-stats-widget match-stats-widget--general">
								<div className="match-stats-widget__header">
									General Stats
								</div>
								<ul className="match-stats-widget__body">
									<li>
										<figure className="match-team" role="group">
											<figure className="match-team-logo match-team-logo--team-1" role="group">
												<img src="assets/img/samples/logo-necromancers-30.png" srcSet="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo" />
											</figure>
											<figcaption>
												<div className="match-team__name">NCR</div>
													<div className="match-team__country ">USA</div>
											</figcaption>
										</figure>
										<div className="match-result match-result--detailed">
											<span className="match-result__score--sm"><span className="team-cyan">12</span> - <span className="team-yellow">4</span></span>
											<span className="match-result__score">16 : 12</span>
											<span className="match-result__score--sm"><span className="team-cyan">5</span> - <span className="team-yellow">7</span></span>
										</div>
										<figure className="match-team" role="group">
											<figure className="match-team-logo match-team-logo--team-5" role="group">
												<img src="assets/img/samples/logo-eagles-30.png" srcSet="assets/img/samples/logo-eagles-30@2x.png 2x" alt="FEG Logo" />
											</figure>
											<figcaption>
												<div className="match-team__name">FEG</div>
													<div className="match-team__country ">FRA</div>
											</figcaption>
										</figure>
									</li>
									<li><span>Map / Level</span><span>Downpass</span></li>
									<li><span>Duration</span><span>32:29</span></li>
									<li className="team-blue">
										<figure className="match-player  match-player--small" role="group">
											<figure className="match-player__avatar">
													<svg role="img" className="df-icon df-icon--medal">
														<use xlinkHref="/assets/img/necromancers.svg#medal"/>
													</svg>
											</figure>
											<figcaption>
												<span className="match-player__nickname">Sam-X-99</span>
												<span className="match-player__name color-primary">Match MVP</span>
											</figcaption>
										</figure>
										<span>5.2<span className="color-primary">KDA R</span></span>
									</li>
								</ul>
							</div>
							<div className="match-stats-widget">
								<div className="match-stats-widget__header">
									Teams Matchup
								</div>
								<ul className="match-stats-widget__body">
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--kills">
												<use xlinkHref="/assets/img/necromancers.svg#kills"/>
											</svg>
											<div className="match-stats-progress__label">Kills</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">34</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "80%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">29</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--deaths">
												<use xlinkHref="/assets/img/necromancers.svg#deaths"/>
											</svg>
											<div className="match-stats-progress__label">Deaths</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "70%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">14</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">21</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--assists">
												<use xlinkHref="/assets/img/necromancers.svg#assists"/>
											</svg>
											<div className="match-stats-progress__label">Assists</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">26</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "45%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">10</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--ratio">
												<use xlinkHref="/assets/img/necromancers.svg#chart"/>
											</svg>
											<div className="match-stats-progress__label">KDR.R</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">1.67</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "75%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">1.14</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div className="match-stats-widget">
								<div className="match-stats-widget__header">
									Game Leaders
								</div>
								<table className="match-stats-widget-table nano">
									<thead>
										<tr>
											<th>
												Team
												<span>
													<span className="team-yellow">T</span> - <span className="team-cyan">CT</span>
												</span>
											</th>
											<th>
												<figure className="match-team-logo match-team-logo--team-1" role="group">
													<img src="assets/img/samples/logo-necromancers-30.png" srcSet="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="Necromancers Logo" />
												</figure>
											</th>
											<th>
												<figure className="match-team-logo match-team-logo--team-5" role="group">
													<img src="assets/img/samples/logo-eagles-30.png" srcSet="assets/img/samples/logo-eagles-30@2x.png 2x" alt="Fierce Eagles Logo" />
												</figure>
											</th>
										</tr>
									</thead>
									<tbody className="nano-content">
										<tr>
											<td>Round 1</td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 2</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 3</td>
											<td>
												<svg role="img" className="df-icon df-icon--pliers team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#pliers"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 4</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--stopwatch team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#stopwatch"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 5</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 6</td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 7</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--pliers team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#pliers"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 8</td>
											<td>
												<svg role="img" className="df-icon df-icon--stopwatch team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#stopwatch"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 9</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 10</td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
		
						<div className="match-stats-container tab-pane fade" id="tab-2" role="tabpanel">
							<div className="match-stats-widget match-stats-widget--general">
								<div className="match-stats-widget__header">
									General Stats
								</div>
								<ul className="match-stats-widget__body">
									<li>
										<figure className="match-team" role="group">
											<figure className="match-team-logo match-team-logo--team-1" role="group">
												<img src="assets/img/samples/logo-necromancers-30.png" srcSet="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo" />
											</figure>
											<figcaption>
												<div className="match-team__name">NCR</div>
													<div className="match-team__country ">USA</div>
											</figcaption>
										</figure>
										<div className="match-result match-result--detailed">
											<span className="match-result__score--sm"><span className="team-cyan">4</span> - <span className="team-yellow">6</span></span>
											<span className="match-result__score">10 : 13</span>
											<span className="match-result__score--sm"><span className="team-cyan">6</span> - <span className="team-yellow">7</span></span>
										</div>
										<figure className="match-team" role="group">
											<figure className="match-team-logo match-team-logo--team-5" role="group">
												<img src="assets/img/samples/logo-eagles-30.png" srcSet="assets/img/samples/logo-eagles-30@2x.png 2x" alt="FEG Logo" />
											</figure>
											<figcaption>
												<div className="match-team__name">FEG</div>
													<div className="match-team__country ">FRA</div>
											</figcaption>
										</figure>
									</li>
									<li><span>Map / Level</span><span>Downpass</span></li>
									<li><span>Duration</span><span>29:51</span></li>
									<li className="team-blue">
										<figure className="match-player  match-player--small" role="group">
											<figure className="match-player__avatar">
													<svg role="img" className="df-icon df-icon--medal">
														<use xlinkHref="/assets/img/necromancers.svg#medal"/>
													</svg>
											</figure>
											<figcaption>
												<span className="match-player__nickname">The Destroy</span>
												<span className="match-player__name color-primary">Match MVP</span>
											</figcaption>
										</figure>
										<span>5.2<span className="color-primary">KDA R</span></span>
									</li>
								</ul>
							</div>
							<div className="match-stats-widget">
								<div className="match-stats-widget__header">
									Teams Matchup
								</div>
								<ul className="match-stats-widget__body">
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--kills">
												<use xlinkHref="/assets/img/necromancers.svg#kills"/>
											</svg>
											<div className="match-stats-progress__label">Kills</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "80%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">29</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">34</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--deaths">
												<use xlinkHref="/assets/img/necromancers.svg#deaths"/>
											</svg>
											<div className="match-stats-progress__label">Deaths</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">21</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "70%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">14</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--assists">
												<use xlinkHref="/assets/img/necromancers.svg#assists"/>
											</svg>
											<div className="match-stats-progress__label">Assists</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "45%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">10</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">26</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--ratio">
												<use xlinkHref="/assets/img/necromancers.svg#chart"/>
											</svg>
											<div className="match-stats-progress__label">KDR.R</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "75%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">1.14</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">1.67</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div className="match-stats-widget">
								<div className="match-stats-widget__header">
									Game Leaders
								</div>
								<table className="match-stats-widget-table nano">
									<thead>
										<tr>
											<th>
												Team
												<span>
													<span className="team-yellow">T</span> - <span className="team-cyan">CT</span>
												</span>
											</th>
											<th>
												<figure className="match-team-logo match-team-logo--team-1" role="group">
													<img src="assets/img/samples/logo-necromancers-30.png" srcSet="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="Necromancers Logo"/>
												</figure>
											</th>
											<th>
												<figure className="match-team-logo match-team-logo--team-5" role="group">
													<img src="assets/img/samples/logo-eagles-30.png" srcSet="assets/img/samples/logo-eagles-30@2x.png 2x" alt="Fierce Eagles Logo" />
												</figure>
											</th>
										</tr>
									</thead>
									<tbody className="nano-content">
										<tr>
											<td>Round 1</td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 2</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--pliers team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#pliers"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 3</td>
											<td>
												<svg role="img" className="df-icon df-icon--stopwatch team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#stopwatch"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 4</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 5</td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 6</td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 7</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 8</td>
											<td>
												<svg role="img" className="df-icon df-icon--pliers team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#pliers"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 9</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--stopwatch team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#stopwatch"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 10</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
		
						<div className="match-stats-container tab-pane fade" id="tab-3" role="tabpanel">
							<div className="match-stats-widget match-stats-widget--general">
								<div className="match-stats-widget__header">
									General Stats
								</div>
								<ul className="match-stats-widget__body">
									<li>
										<figure className="match-team" role="group">
											<figure className="match-team-logo match-team-logo--team-1" role="group">
												<img src="assets/img/samples/logo-necromancers-30.png" srcSet="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="NCR Logo" />
											</figure>
											<figcaption>
												<div className="match-team__name">NCR</div>
													<div className="match-team__country ">USA</div>
											</figcaption>
										</figure>
										<div className="match-result match-result--detailed">
											<span className="match-result__score--sm"><span className="team-cyan">8</span> - <span className="team-yellow">7</span></span>
											<span className="match-result__score">14 : 11</span>
											<span className="match-result__score--sm"><span className="team-cyan">6</span> - <span className="team-yellow">4</span></span>
										</div>
										<figure className="match-team" role="group">
											<figure className="match-team-logo match-team-logo--team-5" role="group">
												<img src="assets/img/samples/logo-eagles-30.png" srcSet="assets/img/samples/logo-eagles-30@2x.png 2x" alt="FEG Logo" />
											</figure>
											<figcaption>
												<div className="match-team__name">FEG</div>
													<div className="match-team__country ">FRA</div>
											</figcaption>
										</figure>
									</li>
									<li><span>Map / Level</span><span>Downpass</span></li>
									<li><span>Duration</span><span>35:07</span></li>
									<li className="team-blue">
										<figure className="match-player  match-player--small" role="group">
											<figure className="match-player__avatar">
													<svg role="img" className="df-icon df-icon--medal">
														<use xlinkHref="/assets/img/necromancers.svg#medal"/>
													</svg>
											</figure>
											<figcaption>
												<span className="match-player__nickname">DaVikinger90</span>
												<span className="match-player__name color-primary">Match MVP</span>
											</figcaption>
										</figure>
										<span>5.2<span className="color-primary">KDA R</span></span>
									</li>
								</ul>
							</div>
							<div className="match-stats-widget">
								<div className="match-stats-widget__header">
									Teams Matchup
								</div>
								<ul className="match-stats-widget__body">
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--kills">
												<use xlinkHref="/assets/img/necromancers.svg#kills"/>
											</svg>
											<div className="match-stats-progress__label">Kills</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">34</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "67%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">24</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--deaths">
												<use xlinkHref="/assets/img/necromancers.svg#deaths"/>
											</svg>
											<div className="match-stats-progress__label">Deaths</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "70%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">14</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">21</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--assists">
												<use xlinkHref="/assets/img/necromancers.svg#assists"/>
											</svg>
											<div className="match-stats-progress__label">Assists</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">26</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "65%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">16</div>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="match-stats-progress match-stats-progress--icon">
											<svg role="img" className="match-stats-progress__icon df-icon df-icon--ratio">
												<use xlinkHref="/assets/img/necromancers.svg#chart"/>
											</svg>
											<div className="match-stats-progress__label">KDR.R</div>
											<div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-1" style={{width: "100%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">1.67</div>
												</div>
												<div className="match-stats-progress__bar-group">
													<div className="match-stats-progress__bar">
														<span className="team-5" style={{width: "82%"}}>&nbsp;</span>
													</div>
													<div className="match-stats-progress__score">1.36</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div className="match-stats-widget">
								<div className="match-stats-widget__header">
									Game Leaders
								</div>
								<table className="match-stats-widget-table nano">
									<thead>
										<tr>
											<th>
												Team
												<span>
													<span className="team-yellow">T</span> - <span className="team-cyan">CT</span>
												</span>
											</th>
											<th>
												<figure className="match-team-logo match-team-logo--team-1" role="group">
													<img src="assets/img/samples/logo-necromancers-30.png" srcSet="assets/img/samples/logo-necromancers-30@2x.png 2x" alt="Necromancers Logo" />
												</figure>
											</th>
											<th>
												<figure className="match-team-logo match-team-logo--team-5" role="group">
													<img src="assets/img/samples/logo-eagles-30.png" srcSet="assets/img/samples/logo-eagles-30@2x.png 2x" alt="Fierce Eagles Logo" />
												</figure>
											</th>
										</tr>
									</thead>
									<tbody className="nano-content">
										<tr>
											<td>Round 1</td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 2</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 3</td>
											<td>
												<svg role="img" className="df-icon df-icon--pliers team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#pliers"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 4</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--stopwatch team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#stopwatch"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 5</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 6</td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 7</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--pliers team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#pliers"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 8</td>
											<td>
												<svg role="img" className="df-icon df-icon--stopwatch team-yellow">
													<use xlinkHref="/assets/img/necromancers.svg#stopwatch"/>
												</svg>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Round 9</td>
											<td></td>
											<td>
												<svg role="img" className="df-icon df-icon--skull team-cyan">
													<use xlinkHref="/assets/img/necromancers.svg#skull"/>
												</svg>
											</td>
										</tr>
										<tr>
											<td>Round 10</td>
											<td>
												<svg role="img" className="df-icon df-icon--explosion team-yellow" >
													<use xlinkHref="/assets/img/necromancers.svg#explosion"/>
												</svg>
											</td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
 */}
 
                </div>
            </main>
			)

}

export default TraderPage;
