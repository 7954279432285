import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from 'lodash';
import { useFetch } from "usehooks-ts";
import { Link } from "react-router-dom";
import { ExchangeInfo, useGetApiGreetGetExchangesQuery } from "../../api/greetApi";
import SelectFx from "../../components/SelectFx/SelectFx";
import useBodyClassnames from "../../components/useBodyClassnames";
import PageHeading from "../../components/pageHeading/PageHeading";
const ExchangesBasePage = ({ dex }:{dex: boolean}) => {

	const [max, setmax] = useState<string>('100');
	const [searchTerm, setSearchTerm] = useState("");

	const [sortField, setSortField] = useState("score"); //
	const [sortDir, setSortDir] = useState(false); //trıu asc
		
	const { data, isLoading, isError, isSuccess, error } = useGetApiGreetGetExchangesQuery({ dex: dex, most: parseInt(max)});

	
	useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);


	useEffect( () => {

		

	},[isError]);

    return(
        <main className="site-content site-content--center page" id="wrapper">
            <div className="container container--large">
                
			<PageHeading title="Exchanges" subTitle={dex ? 'Decentralized' : 'Centralized'} smallSubTitle={true} />
			
                <div className="matches-filter">
                    <label className="matches-filter__label">Ranking</label>
					<SelectFx selected={max} onChange={e => setmax(e ? e : '100')} options={[
						{ value:'100', text:'Top 100'},
						{ value:'200', text:'Top 200'},
						{ value:'500', text:'Top 500'}]} />
                </div>

				<div className="row">
					<div className="col-sm text-start">
						<div className="form-group" style={{'background':'#13151e'}}>
							<label className="control-label" htmlFor="input-default">Search</label>							
							<DebounceInput onChange={e =>  setSearchTerm(e)} placeHolder="search" value={searchTerm} bounce={200} key={'a'} />
						</div>
						</div>
					<div className="col-sm text-end">
						<button className="btn btn-sm btn-secondary position-relative">Data provided by Exchanges and CoinGecko</button>
					</div>
				</div>

			{isLoading && <div className="alert alert-info" role="alert">Loading now...</div>}
			{false && isSuccess && <div className="alert alert-success" role="alert">refresh automatically...</div>}
			{isError && <div className="alert alert-danger d-flex align-items-center" role="alert">Something went wrong, please try again later!</div>}
			<div className="table-responsive mb-sm-auto">
			<table className="table matches-table standings-table">
				<thead>
					<tr>
						<th onClick={e => { if(sortField!="score") setSortField('score'); else setSortDir(!sortDir);   }   }>Score</th>
						<th onClick={e => { if(sortField!="name") setSortField('name'); else setSortDir(!sortDir);   }}>Name</th>
						<th onClick={e => { if(sortField!="country") setSortField('country'); else setSortDir(!sortDir);   } }>Country</th>
						<th onClick={e => { if(sortField!="exType") setSortField('exType'); else setSortDir(!sortDir);   } }>Type</th>
						<th>Homepage</th>
										
					</tr>
				</thead>
				<tbody>
					{data?.data?.filter( (t:ExchangeInfo) => 
						(
                            (t.exType && t.exType.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())>-1) ||
                            (t.country && t.country.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())>-1) ||
						    (t.name && t.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())>-1) || searchTerm===""))
						.sort( (a:any, b:any ) => 
								sortField=="name" ?  sortDir ?  a[sortField].localeCompare(b[sortField]) :  b[sortField].localeCompare(a[sortField]) :
								sortDir ? a[sortField] - b[sortField] : b[sortField] - a[sortField]  )
						.map( (cur: ExchangeInfo) => ( 
							<tr key={cur.name}>
								<td>{cur.score}</td>
								<td><Link to={`/exchanges/${cur.id}`}>
									<figure className="match-team" role="group">
										<figure className="match-team-logo match-team-logo--team-4" role="group">
											<img 
											src={cur.icon ? cur.icon : ""} 
											alt="" />
										</figure>
										<figcaption>
											<div className="match-team__name">{cur.name}</div>
												<div className="match-team__country ">{cur.name}</div>
										</figcaption>
									</figure></Link>
										 </td>
                                <td>{cur.country}</td>
								<td>{cur.exType}</td>							
								<td>{cur.homepage}</td>
							</tr>
					))}

				</tbody>
			</table>
		</div>


            </div>
        </main>
    )

}
type ClickHandler = (arg: any) => void;
const DebounceInput = ( { value, onChange, placeHolder, bounce }: {bounce:number, value:string, placeHolder:string, onChange: ClickHandler }) => {
	const [iValue, setIValue] = useState<string>(value);
	useEffect(()=>{
		setIValue(value);
	},[value]);

	const handleChange = useCallback( debounce(onChange, bounce),[onChange]);
	const handleIChange = useCallback((event:any)=> {
		setIValue(event.target.value);
		handleChange(event.target.value);
	},[setIValue, handleChange]);

	return (<input className="form-control text-white" onChange={handleIChange} placeholder={placeHolder} value={iValue}/>);
}

export default ExchangesBasePage;