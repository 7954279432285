import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


export interface ApiResponseError {
    type: string;
    title: string;
    status: number;
    traceId: string;
    errors: Record<string, string[]>;
  }
  

const useApiErrorHandling = <T extends ApiResponseError>() => {
 
    const handleApiError = (error : T) => 
    {
        console.warn("in error", error);
        Object.values(error.errors).forEach( (errmessage) => 
        {
            console.log("errm", errmessage);
            toast.error(errmessage[0]);
        });
    }

    return { handleApiError };
}
    

export default useApiErrorHandling;