import Decors from "../partials/Decors";

const GlossaryPage = () => {


    return(
        <main className="site-content" id="wrapper">			
        <div className="site-content__inner">
        <div className="site-content__holder">
        <Decors>                            
                <h1 className="text-white">Learn Crypto</h1>
        </Decors>
            <div className="page-content">
            <h4 className="mb-5">The Crypto Words</h4>
            <div className="row ">
            </div>
                    </div>
                </div>
            </div>
        </main> 
    )

};


export default GlossaryPage;