import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import NavLinkC from '../NavLinkC';

const MobileMenu = ({ title, children } : { title:string, children:any}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <li className="mobile-bar-item">
      <a
        className={`mobile-bar-item__header ${isCollapsed ? 'collapsed' : ''}`}
        data-toggle="collapse"
        role="button"
        aria-expanded={!isCollapsed}
        aria-controls={`mobile_collapse_${title}`}
        onClick={toggleCollapse}
      >
        {title}
        <span className="main-nav__toggle">&nbsp;</span>
      </a>

      <div
        id={`mobile_collapse_${title}`}
        className={`collapse mobile-bar-item__body ${!isCollapsed ? 'show' : ''}`}
      >
        <nav className="mobile-nav">
          <ul className="mobile-nav__list">{children}</ul>
        </nav>
      </div>
    </li>
  );
};

const MobileSubMenu = ({ to, children }: { to:string, children:any}) => (
  <li>
    <NavLinkC to={to}>{children}</NavLinkC>
  </li>
);

export { MobileMenu, MobileSubMenu };
