import { useEffect } from "react";

const useWindowTitle = (title: string) => {
  const staticTitle="Coin Synthesis ✅ - Bitcoin, Ethereum, Crypto News, Prices, Rates, Altcoins, Market Cap";
    useEffect(() => {
        
      document.title = `${title} | ${staticTitle}`;

      return () => {
        document.title = staticTitle; // Set your original title here
      };
    }, [title]);
  };
  
  export default useWindowTitle;