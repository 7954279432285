import { telegramApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiTelegramGenerateVerificationCode: build.mutation<
      PostApiTelegramGenerateVerificationCodeResp,
      PostApiTelegramGenerateVerificationCodeReq
    >({
      query: (queryArg) => ({
        url: `/api/Telegram/generateVerificationCode`,
        method: "POST",
        body: queryArg.verificationRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as telegramApi };
export type PostApiTelegramGenerateVerificationCodeResp =
  /** status 200 Success */ VerificationRequest;
export type PostApiTelegramGenerateVerificationCodeReq = {
  verificationRequest: VerificationRequest;
};
export type VerificationRequest = {
  channelId?: string | null;
  verificationCode?: string | null;
  appChannelId?: number;
};
export const { usePostApiTelegramGenerateVerificationCodeMutation } =
  injectedRtkApi;
