import { toast } from 'react-toastify';

type ApiErrors = {
  [key: string]: string[];
} | null | undefined;

const handleApiErrors = (errors: ApiErrors): void => {
  if (errors) {
    Object.keys(errors).forEach((key) => {
      errors[key].forEach((error) => {
        toast.error(error);
      });
    });
  }
};

export default handleApiErrors;
