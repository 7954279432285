import { tagApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiCoinAddTagByIdTags: build.mutation<
      PostApiCoinAddTagByIdTagsResp,
      PostApiCoinAddTagByIdTagsReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/AddTag/${queryArg.id}/tags`,
        method: "POST",
        body: queryArg.tag,
      }),
    }),
    deleteApiCoinRemoveTagByIdTagsAndTagId: build.mutation<
      DeleteApiCoinRemoveTagByIdTagsAndTagIdResp,
      DeleteApiCoinRemoveTagByIdTagsAndTagIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/RemoveTag/${queryArg.id}/tags/${queryArg.tagId}`,
        method: "DELETE",
      }),
    }),
    getApiTagGetTags: build.query<GetApiTagGetTagsResp, GetApiTagGetTagsReq>({
      query: () => ({ url: `/api/Tag/GetTags` }),
    }),
    getApiTagGetTagById: build.query<
      GetApiTagGetTagByIdResp,
      GetApiTagGetTagByIdReq
    >({
      query: (queryArg) => ({ url: `/api/Tag/GetTag/${queryArg.id}` }),
    }),
    postApiTagCreateTag: build.mutation<
      PostApiTagCreateTagResp,
      PostApiTagCreateTagReq
    >({
      query: (queryArg) => ({
        url: `/api/Tag/CreateTag`,
        method: "POST",
        body: queryArg.tag,
      }),
    }),
    putApiTagUpdateTagById: build.mutation<
      PutApiTagUpdateTagByIdResp,
      PutApiTagUpdateTagByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Tag/UpdateTag/${queryArg.id}`,
        method: "PUT",
        body: queryArg.tag,
      }),
    }),
    deleteApiTagDeleteTagById: build.mutation<
      DeleteApiTagDeleteTagByIdResp,
      DeleteApiTagDeleteTagByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Tag/DeleteTag/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as tagApi };
export type PostApiCoinAddTagByIdTagsResp = /** status 201 Created */ TagQrRead;
export type PostApiCoinAddTagByIdTagsReq = {
  id: number;
  tag: Tag;
};
export type DeleteApiCoinRemoveTagByIdTagsAndTagIdResp =
  /** status 204 No Content */ void;
export type DeleteApiCoinRemoveTagByIdTagsAndTagIdReq = {
  id: number;
  tagId: number;
};
export type GetApiTagGetTagsResp =
  /** status 200 Success */ TagIEnumerableQrRead;
export type GetApiTagGetTagsReq = void;
export type GetApiTagGetTagByIdResp = /** status 200 Success */ TagQrRead;
export type GetApiTagGetTagByIdReq = {
  id: number;
};
export type PostApiTagCreateTagResp = /** status 200 Success */ TagQrRead;
export type PostApiTagCreateTagReq = {
  tag: Tag;
};
export type PutApiTagUpdateTagByIdResp = /** status 200 Success */ StringQrRead;
export type PutApiTagUpdateTagByIdReq = {
  id: number;
  tag: Tag;
};
export type DeleteApiTagDeleteTagByIdResp =
  /** status 200 Success */ StringQrRead;
export type DeleteApiTagDeleteTagByIdReq = {
  id: number;
};
export type Tag = {
  id?: number;
  name?: string | null;
  description?: string | null;
};
export type TagQr = {
  data?: Tag;
};
export type TagQrRead = {
  data?: Tag;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type TagIEnumerableQr = {};
export type TagIEnumerableQrRead = {
  data?: Tag[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type StringQr = {};
export type StringQrRead = {
  data?: string | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export const {
  usePostApiCoinAddTagByIdTagsMutation,
  useDeleteApiCoinRemoveTagByIdTagsAndTagIdMutation,
  useGetApiTagGetTagsQuery,
  useLazyGetApiTagGetTagsQuery,
  useGetApiTagGetTagByIdQuery,
  useLazyGetApiTagGetTagByIdQuery,
  usePostApiTagCreateTagMutation,
  usePutApiTagUpdateTagByIdMutation,
  useDeleteApiTagDeleteTagByIdMutation,
} = injectedRtkApi;
