import { useEffect, useState } from "react";
import DebounceInput from "../../components/DebounceInput";
import useBodyClassnames from "../../components/useBodyClassnames";
import { Channel, useLazyGetApiChannelGetChannelByIdQuery, useLazyGetApiChannelGetChannelsMineQuery, usePostApiGreetFindChannelsMutation, usePutApiChannelUpdateChannelPorpUpdatechannelpropMutation } from "../../api/channelApi";
import { Button, ButtonGroup, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ChannelCreateModal from "./trader.channes.page.create";
import ActiveSign from "../../components/active_sign";
import PageHeading from "../../components/pageHeading/PageHeading";
import TimeAgo from 'react-timeago'
import TraderChannelPageInviteModal, { ChannelInviteModalProps } from './trader.channels.page.invite';
import TraderChannelMembers from "./trader.channel.member";
import TraderChannelAvatarUpload from "./trader.channel.avatarupload";
import Avatar from 'react-avatar';
import ChannelEditModal from "./trader.channes.page.edit";
import useWindowTitle from "../../hooks/useWindowTitle";
import Guider from "../../hooks/useGuidBox";
import React from "react";
import { Link } from "react-router-dom";
import { SearchBoxResultListQrRead, usePostApiGreetFindUsersMutation } from "../../api/greetApi";
import CurrencyView, { CurrencyViewWithColorStyle, CurrencyViewWithLpStyle } from "../../components/CurrencyView";
import RankingIcon from "../../components/RankingIcon";
import PnlBox from "../../components/PnlBox/PnlBox";
import PriceDisplay from "../../components/PriceDisplay";
const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;
const TraderBestsPage = () => {
    useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);
    const [searchTerm, setSearchTerm] = useState("");

    const [tableData, setTableData ] = useState<SearchBoxResultListQrRead | undefined>(undefined);
    
    const [findChans, { isLoading: loadingChan, data: dataChan }] = usePostApiGreetFindChannelsMutation();
    const [findUsers, { isLoading: loadingUser, data: dataUser }] = usePostApiGreetFindUsersMutation();
    const [entity, setEntity] = useState<string>("");


    const [ tgc, setTGC] = useState<"0"|"1">("1");
    const [ tgr, setTGR] = useState<0|1|2|3>(3);

    useEffect(()=> {
        setEntity("Channel")
        setTableData(dataChan);
        
    },[dataChan]);
    useEffect(()=> {
        setEntity("User")
        setTableData(dataUser);
    },[dataUser]);


    useEffect( () => {

        if(tgc == '0')
            findChans({searchBox: { best:true, page:0, sOrder: tgr, name:'', ethWallet:'' }}); 
        else
            findUsers({searchBox: { best:true, page:0, sOrder: tgr, name:'', ethWallet:'' }}); 

    },[tgr]);

    return (<main className="site-content site-content--center page" id="wrapper">
            <div className="container container--large">
                <PageHeading title="Global" subTitle="Rankings" smallSubTitle={true}  />
                <div className="p-2 d-flex justify-content-between row">
                    <div className="col-md-6 col-xs-12">
                    <ToggleButtonGroup name="chan" type="radio" value={tgc}>
                        <ToggleButton size="sm" value={'1'} 
                            onClick={e => { setTableData(undefined); setTGC("1"); findUsers({searchBox: { best:true, page:0, sOrder: tgr, name:'', ethWallet:'' }}); } } >Traders</ToggleButton>
                        <ToggleButton  size="sm" value={'0'} 
                            onClick={e => { setTableData(undefined); setTGC("0"); findChans({searchBox: { best:true, page:0, sOrder: tgr, name:'', ethWallet:'' }}); } }>Channels</ToggleButton>
                    </ToggleButtonGroup>
                    </div>
                    <div className="col-md-6 col-xs-12" style={{'textAlign':'right'}}>
                    <ToggleButtonGroup name="chad" type="radio" value={tgr}>
                    <ToggleButton size="sm" value={2} onClick={e =>  { setTGR(0);  } }>
                            Today
                        </ToggleButton>
                        <ToggleButton size="sm" value={1} onClick={e =>  { setTGR(1);  } }>
                            7 Days
                        </ToggleButton>
                        <ToggleButton size="sm" value={2} onClick={e =>  { setTGR(2);  } }>
                            30 Days
                        </ToggleButton>
                        <ToggleButton size="sm" value={3} onClick={e =>  { setTGR(3);  } }>
                            All
                        </ToggleButton>
                    </ToggleButtonGroup>
                    </div>
                </div>
                {(loadingUser||loadingChan) && <div className="alert alert-info" role="alert">Loading now...</div>}
                <br />
                <div className="table-responsive mb-sm-auto">
			        {tableData && <table className="table matches-table standings-table">
				        <thead >
					        <tr>
                                <th style={{'width':'50px'}}>Rank</th>
                                <th style={{'width':'30%'}}>{ entity=='Channel' ? 'Channel' : ''} Name</th>
                                <th style={{'width':'15%'}}>PnL (%)</th>
                                <th style={{'width':'15%'}}>Profit ($)</th>
                                <th style={{'width':'10%'}}># Trades</th>
                                <th style={{'width':'15%'}}>Since</th>                                
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData && tableData.data && tableData.data.map( (v, i) => {
                                    return (<tr key={v.ownerId}>
                                        <td><RankingIcon place={1+i} /></td>
                                            <td>                                                  
                                                <Link to={`/${ entity =="User" ? "account" : "channel"}/${v.ownerId!}`}>
                                                <Avatar name={v.name!}
                                                    src={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${v.ownerId}?entity=${entity}`}
                                                    size="48" round={true} />
                                                </Link>
                                                <Link to={`/account/${v.ownerId!}`}>{v.name}</Link></td>

                                                <td style={{'fontSize':'1rem'}}>
                                                {v.pnl! > 0 ? (<span className="text-success" style={{'fontSize':'1.35rem'}}>+{v.pnl!.toFixed(2)}%</span>) : 
                                                (<span className="text-danger" style={{'fontSize':'1.35rem'}}>{v.pnl!.toFixed(2)}%</span>)}
                                                </td>

                                                <td style={{'fontSize':'1rem'}}><CurrencyViewWithColorStyle value={v.pnl!} /></td>
                                                                                      
                                            <td style={{'fontSize':'1rem'}}>{v.num}</td>
                                            <td><TimeAgo date={v.date!} /></td>      
                                    </tr>)
                                })
                            }
                            {
                                tableData && tableData.data && tableData.data.length == 0 &&
                                <tr><td colSpan={6}>
                                    <p className="alert alert-warning" role="alert">There is no record found, please try different filter</p>
                                    </td></tr>
                            }
                        </tbody>
                    </table> }
                    {!tableData && <h2 className="text-center text-success">Please select Traders or Channels</h2>}
                </div>
            </div>
        </main>)

};

export default TraderBestsPage;