import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CurrencyInfo, useGetApiGreetGetCurrenciesChangeQuery } from "../api/greetApi";
import SelectFx from "../components/SelectFx/SelectFx";
import PriceDisplay from "../components/PriceDisplay";
import CurrencyView from "../components/CurrencyView";
import { debounce } from 'lodash';
import { useFetch } from "usehooks-ts";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { greetActions } from "../../features/greet/greetSlice";
import SelectFxWithState from "../components/SelectFxWithState";
import useBodyClassnames from "../components/useBodyClassnames";
import PageHeading from "../components/pageHeading/PageHeading";
const CurrenciesGainerLooser = () => {

    const spotTop  =  useAppSelector(state => state.greet.spotTop);
    
	const [merged, setMerged] = useState<CurrencyInfo[][] | null>(null);
	const {  data: up_data, isLoading: up_loading, isError: up_error, isSuccess : up_success, error: up_errortext } 
        = useGetApiGreetGetCurrenciesChangeQuery({ most: spotTop, up:true }, {
        pollingInterval:30000,
        refetchOnMountOrArgChange:true
    });        

    const {  data: dw_data, isLoading: dw_loading, isError: dw_error, isSuccess : dw_success, error: dw_errortext } 
        = useGetApiGreetGetCurrenciesChangeQuery({ most: spotTop, up:false }, {
        pollingInterval:30000,
        refetchOnMountOrArgChange:true
    }); 

    useEffect( ()=> {
        if(up_success && dw_success) {     
            console.log("changing data");
            const ups =  up_data?.data || Array<CurrencyInfo>(); 
            const dos =  dw_data?.data || Array<CurrencyInfo>();
            const nmerged= ups.map((value, index) => [value, dos[index]]);
          setMerged(nmerged);            
        }
    },[up_success, up_loading, dw_success, dw_loading, up_data, dw_data]);

    useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);

    return (<main className="site-content site-content--center page" id="wrapper">
    <div className="container container--large">
    <PageHeading title="Coin" subTitle="Gainers & Loosers" smallSubTitle={true}  />
        <div className="matches-filter">
            <label className="matches-filter__label">Ranking</label>
            <SelectFxWithState />
        </div>
              

        <div className="matches-tabs mt-sm-auto mb-sm-auto">
            
            
                <div className="match-lineups-container tab-pane fade show active" id="tab-1" role="tabpanel">

                    { up_success && dw_success &&

                    <table className="matches-table lineups-table lineups-table--style-1"  >
                        <thead>
                            <tr>
                                <th>Coin</th>
                                <th className="td-role">Price</th>
                                <th className="td-hero td-d-none-sm td-center-left">24H %</th>
                                <th className="td-hero td-d-none-sm td-center-right">24H %</th>
                                <th className="td-role">Price</th>
                                <th>Coin</th>
                            </tr>
                        </thead>
                        <tbody>
                                {merged && merged.map((item, index) => (
                                    <tr key={index} >
                                    <td><Link to={`/currencies/detail/${item[0].symbol}`}>
                                        <figure className="match-player team-1" role="group" style={{'height':'64px'}}>
                                            <figure className="match-player__avatar">
                                            <img style={{'maxWidth':'32px'}}
                                            className="m-3"
											src={item[0].icon ? item[0].icon : ""} 
											alt="" />
                                            </figure>
                                            <figcaption>
                                                <span className="match-player__nickname" style={{'fontSize':'20px'}} >{item[0].symbol}</span>
                                                <span className="match-player__name" style={{'fontSize':'14px'}}>{item[0].name}</span>
                                            </figcaption>
                                        </figure></Link>
                                    </td>
                                    <td style={{'fontSize':'18px'}} >
                                    <PriceDisplay pre={true} sign={'$'} value={item[0].price} />
                                     
                                    </td>
                                    <td className="td-d-none-sm td-center-left standings-table__wins" style={{'fontSize':'18px'}}>{item[0].change24Hr?.toFixed(2)}%</td>
                                    <td className="td-d-none-sm td-center-right standings-table__losses" style={{'fontSize':'18px'}}>{item[1].change24Hr?.toFixed(2)}%</td>
                                    <td style={{'fontSize':'18px'}}>
                                    <PriceDisplay pre={true} sign={'$'} value={item[1].price} />
                                    </td>
                                    <td><Link to={`/currencies/detail/${item[1].symbol}`}>
                                        <figure className="match-player team-3" role="group" style={{'height':'64px'}}>
                                            <figure className="match-player__avatar">
                                            <img style={{'maxWidth':'32px'}}
                                            className="m-3"
											src={item[1].icon ? item[1].icon : ""} 
											alt="" />
                                            </figure>
                                            <figcaption>
                                                <span className="match-player__nickname"  style={{'fontSize':'20px'}}>{item[1].symbol}</span>
                                                <span className="match-player__name"  style={{'fontSize':'14px'}}>{item[1].name}</span>
                                            </figcaption>
                                        </figure></Link>
                                    </td>
                                </tr>
                            ))}                                                   
                        </tbody>
                    </table>
                    }
                    {(up_loading || dw_loading) && <p>Loading...</p> }
                </div>

            
        </div>
    </div>
</main>)
}

export default CurrenciesGainerLooser;