import { useEffect, useState } from "react";
import Decors from "../partials/Decors";

interface PNLOpt {
    leverage : number;
    entry_price?: number | null;
    exit_price?: number | null;
    quantity? : number | null;
    is_long:boolean;
    initial_margin: number;
    pnl:number;
    roe:number;
}
const ToolPNL = () => {

    const [t, sett] = useState<PNLOpt>({
            entry_price:null,
            exit_price:null,
            initial_margin:0,
            leverage:1,
            pnl:0,
            quantity:null,
            roe:0,
            is_long:true            
    });


    const goodNum = (n:number) =>  {

       return new Intl.NumberFormat("en-US", {
            signDisplay: "exceptZero",
            minimumFractionDigits: 2,
            maximumFractionDigits: 10
        }).format(n);

    }

    useEffect(()=> {

        const cpt = {...t};
        if( cpt.quantity  !=null && cpt.exit_price != undefined && cpt.entry_price != undefined) {
            const roe = cpt.quantity * ( cpt.exit_price - cpt.entry_price ) * cpt.leverage;
            const pnl = cpt.quantity * ( cpt.exit_price - cpt.entry_price );
            cpt.pnl = isNaN(pnl) ? 0 : pnl;
            cpt.roe = roe;
            cpt.initial_margin = 1.0 * cpt.quantity *  cpt.entry_price / cpt.leverage;

            if(!cpt.is_long) {
                cpt.pnl = -1.0 * cpt.pnl;
                cpt.roe = -1.0 * cpt.roe;
            
            }

            sett(cpt);
        }
    },[t.entry_price, t.quantity, t.exit_price, t.leverage, t.is_long]);


    return (
        <main className="site-content" id="wrapper">			
			<div className="site-content__inner">
            <div className="site-content__holder">
                <Decors>                    
"PnL" stands for "Profit and Loss." It is a financial term used to describe the overall gain or loss on an investment or trading position over a specific period of time. PnL is a measure of the performance of a financial asset, portfolio, or trading strategy.

When a trader or investor closes a position, they calculate the PnL by taking the difference between the selling price and the buying price. If the selling price is higher than the buying price, the result is a profit. Conversely, if the selling price is lower than the buying price, it results in a loss.

PnL is a key metric in assessing the success or failure of trading activities. It is often expressed in both absolute terms (dollar amount) and as a percentage of the initial investment. Monitoring PnL helps traders and investors make informed decisions, adjust their strategies, and manage risk effectively.
                </Decors>
                <div className="page-content">
                <h4 className="mb-5">Profit/Loss</h4>
                <div className="row ">
                    <div className="col-md-6">


                        <div className="form-group">

                            <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" defaultChecked 
                             onChange={e => sett({...t, is_long:true } )} 
                            />
                            <label className="btn btn-outline-success" htmlFor="success-outlined">Long</label>

                            <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" 
                            onChange={e => sett({...t, is_long:false } )} 
                            />
                            <label className="btn btn-outline-danger" htmlFor="danger-outlined">Short</label>

                        </div>

                        <div className="form-group">                            
                            <label htmlFor="customRange3" className="form-label">Leverage (x{t.leverage})</label>
                            <input type="range" className="form-range" min="1" max="200" value={t.leverage} 
                            onChange={e => sett({...t, leverage: e.target.value ? parseInt(e.target.value) : 1} )}  step="1" id="customRange3"></input>                            
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Entry Price</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={t.entry_price || ""}  onChange={e => sett({...t, entry_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="1.5" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Close Price</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number"   value={t.exit_price || ""}  onChange={e => sett({...t, exit_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="1.5" />
                            </div>
                        </div>
                        
                        
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Quantity</label>                                                    
                            <input type="number"  value={t.quantity || ""}  onChange={e => sett({...t, quantity:parseFloat(e.target.value)} )} className="form-control" placeholder="1" />
                            
                        </div>

                    </div>
                    <div className="col-md-6">
                        <h4>Result</h4>
                    <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Initial Margin</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(t.initial_margin)} $</div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Profit/Loss</label>
                            <div style={{'fontWeight':'bold', 'fontSize':'2rem'}} className={t.pnl>=0 ? 'text-success': 'text-danger'} >{goodNum(t.pnl)} $</div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">ROE</label>
                            <div style={{'fontWeight':'bold', 'fontSize':'2.2rem'}} className={t.pnl>=0 ? 'text-success': 'text-danger'} >{goodNum(t.roe)}%</div>
                        </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>    
        )
}

export default ToolPNL;