import { useEffect, useState } from "react";
import Decors from "../partials/Decors";

interface PNLOpt {
    leverage : number;
    entry_price: number |null;
    target_price: number;    
    balance: number; 
    quantity:number |null;
    is_long:boolean;
}
const ToolLiquidationPrice = () => {

    const [t, sett] = useState<PNLOpt>({
            entry_price:null,
            is_long:true,
            leverage:1,
            target_price:0,
            balance:0,
            quantity:null,                    
    });


    const goodNum = (n:number) =>  {

       return new Intl.NumberFormat("en-US", {
            signDisplay: "exceptZero",
            minimumFractionDigits: 2,
            maximumFractionDigits: 10
        }).format(n);

    }


    const maintLookupTable = [
        [50000, 0.4, 0],
        [250000, 0.5, 50],
        [1000000, 1.0, 1300],
        [10000000, 2.5, 16300],
        [20000000, 5.0, 266300],
        [50000000, 10.0, 1266300],
        [100000000, 12.5, 2516300],
        [200000000, 15.0, 5016300],
        [300000000, 25.0, 25016300],
        [500000000, 50.0, 100016300],
      ];
      
      function binanceBtcLiqBalance(walletBalance:number, contractQty:number, entryPrice:number) {
        for (const [maxPosition, maintMarginRatePct, maintAmount] of maintLookupTable) {
          const maintMarginRate = maintMarginRatePct / 100;
          const liqPrice =
            (walletBalance + maintAmount - contractQty * entryPrice) /
            (Math.abs(contractQty) * (maintMarginRate - (contractQty >= 0 ? 1 : -1)));
          const baseBalance = liqPrice * Math.abs(contractQty);
          if (baseBalance <= maxPosition) {
            return liqPrice;
          }
        }
        throw new Error('Insufficient balance for liquidation');
      }
      
      function binanceBtcLiqLeverage(leverage:number, contractQty:number, entryPrice:number) : number {
        const m1 =  (Math.abs(contractQty) * entryPrice) / leverage;
        const m2 =  t.balance - m1;
        const walletBalance = m1 + m2;

        // Additional validation for contractQty and walletBalance
        if (Math.abs(contractQty) * entryPrice * (1 / leverage) > walletBalance) {
            throw new Error('Insufficient balance for opening the position with the given contract quantity');
        }
        console.log(`[Wallet-balance-equivalent of ${walletBalance}] `);
        return binanceBtcLiqBalance(walletBalance, contractQty, entryPrice);
      }
      
      // Example usage
     
      
      
      

    useEffect(()=> {

        const cpt = {...t};


        if(cpt.entry_price != null && cpt.quantity != null) {   
           
            try {
                const liquidationPrice = binanceBtcLiqLeverage(t.leverage, t.quantity!, t.entry_price!);
                console.log('Liquidation price:', liquidationPrice);
                cpt.target_price =Math.round(liquidationPrice*100)/100;
              } catch (error:any) {
                cpt.target_price=0;
              }
        }

        sett(cpt);               
    },[t.entry_price, t.quantity, t.leverage, t.is_long, t.balance]);


    return (
        <main className="site-content" id="wrapper">			
			<div className="site-content__inner">
            <div className="site-content__holder">
                <Decors>
                The "liquidation price" is a term commonly used in trading, particularly in the context of margin trading and futures contracts. It refers to the price level at which a trader's position is automatically closed (liquidated) by the exchange or broker to prevent further losses.

In margin trading, traders borrow funds to leverage their positions. The liquidation price is crucial because if the market moves against the trader and the asset's price reaches or goes below the liquidation price, the broker or exchange will automatically sell the trader's assets to cover the losses and repay the borrowed funds.
                </Decors>
                <div className="page-content">
                <h4 className="mb-5">Liquidation Price</h4>
                <div className="row ">
                    <div className="col-md-6">

 
                        <div className="form-group">
                            <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" defaultChecked 
                             onChange={e => sett({...t, is_long:true } )} 
                            />
                            <label className="btn btn-outline-success" htmlFor="success-outlined">Long</label>

                            <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" 
                            onChange={e => sett({...t, is_long:false } )} 
                            />
                            <label className="btn btn-outline-danger" htmlFor="danger-outlined">Short</label>

                        </div>

                        <div className="form-group">                            
                            <label htmlFor="customRange3" className="form-label">Leverage (x{t.leverage})</label>
                            <input type="range" className="form-range" min="1" max="200" value={t.leverage} 
                            onChange={e => sett({...t, leverage: e.target.value ? parseInt(e.target.value) : 1} )}  step="1" id="customRange3"></input>                            
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Entry Price</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={t.entry_price || ""}  
                                onChange={e => sett({...t, entry_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="1.5" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Quantity</label>
                            <div className="input-group mb-3">
                            
                                <input type="number"   value={t.quantity|| ""}  onChange={e => sett({...t, quantity:parseFloat(e.target.value??0)} )} className="form-control" placeholder="100" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Initial Balance</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number"   value={t.balance|| ""}  onChange={e => sett({...t, balance:parseFloat(e.target.value??0)} )} className="form-control" placeholder="1000" />
                            </div>
                        </div>
                   

                    </div>
                    <div className="col-md-6">
                    <h4>Result</h4>
                    <div className="form-group">
                        <select className="form-control">
                            <option value={'cross'}>Cross</option>
                            <option value={'isolated'}>Isolated</option>
                        </select>
                        <select className="form-control">
                            <option value={'oneway'}>One-way Mode</option>
                            <option value={'hedge'}>Hedge Mode</option>
                        </select>
                    </div>
                    <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Target Price</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(t.target_price)} $</div>
                        </div>
       

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>    
        )
}

export default ToolLiquidationPrice;