import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAppSelector } from '../../../store';
import openPopup from '../../../components/openPopup';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

interface TwitterButtonProps {
    chan :number;
    media: number;
}

const TwitterAuthButton: React.FC<TwitterButtonProps> = ({chan, media}) => {
    
    const token = useAppSelector( p => p.auth);
   

    const [isAuthenticated, setIsAuthenticated] = useState<boolean|undefined>(undefined);

    useEffect(() => {
        const handleAuthMessage = (event: MessageEvent) => {
            if (event.data === 'auth_success') {
                setIsAuthenticated(true);
                console.log('Twitter authentication successful');
                // Optionally, refresh user data or update state
            }
            if(event.data === 'auth_failed') {
                setIsAuthenticated(false);
                console.log('Twitter authentication failed');
            }
        };

        window.addEventListener('message', handleAuthMessage);

        return () => {
            window.removeEventListener('message', handleAuthMessage);
        };
    }, []);

    const startTwitterAuth = () => {
        const authUrl = BASE_URL+'/api/twitter/authenticate?media='+media+'&marker='+token.userToken?.refreshToken+'&chan='+chan;
        const popup = openPopup(authUrl, 'Authenticate with Twitter', 600, 600);

        const popupTick = setInterval(() => {
            if (!popup || popup.closed || popup.closed === undefined) {
                clearInterval(popupTick);
                
            }
        }, 500);
    };

    return (
         <div>
            {!isAuthenticated && <Button variant="primary" size="sm" onClick={startTwitterAuth}>
                <i className="fa-regular fa-circle-check"></i> Authenticate with Twitter
            </Button>}
            {isAuthenticated ===true && <p className='text-success'>Authenticated successfully!</p>}
            {isAuthenticated ===false && <p className='text-danger'>Authenticated failed!!</p>}
        </div>
    );
};


export default TwitterAuthButton;

