import React from "react";

const useTextTyping = (ifullText:string) => {

    const [text, setText] = React.useState("");
    const [fullText, setFullText] = React.useState(ifullText);
    const [enabled, setEnabled] = React.useState(false);
    const [index, setIndex] = React.useState(0);

    React.useEffect(()=> {
        if(enabled) {
            if( index < fullText.length ){
                setTimeout(() => {
                    setText(text + fullText[index]);
                    setIndex(index +1);
                },40);
            }
        }
    },[index, enabled]);

    return {text, setEnabled};
};

export default useTextTyping;
