import { positionApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiChannelAddPositionProviderByIdProvidersAdd: build.mutation<
      PostApiChannelAddPositionProviderByIdProvidersAddResp,
      PostApiChannelAddPositionProviderByIdProvidersAddReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/AddPositionProvider/${queryArg.id}/providers/add`,
        method: "POST",
        body: queryArg.channelPositionProvider,
      }),
    }),
    getApiChannelGetPositionProviderByIdProviders: build.query<
      GetApiChannelGetPositionProviderByIdProvidersResp,
      GetApiChannelGetPositionProviderByIdProvidersReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/GetPositionProvider/${queryArg.id}/providers`,
      }),
    }),
    putApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivate:
      build.mutation<
        PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateResp,
        PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateReq
      >({
        query: (queryArg) => ({
          url: `/api/Channel/DeactivatePositionProvider/${queryArg.id}/providers/${queryArg.providerId}/deactivate`,
          method: "PUT",
        }),
      }),
    putApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdate:
      build.mutation<
        PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateResp,
        PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateReq
      >({
        query: (queryArg) => ({
          url: `/api/Channel/UpdatePositionProvider/${queryArg.id}/providers/${queryArg.providerId}/update`,
          method: "PUT",
          body: queryArg.channelPositionProvider,
        }),
      }),
    getApiPositionGetPositions: build.query<
      GetApiPositionGetPositionsResp,
      GetApiPositionGetPositionsReq
    >({
      query: () => ({ url: `/api/Position/GetPositions` }),
    }),
    getApiPositionGetPositionsLatestPrice: build.query<
      GetApiPositionGetPositionsLatestPriceResp,
      GetApiPositionGetPositionsLatestPriceReq
    >({
      query: () => ({ url: `/api/Position/GetPositionsLatestPrice` }),
    }),
    getApiPositionGetPositionsOf: build.query<
      GetApiPositionGetPositionsOfResp,
      GetApiPositionGetPositionsOfReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/GetPositionsOf`,
        params: {
          typ: queryArg.typ,
          active: queryArg.active,
          pg: queryArg.pg,
          userid: queryArg.userid,
        },
      }),
    }),
    getApiPositionGetPositionsOfC: build.query<
      GetApiPositionGetPositionsOfCResp,
      GetApiPositionGetPositionsOfCReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/GetPositionsOfC`,
        params: {
          chanId: queryArg.chanId,
          typ: queryArg.typ,
          active: queryArg.active,
          pg: queryArg.pg,
        },
      }),
    }),
    getApiPositionGetPositionHistoryById: build.query<
      GetApiPositionGetPositionHistoryByIdResp,
      GetApiPositionGetPositionHistoryByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/GetPositionHistory/${queryArg.id}`,
      }),
    }),
    getApiPositionGetPositionById: build.query<
      GetApiPositionGetPositionByIdResp,
      GetApiPositionGetPositionByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/GetPosition/${queryArg.id}`,
      }),
    }),
    getApiPositionGetPositionDetailByIdDetailAndDetailId: build.query<
      GetApiPositionGetPositionDetailByIdDetailAndDetailIdResp,
      GetApiPositionGetPositionDetailByIdDetailAndDetailIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/GetPositionDetail/${queryArg.id}/detail/${queryArg.detailId}`,
      }),
    }),
    getApiPositionGetPositionTakeProfitByIdTakeprofitAndTakeProfitId:
      build.query<
        GetApiPositionGetPositionTakeProfitByIdTakeprofitAndTakeProfitIdResp,
        GetApiPositionGetPositionTakeProfitByIdTakeprofitAndTakeProfitIdReq
      >({
        query: (queryArg) => ({
          url: `/api/Position/GetPositionTakeProfit/${queryArg.id}/takeprofit/${queryArg.takeProfitId}`,
        }),
      }),
    postApiPositionNewOrder: build.mutation<
      PostApiPositionNewOrderResp,
      PostApiPositionNewOrderReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/NewOrder`,
        method: "POST",
        body: queryArg.newOrderModel,
      }),
    }),
    postApiPositionCancelOrderByIdCancelorder: build.mutation<
      PostApiPositionCancelOrderByIdCancelorderResp,
      PostApiPositionCancelOrderByIdCancelorderReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/CancelOrder/${queryArg.id}/cancelorder`,
        method: "POST",
      }),
    }),
    putApiPositionUpdatePositionById: build.mutation<
      PutApiPositionUpdatePositionByIdResp,
      PutApiPositionUpdatePositionByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/UpdatePosition/${queryArg.id}`,
        method: "PUT",
        body: queryArg.positionUpd,
      }),
    }),
    postApiPositionClosePositionByIdCloseposition: build.mutation<
      PostApiPositionClosePositionByIdClosepositionResp,
      PostApiPositionClosePositionByIdClosepositionReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/ClosePosition/${queryArg.id}/closeposition`,
        method: "POST",
      }),
    }),
    postApiPositionClosePositionPartialByIdClosepartialposition: build.mutation<
      PostApiPositionClosePositionPartialByIdClosepartialpositionResp,
      PostApiPositionClosePositionPartialByIdClosepartialpositionReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/ClosePositionPartial/${queryArg.id}/closepartialposition`,
        method: "POST",
        params: { perc: queryArg.perc },
      }),
    }),
    postApiPositionCreatePositionDetailByIdDetail: build.mutation<
      PostApiPositionCreatePositionDetailByIdDetailResp,
      PostApiPositionCreatePositionDetailByIdDetailReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/CreatePositionDetail/${queryArg.id}/detail`,
        method: "POST",
        body: queryArg.positionDetail,
      }),
    }),
    putApiPositionUpdatePositionDetailByIdDetailAndDetailId: build.mutation<
      PutApiPositionUpdatePositionDetailByIdDetailAndDetailIdResp,
      PutApiPositionUpdatePositionDetailByIdDetailAndDetailIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/UpdatePositionDetail/${queryArg.id}/detail/${queryArg.detailId}`,
        method: "PUT",
        body: queryArg.positionDetail,
      }),
    }),
    postApiPositionCreatePositionTakeProfitByIdTakeprofit: build.mutation<
      PostApiPositionCreatePositionTakeProfitByIdTakeprofitResp,
      PostApiPositionCreatePositionTakeProfitByIdTakeprofitReq
    >({
      query: (queryArg) => ({
        url: `/api/Position/CreatePositionTakeProfit/${queryArg.id}/takeprofit`,
        method: "POST",
        body: queryArg.positionTakeProfitModel,
      }),
    }),
    putApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitId:
      build.mutation<
        PutApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitIdResp,
        PutApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitIdReq
      >({
        query: (queryArg) => ({
          url: `/api/Position/UpdatePositionTakeProfit/${queryArg.id}/takeprofit/${queryArg.takeProfitId}`,
          method: "PUT",
          body: queryArg.positionTakeProfitModel,
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as positionApi };
export type PostApiChannelAddPositionProviderByIdProvidersAddResp =
  /** status 200 Success */ ChannelPositionProviderQrRead;
export type PostApiChannelAddPositionProviderByIdProvidersAddReq = {
  id: number;
  channelPositionProvider: ChannelPositionProvider;
};
export type GetApiChannelGetPositionProviderByIdProvidersResp =
  /** status 200 Success */ BaseUserIEnumerableQrRead;
export type GetApiChannelGetPositionProviderByIdProvidersReq = {
  id: number;
};
export type PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateResp =
  /** status 200 Success */ StringQrRead;
export type PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateReq =
  {
    id: number;
    providerId: string;
  };
export type PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateResp =
  /** status 200 Success */ StringQrRead;
export type PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateReq =
  {
    id: number;
    providerId: number;
    channelPositionProvider: ChannelPositionProvider;
  };
export type GetApiPositionGetPositionsResp =
  /** status 200 Success */ PositionIEnumerableQrRead;
export type GetApiPositionGetPositionsReq = void;
export type GetApiPositionGetPositionsLatestPriceResp =
  /** status 200 Success */ StringPositionLateDictionaryQrRead;
export type GetApiPositionGetPositionsLatestPriceReq = void;
export type GetApiPositionGetPositionsOfResp =
  /** status 200 Success */ PositionExListQrRead;
export type GetApiPositionGetPositionsOfReq = {
  typ?: string;
  active?: boolean;
  pg?: number;
  userid?: string;
};
export type GetApiPositionGetPositionsOfCResp =
  /** status 200 Success */ PositionExListQrRead;
export type GetApiPositionGetPositionsOfCReq = {
  chanId?: number;
  typ?: string;
  active?: boolean;
  pg?: number;
};
export type GetApiPositionGetPositionHistoryByIdResp =
  /** status 200 Success */ TradeHistory[];
export type GetApiPositionGetPositionHistoryByIdReq = {
  id: number;
};
export type GetApiPositionGetPositionByIdResp =
  /** status 200 Success */ PositionExQrRead;
export type GetApiPositionGetPositionByIdReq = {
  id: number;
};
export type GetApiPositionGetPositionDetailByIdDetailAndDetailIdResp =
  /** status 200 Success */ PositionDetailQrRead;
export type GetApiPositionGetPositionDetailByIdDetailAndDetailIdReq = {
  id: number;
  detailId: number;
};
export type GetApiPositionGetPositionTakeProfitByIdTakeprofitAndTakeProfitIdResp =
  /** status 200 Success */ PositionTakeProfitQrRead;
export type GetApiPositionGetPositionTakeProfitByIdTakeprofitAndTakeProfitIdReq =
  {
    id: number;
    takeProfitId: number;
  };
export type PostApiPositionNewOrderResp =
  /** status 200 Success */ PositionQrRead;
export type PostApiPositionNewOrderReq = {
  newOrderModel: NewOrderModel;
};
export type PostApiPositionCancelOrderByIdCancelorderResp =
  /** status 200 Success */ StringQrRead;
export type PostApiPositionCancelOrderByIdCancelorderReq = {
  id: number;
};
export type PutApiPositionUpdatePositionByIdResp =
  /** status 200 Success */ StringQrRead;
export type PutApiPositionUpdatePositionByIdReq = {
  id: number;
  positionUpd: PositionUpd;
};
export type PostApiPositionClosePositionByIdClosepositionResp =
  /** status 200 Success */ StringQrRead;
export type PostApiPositionClosePositionByIdClosepositionReq = {
  id: number;
};
export type PostApiPositionClosePositionPartialByIdClosepartialpositionResp =
  /** status 200 Success */ StringQrRead;
export type PostApiPositionClosePositionPartialByIdClosepartialpositionReq = {
  id: number;
  perc?: number;
};
export type PostApiPositionCreatePositionDetailByIdDetailResp =
  /** status 200 Success */ PositionDetailQrRead;
export type PostApiPositionCreatePositionDetailByIdDetailReq = {
  id: number;
  positionDetail: PositionDetail;
};
export type PutApiPositionUpdatePositionDetailByIdDetailAndDetailIdResp =
  /** status 200 Success */ StringQrRead;
export type PutApiPositionUpdatePositionDetailByIdDetailAndDetailIdReq = {
  id: number;
  detailId: number;
  positionDetail: PositionDetail;
};
export type PostApiPositionCreatePositionTakeProfitByIdTakeprofitResp =
  /** status 200 Success */ PositionTakeProfitQrRead;
export type PostApiPositionCreatePositionTakeProfitByIdTakeprofitReq = {
  id: number;
  positionTakeProfitModel: PositionTakeProfitModel;
};
export type PutApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitIdResp =
  /** status 200 Success */ StringQrRead;
export type PutApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitIdReq =
  {
    id: number;
    takeProfitId: number;
    positionTakeProfitModel: PositionTakeProfitModel;
  };
export type ChannelPositionProvider = {
  id?: number;
  channelId?: number;
  positionProviderId?: string | null;
  active?: boolean;
};
export type ChannelPositionProviderRead = {
  id?: number;
  channelId?: number;
  positionProviderId?: string | null;
  active?: boolean;
  registerAt?: string;
};
export type ChannelPositionProviderQr = {
  data?: ChannelPositionProvider;
};
export type ChannelPositionProviderQrRead = {
  data?: ChannelPositionProviderRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type BaseUserIEnumerableQr = {};
export type BaseUser = {
  username?: string | null;
  userId?: string | null;
  joinDate?: string;
  expire?: string;
};
export type BaseUserIEnumerableQrRead = {
  data?: BaseUser[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type StringQr = {};
export type StringQrRead = {
  data?: string | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PositionIEnumerableQr = {};
export type PositionType = 0 | 1;
export type PositionTakeProfit = {
  id?: number;
  isSuccess?: boolean;
  positionId?: number;
  profitPercentageClosed?: number;
  protfitPrice?: number;
  profitClosed?: number;
  qtyClosed?: number;
  active?: boolean;
  createdAt?: string;
  executedAt?: string | null;
};
export type PositionDetail = {
  id?: number;
  positionId?: number;
  type?: string | null;
  value?: number;
  active?: boolean;
};
export type PositionStateEnum = 0 | 1 | 9 | 10;
export type Position = {
  id?: number;
  channelId?: number;
  pairId?: number;
  exchangeId?: number;
  createdAt?: string;
  ownerId?: string | null;
  completed?: boolean;
  completedAt?: string | null;
  type?: PositionType;
  entryPrice?: number;
  pnl?: number;
  stopLoss?: number;
  takeProfits?: PositionTakeProfit[] | null;
  details?: PositionDetail[] | null;
  leverageMin?: number;
  leverageMax?: number;
  forceClosed?: boolean;
  exitPrice?: number;
  orderQty?: number;
  orderPrice?: number;
  qty?: number;
  qtyClosed?: number;
  active?: boolean;
  positionState?: PositionStateEnum;
};
export type PositionIEnumerableQrRead = {
  data?: Position[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type StringPositionLateDictionaryQr = {};
export type PositionLate = {
  price?: number;
  liqPrice?: number;
};
export type StringPositionLateDictionaryQrRead = {
  data?: {
    [key: string]: PositionLate;
  } | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PositionExListQr = {};
export type PositionEx = {
  id?: number;
  channelId?: number;
  pairId?: number;
  exchangeId?: number;
  createdAt?: string;
  ownerId?: string | null;
  completed?: boolean;
  completedAt?: string | null;
  type?: PositionType;
  entryPrice?: number;
  pnl?: number;
  stopLoss?: number;
  takeProfits?: PositionTakeProfit[] | null;
  details?: PositionDetail[] | null;
  leverageMin?: number;
  leverageMax?: number;
  forceClosed?: boolean;
  exitPrice?: number;
  orderQty?: number;
  orderPrice?: number;
  qty?: number;
  qtyClosed?: number;
  active?: boolean;
  positionState?: PositionStateEnum;
  channelName?: string | null;
  exchangeName?: string | null;
  pairName?: string | null;
  ownerName?: string | null;
  ownerRef?: string | null;
};
export type PositionExListQrRead = {
  data?: PositionEx[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type CTradeType = 0 | 1 | 2 | 3 | 13 | 22 | 23 | 32 | 33;
export type COrderType = 0 | 1;
export type TradeHistory = {
  id?: number;
  active?: boolean;
  positionId?: number;
  qty?: number;
  price?: number;
  entryPrice?: number;
  pnl?: number;
  tradeTyp?: CTradeType;
  orderTyp?: COrderType;
  createdAt?: string;
  executedAt?: string | null;
};
export type PositionExQr = {
  data?: PositionEx;
};
export type PositionExQrRead = {
  data?: PositionEx;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PositionDetailQr = {
  data?: PositionDetail;
};
export type PositionDetailQrRead = {
  data?: PositionDetail;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PositionTakeProfitQr = {
  data?: PositionTakeProfit;
};
export type PositionTakeProfitQrRead = {
  data?: PositionTakeProfit;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PositionQr = {
  data?: Position;
};
export type PositionQrRead = {
  data?: Position;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type TakeProfitModel = {
  price?: number;
  perc?: number;
};
export type NewOrderModel = {
  exchangeId?: number;
  pairId?: number;
  positionSide?: number;
  leverage?: number;
  isMarket?: boolean;
  price?: number;
  quantity?: number;
  stoploss?: number | null;
  channelId?: number;
  takeProfits?: TakeProfitModel[] | null;
};
export type PositionUpd = {
  stopLoss?: number;
  orderQty?: number;
  orderPrice?: number;
  leverageMax?: number;
};
export type PositionTakeProfitModel = {
  active?: boolean;
  protfitPrice?: number;
  profitPercentageClosed?: number;
};
export const {
  usePostApiChannelAddPositionProviderByIdProvidersAddMutation,
  useGetApiChannelGetPositionProviderByIdProvidersQuery,
  useLazyGetApiChannelGetPositionProviderByIdProvidersQuery,
  usePutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateMutation,
  usePutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateMutation,
  useGetApiPositionGetPositionsQuery,
  useLazyGetApiPositionGetPositionsQuery,
  useGetApiPositionGetPositionsLatestPriceQuery,
  useLazyGetApiPositionGetPositionsLatestPriceQuery,
  useGetApiPositionGetPositionsOfQuery,
  useLazyGetApiPositionGetPositionsOfQuery,
  useGetApiPositionGetPositionsOfCQuery,
  useLazyGetApiPositionGetPositionsOfCQuery,
  useGetApiPositionGetPositionHistoryByIdQuery,
  useLazyGetApiPositionGetPositionHistoryByIdQuery,
  useGetApiPositionGetPositionByIdQuery,
  useLazyGetApiPositionGetPositionByIdQuery,
  useGetApiPositionGetPositionDetailByIdDetailAndDetailIdQuery,
  useLazyGetApiPositionGetPositionDetailByIdDetailAndDetailIdQuery,
  useGetApiPositionGetPositionTakeProfitByIdTakeprofitAndTakeProfitIdQuery,
  useLazyGetApiPositionGetPositionTakeProfitByIdTakeprofitAndTakeProfitIdQuery,
  usePostApiPositionNewOrderMutation,
  usePostApiPositionCancelOrderByIdCancelorderMutation,
  usePutApiPositionUpdatePositionByIdMutation,
  usePostApiPositionClosePositionByIdClosepositionMutation,
  usePostApiPositionClosePositionPartialByIdClosepartialpositionMutation,
  usePostApiPositionCreatePositionDetailByIdDetailMutation,
  usePutApiPositionUpdatePositionDetailByIdDetailAndDetailIdMutation,
  usePostApiPositionCreatePositionTakeProfitByIdTakeprofitMutation,
  usePutApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitIdMutation,
} = injectedRtkApi;
