import { exchangeApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiExchangeGetExchanges: build.query<
      GetApiExchangeGetExchangesResp,
      GetApiExchangeGetExchangesReq
    >({
      query: () => ({ url: `/api/Exchange/GetExchanges` }),
    }),
    getApiExchangeGetExchangeById: build.query<
      GetApiExchangeGetExchangeByIdResp,
      GetApiExchangeGetExchangeByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Exchange/GetExchange/${queryArg.id}`,
      }),
    }),
    postApiExchangeCreateExchange: build.mutation<
      PostApiExchangeCreateExchangeResp,
      PostApiExchangeCreateExchangeReq
    >({
      query: (queryArg) => ({
        url: `/api/Exchange/CreateExchange`,
        method: "POST",
        body: queryArg.exchange,
      }),
    }),
    putApiExchangeUpdateExchangeById: build.mutation<
      PutApiExchangeUpdateExchangeByIdResp,
      PutApiExchangeUpdateExchangeByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Exchange/UpdateExchange/${queryArg.id}`,
        method: "PUT",
        body: queryArg.exchange,
      }),
    }),
    deleteApiExchangeDeleteExchangeById: build.mutation<
      DeleteApiExchangeDeleteExchangeByIdResp,
      DeleteApiExchangeDeleteExchangeByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Exchange/DeleteExchange/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiGreetGetExchange: build.query<
      GetApiGreetGetExchangeResp,
      GetApiGreetGetExchangeReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchange`,
        params: { id: queryArg.id },
      }),
    }),
    getApiGreetGetExchanges: build.query<
      GetApiGreetGetExchangesResp,
      GetApiGreetGetExchangesReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchanges`,
        params: { most: queryArg.most, dex: queryArg.dex },
      }),
    }),
    getApiGreetGetPairChange2ByExchange: build.query<
      GetApiGreetGetPairChange2ByExchangeResp,
      GetApiGreetGetPairChange2ByExchangeReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetPairChange2ByExchange`,
        params: { exchangeName: queryArg.exchangeName },
      }),
    }),
    getApiGreetGetExchangesSupport: build.query<
      GetApiGreetGetExchangesSupportResp,
      GetApiGreetGetExchangesSupportReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchangesSupport`,
        params: { ws: queryArg.ws, rest: queryArg.rest },
      }),
    }),
    getApiGreetGetExchangesPairs: build.query<
      GetApiGreetGetExchangesPairsResp,
      GetApiGreetGetExchangesPairsReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchangesPairs`,
        params: { exchangeId: queryArg.exchangeId },
      }),
    }),
    getApiGreetGetExchangePairPerf: build.query<
      GetApiGreetGetExchangePairPerfResp,
      GetApiGreetGetExchangePairPerfReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchangePairPerf`,
        params: { exchangeId: queryArg.exchangeId, pairId: queryArg.pairId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as exchangeApi };
export type GetApiExchangeGetExchangesResp =
  /** status 200 Success */ ExchangeIEnumerableQrRead;
export type GetApiExchangeGetExchangesReq = void;
export type GetApiExchangeGetExchangeByIdResp =
  /** status 200 Success */ StringQrRead;
export type GetApiExchangeGetExchangeByIdReq = {
  id: number;
};
export type PostApiExchangeCreateExchangeResp =
  /** status 200 Success */ ExchangeQrRead;
export type PostApiExchangeCreateExchangeReq = {
  exchange: Exchange;
};
export type PutApiExchangeUpdateExchangeByIdResp =
  /** status 200 Success */ StringQrRead;
export type PutApiExchangeUpdateExchangeByIdReq = {
  id: number;
  exchange: Exchange;
};
export type DeleteApiExchangeDeleteExchangeByIdResp =
  /** status 200 Success */ StringQrRead;
export type DeleteApiExchangeDeleteExchangeByIdReq = {
  id: number;
};
export type GetApiGreetGetExchangeResp =
  /** status 200 Success */ ExchangeInfoDetailQrRead;
export type GetApiGreetGetExchangeReq = {
  id?: number;
};
export type GetApiGreetGetExchangesResp =
  /** status 200 Success */ ExchangeInfoListQrRead;
export type GetApiGreetGetExchangesReq = {
  most?: number;
  dex?: boolean;
};
export type GetApiGreetGetPairChange2ByExchangeResp =
  /** status 200 Success */ CandleChangeRowListQrRead;
export type GetApiGreetGetPairChange2ByExchangeReq = {
  exchangeName?: string;
};
export type GetApiGreetGetExchangesSupportResp =
  /** status 200 Success */ ExchangeInfoListQrRead;
export type GetApiGreetGetExchangesSupportReq = {
  ws?: boolean;
  rest?: boolean;
};
export type GetApiGreetGetExchangesPairsResp =
  /** status 200 Success */ PairInfoListQrRead;
export type GetApiGreetGetExchangesPairsReq = {
  exchangeId?: number;
};
export type GetApiGreetGetExchangePairPerfResp =
  /** status 200 Success */ KlinePerfDataListQrRead;
export type GetApiGreetGetExchangePairPerfReq = {
  exchangeId?: number;
  pairId?: number;
};
export type ExchangeIEnumerableQr = {};
export type ExchangePair = {
  id?: number;
  exchangeId?: number;
  pairId?: number;
  active?: boolean;
  mmrT1?: number;
  mmrT1Value?: number;
  mmrT1NV?: number;
  mmrT2NV?: number;
  mmrT2?: number;
  mmrT2Value?: number;
  fundingFee?: number;
  fundingFeeNext?: string;
  fundingFeeCurrent?: number;
  fundingFeeCurrentDate?: string;
  fundingFeeApplied?: number;
  fundingFeeCurrentApplied?: string;
};
export type Linked = {
  id?: number;
  name?: string | null;
  url?: string | null;
  icon?: string | null;
  order?: number;
  lType?: string | null;
  verified?: boolean;
  verifiedAt?: string | null;
  active?: boolean;
  verificationCode?: string | null;
};
export type ExchangeTag = {
  id?: number;
  exchangeId?: number;
  tagId?: number;
};
export type DataProperty = {
  id?: number;
  name?: string | null;
  value?: string | null;
};
export type Exchange = {
  id?: number;
  name?: string | null;
  exchangePairs?: ExchangePair[] | null;
  linkedList?: Linked[] | null;
  tags?: ExchangeTag[] | null;
  properties?: DataProperty[] | null;
  exType?: string | null;
  decentralized?: boolean;
  icon?: string | null;
  country?: string | null;
  score?: number;
  vol24hr?: number;
  homepage?: string | null;
  isApiWebsocket?: boolean;
  isApiRest?: boolean;
  active?: boolean;
};
export type ExchangeIEnumerableQrRead = {
  data?: Exchange[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type StringQr = {};
export type StringQrRead = {
  data?: string | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ExchangeQr = {
  data?: Exchange;
};
export type ExchangeQrRead = {
  data?: Exchange;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ExchangePairNamed = {
  exchangeUpper?: string | null;
  exchangeName?: string | null;
  pairName?: string | null;
};
export type ExchangeInfoDetail = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
  links?: Linked[] | null;
  exchangePairs?: ExchangePairNamed[] | null;
  description?: string | null;
};
export type Tag = {
  id?: number;
  name?: string | null;
  description?: string | null;
};
export type ExchangeInfoDetailRead = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
  isWs?: boolean;
  isRest?: boolean;
  links?: Linked[] | null;
  exchangePairs?: ExchangePairNamed[] | null;
  description?: string | null;
  props?: DataProperty[] | null;
  tags?: Tag[] | null;
};
export type ExchangeInfoDetailQr = {
  data?: ExchangeInfoDetail;
};
export type ExchangeInfoDetailQrRead = {
  data?: ExchangeInfoDetailRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ExchangeInfoListQr = {};
export type ExchangeInfo = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
};
export type ExchangeInfoRead = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
  isWs?: boolean;
  isRest?: boolean;
};
export type ExchangeInfoListQrRead = {
  data?: ExchangeInfoRead[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type CandleChangeRowListQr = {};
export type CandleChangeRow = {
  bucket?: string;
  minopen?: number;
  maxclose?: number;
  minlow?: number;
  maxhigh?: number;
  last_close?: number;
  last_time?: string;
  op_cl_diff?: number;
  hi_low_diff?: number;
  exchangeName?: string | null;
  pairName?: string | null;
  intv?: string | null;
};
export type CandleChangeRowListQrRead = {
  data?: CandleChangeRow[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PairInfoListQr = {};
export type PairInfo = {
  id?: number;
  name?: string | null;
  icon?: string | null;
};
export type PairInfoRead = {
  id?: number;
  name?: string | null;
  icon?: string | null;
  decimals?: number;
};
export type PairInfoListQrRead = {
  data?: PairInfoRead[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type KlinePerfDataListQr = {};
export type KlineIntervalL =
  | 1
  | 60
  | 180
  | 300
  | 900
  | 1800
  | 3600
  | 7200
  | 14400
  | 21600
  | 28800
  | 43200
  | 86400
  | 259200
  | 604800
  | 2592000;
export type KlinePerfData = {
  dataTime?: string;
  openTime?: string;
  openPrice?: number;
  highPrice?: number;
  lowPrice?: number;
  closePrice?: number;
  volume?: number;
  interval?: KlineIntervalL;
};
export type KlinePerfDataListQrRead = {
  data?: KlinePerfData[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export const {
  useGetApiExchangeGetExchangesQuery,
  useLazyGetApiExchangeGetExchangesQuery,
  useGetApiExchangeGetExchangeByIdQuery,
  useLazyGetApiExchangeGetExchangeByIdQuery,
  usePostApiExchangeCreateExchangeMutation,
  usePutApiExchangeUpdateExchangeByIdMutation,
  useDeleteApiExchangeDeleteExchangeByIdMutation,
  useGetApiGreetGetExchangeQuery,
  useLazyGetApiGreetGetExchangeQuery,
  useGetApiGreetGetExchangesQuery,
  useLazyGetApiGreetGetExchangesQuery,
  useGetApiGreetGetPairChange2ByExchangeQuery,
  useLazyGetApiGreetGetPairChange2ByExchangeQuery,
  useGetApiGreetGetExchangesSupportQuery,
  useLazyGetApiGreetGetExchangesSupportQuery,
  useGetApiGreetGetExchangesPairsQuery,
  useLazyGetApiGreetGetExchangesPairsQuery,
  useGetApiGreetGetExchangePairPerfQuery,
  useLazyGetApiGreetGetExchangePairPerfQuery,
} = injectedRtkApi;
