import { greetApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiGreetGetMarket: build.query<
      GetApiGreetGetMarketResp,
      GetApiGreetGetMarketReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetMarket`,
        params: { most: queryArg.most },
      }),
    }),
    getApiGreetGetCurrency: build.query<
      GetApiGreetGetCurrencyResp,
      GetApiGreetGetCurrencyReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetCurrency`,
        params: { coin: queryArg.coin },
      }),
    }),
    getApiGreetGetCurrencies: build.query<
      GetApiGreetGetCurrenciesResp,
      GetApiGreetGetCurrenciesReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetCurrencies`,
        params: { most: queryArg.most },
      }),
    }),
    getApiGreetGetCurrenciesChange: build.query<
      GetApiGreetGetCurrenciesChangeResp,
      GetApiGreetGetCurrenciesChangeReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetCurrenciesChange`,
        params: { most: queryArg.most, up: queryArg.up },
      }),
    }),
    getApiGreetGetExchange: build.query<
      GetApiGreetGetExchangeResp,
      GetApiGreetGetExchangeReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchange`,
        params: { id: queryArg.id },
      }),
    }),
    getApiGreetGetExchanges: build.query<
      GetApiGreetGetExchangesResp,
      GetApiGreetGetExchangesReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchanges`,
        params: { most: queryArg.most, dex: queryArg.dex },
      }),
    }),
    getApiGreetGetMarketPrice: build.query<
      GetApiGreetGetMarketPriceResp,
      GetApiGreetGetMarketPriceReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetMarketPrice`,
        params: {
          exchangeName: queryArg.exchangeName,
          pairName: queryArg.pairName,
        },
      }),
    }),
    getApiGreetGetPairChange: build.query<
      GetApiGreetGetPairChangeResp,
      GetApiGreetGetPairChangeReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetPairChange`,
        params: {
          exchangeName: queryArg.exchangeName,
          pairName: queryArg.pairName,
        },
      }),
    }),
    getApiGreetGetGlossary: build.query<
      GetApiGreetGetGlossaryResp,
      GetApiGreetGetGlossaryReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetGlossary`,
        params: { key: queryArg.key },
      }),
    }),
    getApiGreetGetPairChange2ByExchange: build.query<
      GetApiGreetGetPairChange2ByExchangeResp,
      GetApiGreetGetPairChange2ByExchangeReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetPairChange2ByExchange`,
        params: { exchangeName: queryArg.exchangeName },
      }),
    }),
    getApiGreetGetExchangesSupport: build.query<
      GetApiGreetGetExchangesSupportResp,
      GetApiGreetGetExchangesSupportReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchangesSupport`,
        params: { ws: queryArg.ws, rest: queryArg.rest },
      }),
    }),
    getApiGreetGetExchangesPairs: build.query<
      GetApiGreetGetExchangesPairsResp,
      GetApiGreetGetExchangesPairsReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchangesPairs`,
        params: { exchangeId: queryArg.exchangeId },
      }),
    }),
    getApiGreetGetAvatarByIdAvatarById: build.query<
      GetApiGreetGetAvatarByIdAvatarByIdResp,
      GetApiGreetGetAvatarByIdAvatarByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetAvatarById/avatar/${queryArg.id}`,
        params: { entity: queryArg.entity },
      }),
    }),
    getApiGreetGetExchangePairPerf: build.query<
      GetApiGreetGetExchangePairPerfResp,
      GetApiGreetGetExchangePairPerfReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/GetExchangePairPerf`,
        params: { exchangeId: queryArg.exchangeId, pairId: queryArg.pairId },
      }),
    }),
    getApiGreetGetPnl: build.query<GetApiGreetGetPnlResp, GetApiGreetGetPnlReq>(
      {
        query: (queryArg) => ({
          url: `/api/Greet/GetPnl`,
          params: {
            key: queryArg.key,
            pairid: queryArg.pairid,
            chanid: queryArg.chanid,
            take: queryArg.take,
            dir: queryArg.dir,
          },
        }),
      }
    ),
    postApiGreetFindUsers: build.mutation<
      PostApiGreetFindUsersResp,
      PostApiGreetFindUsersReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/FindUsers`,
        method: "POST",
        body: queryArg.searchBox,
      }),
    }),
    postApiGreetFindChannels: build.mutation<
      PostApiGreetFindChannelsResp,
      PostApiGreetFindChannelsReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/FindChannels`,
        method: "POST",
        body: queryArg.searchBox,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as greetApi };
export type GetApiGreetGetMarketResp =
  /** status 200 Success */ DataTickerViewListQrRead;
export type GetApiGreetGetMarketReq = {
  most?: number;
};
export type GetApiGreetGetCurrencyResp =
  /** status 200 Success */ CurrencyDetailQrRead;
export type GetApiGreetGetCurrencyReq = {
  coin?: string;
};
export type GetApiGreetGetCurrenciesResp =
  /** status 200 Success */ CurrencyInfoListQrRead;
export type GetApiGreetGetCurrenciesReq = {
  most?: number;
};
export type GetApiGreetGetCurrenciesChangeResp =
  /** status 200 Success */ CurrencyInfoListQrRead;
export type GetApiGreetGetCurrenciesChangeReq = {
  most?: number;
  up?: boolean;
};
export type GetApiGreetGetExchangeResp =
  /** status 200 Success */ ExchangeInfoDetailQrRead;
export type GetApiGreetGetExchangeReq = {
  id?: number;
};
export type GetApiGreetGetExchangesResp =
  /** status 200 Success */ ExchangeInfoListQrRead;
export type GetApiGreetGetExchangesReq = {
  most?: number;
  dex?: boolean;
};
export type GetApiGreetGetMarketPriceResp =
  /** status 200 Success */ DoubleValueQrRead;
export type GetApiGreetGetMarketPriceReq = {
  exchangeName?: string;
  pairName?: string;
};
export type GetApiGreetGetPairChangeResp =
  /** status 200 Success */ CandleChangeRowListQrRead;
export type GetApiGreetGetPairChangeReq = {
  exchangeName?: string;
  pairName?: string;
};
export type GetApiGreetGetGlossaryResp =
  /** status 200 Success */ GlossaryListQrRead;
export type GetApiGreetGetGlossaryReq = {
  key?: string;
};
export type GetApiGreetGetPairChange2ByExchangeResp =
  /** status 200 Success */ CandleChangeRowListQrRead;
export type GetApiGreetGetPairChange2ByExchangeReq = {
  exchangeName?: string;
};
export type GetApiGreetGetExchangesSupportResp =
  /** status 200 Success */ ExchangeInfoListQrRead;
export type GetApiGreetGetExchangesSupportReq = {
  ws?: boolean;
  rest?: boolean;
};
export type GetApiGreetGetExchangesPairsResp =
  /** status 200 Success */ PairInfoListQrRead;
export type GetApiGreetGetExchangesPairsReq = {
  exchangeId?: number;
};
export type GetApiGreetGetAvatarByIdAvatarByIdResp = unknown;
export type GetApiGreetGetAvatarByIdAvatarByIdReq = {
  entity?: string;
  id: string;
};
export type GetApiGreetGetExchangePairPerfResp =
  /** status 200 Success */ KlinePerfDataListQrRead;
export type GetApiGreetGetExchangePairPerfReq = {
  exchangeId?: number;
  pairId?: number;
};
export type GetApiGreetGetPnlResp =
  /** status 200 Success */ PnLBoardListQrRead;
export type GetApiGreetGetPnlReq = {
  key?: number;
  pairid?: number;
  chanid?: number;
  take?: number;
  dir?: boolean;
};
export type PostApiGreetFindUsersResp =
  /** status 200 Success */ SearchBoxResultListQrRead;
export type PostApiGreetFindUsersReq = {
  searchBox: SearchBox;
};
export type PostApiGreetFindChannelsResp =
  /** status 200 Success */ SearchBoxResultListQrRead;
export type PostApiGreetFindChannelsReq = {
  searchBox: SearchBox;
};
export type DataTickerViewListQr = {};
export type DataTickerView = {
  id?: number;
  price?: number;
  name?: string | null;
  symbol?: string | null;
  change24Hr?: number;
};
export type DataTickerViewListQrRead = {
  data?: DataTickerView[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type Linked = {
  id?: number;
  name?: string | null;
  url?: string | null;
  icon?: string | null;
  order?: number;
  lType?: string | null;
  verified?: boolean;
  verifiedAt?: string | null;
  active?: boolean;
  verificationCode?: string | null;
};
export type ExchangePairNamed = {
  exchangeUpper?: string | null;
  exchangeName?: string | null;
  pairName?: string | null;
};
export type CurrencyDetail = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  price?: number;
  change24Hr?: number;
  maxSup?: number;
  cirSup?: number;
  tier?: number;
  ath?: number;
  symbol?: string | null;
  links?: Linked[] | null;
  exchangePairs?: ExchangePairNamed[] | null;
  description?: string | null;
};
export type CurrencyDetailQr = {
  data?: CurrencyDetail;
};
export type CurrencyDetailQrRead = {
  data?: CurrencyDetail;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type CurrencyInfoListQr = {};
export type CurrencyInfo = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  price?: number;
  change24Hr?: number;
  maxSup?: number;
  cirSup?: number;
  tier?: number;
  ath?: number;
  symbol?: string | null;
};
export type CurrencyInfoListQrRead = {
  data?: CurrencyInfo[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ExchangeInfoDetail = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
  links?: Linked[] | null;
  exchangePairs?: ExchangePairNamed[] | null;
  description?: string | null;
};
export type DataProperty = {
  id?: number;
  name?: string | null;
  value?: string | null;
};
export type Tag = {
  id?: number;
  name?: string | null;
  description?: string | null;
};
export type ExchangeInfoDetailRead = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
  isWs?: boolean;
  isRest?: boolean;
  links?: Linked[] | null;
  exchangePairs?: ExchangePairNamed[] | null;
  description?: string | null;
  props?: DataProperty[] | null;
  tags?: Tag[] | null;
};
export type ExchangeInfoDetailQr = {
  data?: ExchangeInfoDetail;
};
export type ExchangeInfoDetailQrRead = {
  data?: ExchangeInfoDetailRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ExchangeInfoListQr = {};
export type ExchangeInfo = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
};
export type ExchangeInfoRead = {
  id?: number;
  icon?: string | null;
  name?: string | null;
  score?: number;
  country?: string | null;
  exType?: string | null;
  homepage?: string | null;
  isWs?: boolean;
  isRest?: boolean;
};
export type ExchangeInfoListQrRead = {
  data?: ExchangeInfoRead[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type DoubleValue = {
  dValue?: number;
};
export type DoubleValueQr = {
  data?: DoubleValue;
};
export type DoubleValueQrRead = {
  data?: DoubleValue;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type CandleChangeRowListQr = {};
export type CandleChangeRow = {
  bucket?: string;
  minopen?: number;
  maxclose?: number;
  minlow?: number;
  maxhigh?: number;
  last_close?: number;
  last_time?: string;
  op_cl_diff?: number;
  hi_low_diff?: number;
  exchangeName?: string | null;
  pairName?: string | null;
  intv?: string | null;
};
export type CandleChangeRowListQrRead = {
  data?: CandleChangeRow[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type GlossaryListQr = {};
export type Glossary = {
  id?: number;
  term?: string | null;
  slug?: string | null;
  shortEx?: string | null;
  longEx?: string | null;
  active?: boolean;
};
export type GlossaryListQrRead = {
  data?: Glossary[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PairInfoListQr = {};
export type PairInfo = {
  id?: number;
  name?: string | null;
  icon?: string | null;
};
export type PairInfoRead = {
  id?: number;
  name?: string | null;
  icon?: string | null;
  decimals?: number;
};
export type PairInfoListQrRead = {
  data?: PairInfoRead[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type KlinePerfDataListQr = {};
export type KlineIntervalL =
  | 1
  | 60
  | 180
  | 300
  | 900
  | 1800
  | 3600
  | 7200
  | 14400
  | 21600
  | 28800
  | 43200
  | 86400
  | 259200
  | 604800
  | 2592000;
export type KlinePerfData = {
  dataTime?: string;
  openTime?: string;
  openPrice?: number;
  highPrice?: number;
  lowPrice?: number;
  closePrice?: number;
  volume?: number;
  interval?: KlineIntervalL;
};
export type KlinePerfDataListQrRead = {
  data?: KlinePerfData[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type PnLBoardListQr = {};
export type PositionType = 0 | 1;
export type PnLBoard = {
  id?: number;
  completed?: boolean;
  exch?: string | null;
  pair?: string | null;
  pnl?: number;
  owner?: string | null;
  pType?: PositionType;
};
export type PnLBoardRead = {
  id?: number;
  completed?: boolean;
  exch?: string | null;
  pair?: string | null;
  pnl?: number;
  owner?: string | null;
  pType?: PositionType;
  entryPrice?: number;
  qty?: number;
  uPnl?: number;
  chan?: number;
  chanName?: string | null;
  sz?: number;
};
export type PnLBoardListQrRead = {
  data?: PnLBoardRead[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type SearchBoxResultListQr = {};
export type SearchBoxResult = {
  ownerId?: string | null;
  date?: string;
  num?: number;
  pnl?: number;
  name?: string | null;
};
export type SearchBoxResultListQrRead = {
  data?: SearchBoxResult[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type SearchBoxOrder = 0 | 1 | 2 | 3;
export type SearchBox = {
  name?: string | null;
  ethWallet?: string | null;
  best?: boolean;
  page?: number;
  sOrder?: SearchBoxOrder;
};
export const {
  useGetApiGreetGetMarketQuery,
  useLazyGetApiGreetGetMarketQuery,
  useGetApiGreetGetCurrencyQuery,
  useLazyGetApiGreetGetCurrencyQuery,
  useGetApiGreetGetCurrenciesQuery,
  useLazyGetApiGreetGetCurrenciesQuery,
  useGetApiGreetGetCurrenciesChangeQuery,
  useLazyGetApiGreetGetCurrenciesChangeQuery,
  useGetApiGreetGetExchangeQuery,
  useLazyGetApiGreetGetExchangeQuery,
  useGetApiGreetGetExchangesQuery,
  useLazyGetApiGreetGetExchangesQuery,
  useGetApiGreetGetMarketPriceQuery,
  useLazyGetApiGreetGetMarketPriceQuery,
  useGetApiGreetGetPairChangeQuery,
  useLazyGetApiGreetGetPairChangeQuery,
  useGetApiGreetGetGlossaryQuery,
  useLazyGetApiGreetGetGlossaryQuery,
  useGetApiGreetGetPairChange2ByExchangeQuery,
  useLazyGetApiGreetGetPairChange2ByExchangeQuery,
  useGetApiGreetGetExchangesSupportQuery,
  useLazyGetApiGreetGetExchangesSupportQuery,
  useGetApiGreetGetExchangesPairsQuery,
  useLazyGetApiGreetGetExchangesPairsQuery,
  useGetApiGreetGetAvatarByIdAvatarByIdQuery,
  useLazyGetApiGreetGetAvatarByIdAvatarByIdQuery,
  useGetApiGreetGetExchangePairPerfQuery,
  useLazyGetApiGreetGetExchangePairPerfQuery,
  useGetApiGreetGetPnlQuery,
  useLazyGetApiGreetGetPnlQuery,
  usePostApiGreetFindUsersMutation,
  usePostApiGreetFindChannelsMutation,
} = injectedRtkApi;
