import { NavLink, NavLinkProps } from 'react-router-dom';
import { useAppDispatch } from '../store';
import { closeMenu } from '../../features/config';


interface NavLinkCProps extends NavLinkProps {
    to: string;
  }
  

const NavLinkC: React.FC<NavLinkCProps> = (  { to, children, ...rest }) => {
    const dispatch = useAppDispatch();
    return (<NavLink 
            className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "isNo"} 
            {...rest}
            to={to} onClick={e => dispatch(closeMenu())} >{children}
            </NavLink>)
}

export default NavLinkC;