import React, { useState } from 'react';
import { usePostApiAuthUploadAvatarAvatarMutation } from '../api/authApi';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';

const UserAvatarUpload = ({show, onClose } : { show:boolean, onClose: () => void }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadAvatar] = usePostApiAuthUploadAvatarAvatarMutation();

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const fd = new FormData();
        fd.append("avatar", selectedFile);
        await uploadAvatar({
          body: fd as any,
        });
        toast.success('Avatar updated successfully');
        onClose();
        // Optionally, you can trigger a refresh or update of the avatar display
      } catch (error) {
        console.error('Error uploading avatar:', error);
        toast.error(error as any);
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} size='lg' scrollable={true} centered={true}>
    <Modal.Header closeButton>
      <Modal.Title>Upload Your Avatar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      
    <input type="file" accept="image/*" onChange={handleFileChange} />
      <Button size="sm" variant="primary"  onClick={handleUpload}>Upload</Button>
      <Button size="sm" variant="danger" onClick={onClose}>Cancel</Button>
    </Modal.Body>
  </Modal>
  );
};

export default UserAvatarUpload;