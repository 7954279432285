import { useEffect, useState } from "react";
import PlasterEditor from "../../components/plaster/PlasterEditor";
import useBodyClassnames from "../../components/useBodyClassnames";

const ToolPoster = () => {


	useBodyClassnames(["bg--dotted-3x3","scroll-is--active"]);

    return (<main className="site-content site-content--center page" id="wrapper">			
			<div className="container container--large">
            <h1 className="page-header__title">Poster Editor</h1>
                            <div className="mt-sm-auto mb-sm-auto">                 
                    <PlasterEditor />                          
                </div>
            </div>
        </main>)


};


export default ToolPoster;