import * as React from 'react';

type Props = {
    children?: React.ReactNode,
    title:string
  };

const SimplePage = ({title, children} : Props) => {


    return(
        <main className="site-content" id="wrapper">			
            <div className="site-content__inner">
                <div className="site-content__holder">
                    <h1 className="text-white">{title}</h1>
        
                    <div className="page-content">
                    {children}
                    </div>
                </div>
            </div>
        </main> 
    )

};


export default SimplePage;