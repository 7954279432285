import { Link } from "react-router-dom";
import { PnLBoard, PnLBoardRead } from "../../api/greetApi";
import { CurrencyViewWithColorStyle } from "../CurrencyView";
import PriceDisplay from "../PriceDisplay";

interface PnLBoxProps {
    data: PnLBoardRead;
}

const PnlBox: React.FC<PnLBoxProps> = ({data}) => {


    return (<li className="top-bar-carousel__item x2018 slick-slide">
                <div className="match-score-inline">
                    <div className="match-score-inline__body">
                        <div className="match-score-inline__body-inner">
                            <figure className="match-team" role="group">
                                
                             
                                <figcaption>
                                    <div className="match-team__name">{data.pair!}</div>
                                    <div className="match-team__country ">{data.exch!}</div>
                                </figcaption>
                            </figure>
                            <div className="match-result match-result--winner-right">
                                <span className="match-result__score">
                                <CurrencyViewWithColorStyle value={data.pnl!} />
                                </span>
                            </div>
                           
                        </div>
                        <div className="match-score-inline__body-inner">
                            <figure className="match-team" role="group">
                            <figure style={{'padding':'6px'}}>
                            <Link to={'/channel/'+data.chan!}>{data.chanName}</Link>
                                </figure>
                                <figcaption>
                                    
                                </figcaption>
                            </figure>
                           
                            <div className="match-result">
                                <span className="match-result__label">
                                    {data.pType == 0 ? <span className="text-success">LONG</span>: <span className="text-danger">SHORT</span>}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="match-score-inline__footer">
                        <ul className="match-stats-links">
                            {false && <li>
                                <a href="matches-overview-1.html">
                                    <svg role="img" className="df-icon df-icon--overview">
                                        <use xlinkHref="/assets/img/necromancers.svg#overview"/>
                                    </svg>
                                </a>
                            </li>}
                            <li>
                                <Link to={'/channel/'+data.chan!}>
                                    <svg role="img" className="df-icon df-icon--stats">
                                        <use xlinkHref="/assets/img/necromancers.svg#stats"/>
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/account/'+data.owner!}>
                                    <svg role="img" className="df-icon df-icon--lineups">
                                        <use xlinkHref="/assets/img/necromancers.svg#lineups"/>
                                    </svg>
                                </Link>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </li>)


}


export default PnlBox;