import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../../components/FormInput';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import { Turnstile } from '@marsidev/react-turnstile'
import type { TurnstileInstance } from '@marsidev/react-turnstile'


import { 
  usePostApiAuthRegisterUserMutation, 
  usePostApiAuthCreateTokenMutation, 
  PostApiAuthRegisterUserReq, 
  PostApiAuthCreateTokenReq,
  usePostApiAuthConfirmEmailValidateMutation,
  usePostApiAuthPingMutation

} from '../../api/authApi';
import { Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../store';
import { closeMenu } from '../../../features/config';

import { usePreviousUrl } from '../../components/PreviousUrl'; // Adjust the path accordingly
import React from 'react';
import OneSignal from 'react-onesignal';

/*
(window as any).recaptchaOptions = {
  //useRecaptchaNet: true,
  enterprise: true,
};
*/

const SITEKEY = process.env.REACT_APP_SITEKEY as string;
const TURNSTILESITEKEY = process.env.REACT_APP_TURNSTILESITEKEY as string;

const loginSchema = object({
  rememberme: boolean().optional(),
  email: string()
    .min(1,'Email address is required')
    .email('Email Address is invalid'),
  password: string()
    .min(1,'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
});
const registerSchema = object({
  regemail: string()
    .min(1,'Email address is required')
    .email('Email Address is invalid'),
  regpassword: string()
    .min(1,'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  regpasswordVerify: string()
    .min(1,'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
})
.refine( (data) => data.regpassword === data.regpasswordVerify, {
  path:['regpasswordVerify'], message:'Passwords does not match'
})

export type LoginInput = TypeOf<typeof loginSchema>;
export type RegisterInput = TypeOf<typeof registerSchema>;

/*
type ReCAPTCHAPropswRef = ReCAPTCHA & {
  ref: React.RefObject<ReCAPTCHA>;
};
*/

const LoginPage = () => {

  const ctx = usePreviousUrl();
  //const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const ref = React.useRef<TurnstileInstance>(null);
  const loginMethods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });
  const registerMethods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });
  
  

  const [ pong, { data : pingData } ] = usePostApiAuthPingMutation();

  useEffect( () => {
    if(pingData) {
    
      if('data' in pingData){
        console.log("ping result is ", pingData.data);
        if(pingData.data?.resultMessage){
          console.log("ping result",pingData.data?.resultMessage);
          //backer();
          let cbu = ctx.callbackUrl();
          if(cbu == "/login" || cbu=="/" || cbu == "" || cbu == "/confirmemailaccept") cbu = "/trader";
            navigate(cbu)
        } //else console.warn("no resultMessage");
      } else console.warn("no data");
    }

  },[pingData]);

  const config = useAppSelector( t => t.config);
  const dispatch = useAppDispatch();
  const [loginUser, { 
          isLoading: loginIsLoading, 
          isError: loginIsError, 
          error: loginError, 
          isSuccess: loginIsSuccess }] = usePostApiAuthCreateTokenMutation();
  const userAccessTokenExpiration = useAppSelector(p=>p.auth?.userToken?.accessTokenExpiration);
  const userId = useAppSelector(p=>p.auth?.userToken?.id);
  const [registerUser, { 
          isLoading: registerIsLoading, 
          isError: registerIsError, 
          error: registerError, 
          data: registerData,
          isSuccess: registerIsSuccess }] = usePostApiAuthRegisterUserMutation();


  const [ validateEmail, {
        isLoading: validationIsLoading,
        isSuccess: validationIsSuccess,
        isError: validationIsError,
        error: validationError
  }] = usePostApiAuthConfirmEmailValidateMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const dipatch = useAppDispatch();
  
 
  const [searchParams] = useSearchParams();

  useEffect(()=>{
      const co = searchParams.get("verification");
      const uid = searchParams.get("identity");
      if( co && co!='' && co!=null  )
      {
        validateEmail({ signInEmailConfirmation: {userId:uid, code: co }});
      }
  },[]);

  useEffect(()=>{
    if(validationIsSuccess) {
      toast.success('Email is verified, you may sign in.');
    }

  },[validationIsSuccess])


  const {
    reset: loginReset,
    handleSubmit : loginSubmit,
    formState: { isSubmitSuccessful : loginSubmitOk },
  } = loginMethods;

  const {
    reset : registerReset,
    handleSubmit : registerSubmit,
    formState: { isSubmitSuccessful: registerSubmitOk },
  } = registerMethods;

 
  useEffect( ()=> {
    if(config.menuShow){
      console.log("0000");
      dispatch(closeMenu());
    }
    pong();
  },[]);


  useEffect(() => {
    if (registerIsSuccess) {
      toast.success('You successfully registered, ' + registerData?.data?.resultMessage);
      navigate('/confirmemail');
    }
    if (registerIsError) {
      if(registerError!=undefined){
        const err = registerError as any;
        if(err.status=="PARSING_ERROR" || err.status=="FETCH_ERROR"){
          toast.error('Most likely there is an connection or server error.');
        }
        else {    
          ref?.current?.reset();      
          if (typeof((err).data.errors)=="object" ) {
            const ers = (err).data.errors["$"] as Array<string>;
            if(ers) {
              ers.forEach( (el: any)  => {              
                toast.error(el, { position: 'top-right',});
              });
            }
          } else {
            toast.error((registerError as any).data.message, {
              position: 'top-right',
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerIsLoading]);

  //const _cb = ["login", "", "/", "confirmemail", "forgotpasswordaccept","confirmemailaccept"];

  useEffect(() => {
    if (loginIsSuccess && userAccessTokenExpiration) {
      OneSignal.login(userId!).finally(()=> {
        let cbu = ctx.callbackUrl();
        toast.success('You successfully logged in');
        console.warn("onesignal- user registered", userId!);     
        console.warn("callback url...", cbu);
        if( 
            cbu.indexOf("login")>-1 || 
            cbu=="/" || cbu == "" || 
            cbu.indexOf('confirmemail')>-1 || 
            cbu.indexOf('forgotpasswordaccept')>-1 ||  
            cbu.indexOf('confirmemailaccept')>-1) 
          cbu = "/trader";
          navigate(cbu)
      });    
    }
    if (loginIsError) {
      if(loginError!=undefined){
        const err = loginError as any;
        if(err.status=="PARSING_ERROR" || err.status=="FETCH_ERROR"){
          toast.error('Most likely there is an connection or server error.');
        }
        else {      
          ref?.current?.reset();    
          if (typeof((err).data.errors)=="object" ) {
            const ers = (err).data.errors["$"] as Array<string>;
            if(ers) {
              ers.forEach( (el: any)  => {              
                toast.error(el, { position: 'top-right', });
              });
            }
          } else {
            toast.error((loginError as any).data.message, {
              position: 'top-right',
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginIsSuccess, loginIsError, userAccessTokenExpiration]);

  const [token, setToken] = useState<string|null>(null);
  
  const onSubmitLoginHandler: SubmitHandler<LoginInput> = async (values) => {
    
    //if(recaptchaRef.current!=null) {
      //console.log("recaptchaRef.current",recaptchaRef.current," before exec");
      //recaptchaRef.current.execute();
      try {
      //const t = await recaptchaRef.current.executeAsync();
      const t = ref.current?.getResponse();
      var vl: PostApiAuthCreateTokenReq = {
        signIn: {
          email : values.email,
          password: values.password,
          token: t
        }
      };
      //console.log("recaptchaRef.current",recaptchaRef.current,"login goes...");
      loginUser(vl);      
    }
    catch(ex) {
      console.warn(ex);
      console.trace();
      ref?.current?.reset();
    }
    
    //}
    //else console.log("recaptchaRef.current",recaptchaRef.current," is null");
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  const onSubmitRegisterHandler: SubmitHandler<RegisterInput> = async (values) => {
    //console.log("prev",recaptchaRef.current);
    //if(recaptchaRef.current!=null) {
      try {
      //const t = await recaptchaRef.current.executeAsync();
      //console.log("recaptchaRef.current",t," after exec");
      const tokenResponse = ref.current?.getResponse();
      
      var vl: PostApiAuthRegisterUserReq = {
        userLoginModel: {
          email: values.regemail,
          password: values.regpassword,
          confirmPassword: values.regpasswordVerify,
          token: tokenResponse,
          refcode: searchParams.get("refcode")
        }
      };
     
      registerUser(vl);      
    }catch(ex){ 
      console.warn(ex);
      ref?.current?.reset();
    }
    
  };

  return (
    <main className="site-content login-page" id="wrapper">
      <Row>        
        <Col id={'login'}  md={6}>
          <h2 className="h4">Login</h2>

        <FormProvider {...loginMethods}>
          <Form className="form login-form"            
            onSubmit={loginSubmit((values) => onSubmitLoginHandler(values))}
            noValidate
            autoComplete='off'                 
          >
            <p>Already registered, Welcome back!</p>
            {/* <ReCAPTCHA ref={recaptchaRef} size="invisible" onChange={ReCAPTCHAChange} sitekey={SITEKEY} /> */}
            
            <FormInput name='email'  label='' type='email' placeholder={'Your Email *'} invalid={''} />
            <FormInput name='password' label='' type='password' placeholder={'Password'} invalid={''} />

          	<div className="form-group d-flex justify-content-between align-items-center">
							{false && <label className="checkbox checkbox-inline">
								<input className="checkbox-input" type="checkbox" name="rememberme" /> Remember me<span className="checkbox-label">&nbsp;</span>
							</label>}
              <span></span>
							<span className="password-reminder">								
                <Link to={'/forgotpassword'}>Forgot password?</Link>
							</span>
						</div>
            <div className='pb-3'>
            <Turnstile style={{'margin':'auto'}} ref={ref} siteKey={TURNSTILESITEKEY}  />
            </div>
						<button className="btn btn-block login-form__button" disabled={loginIsLoading} >Login to your account!
            {loginIsLoading &&  <span className="spinner-grow spinner-grow-sm"></span>}
            </button>
            <p>
            <br />
            </p>
            
            <p>
            <br />
          </p>
          </Form>
        </FormProvider>
        </Col>        
        <Col md={6} id={'register'} >
        <h2 className="h4" onClick={e => scrollDown()}>Register now!</h2>
        
        <FormProvider {...registerMethods}>
          <Form className="form register-form"            
            onSubmit={registerSubmit(onSubmitRegisterHandler)}
            noValidate
            autoComplete='off'                 
          >
            <p>
          Create your free account and start building your community! or Join traders' channels to earn!
        </p>
						<div className="form-group">
              <FormInput name='regemail'  label='' type='email' placeholder={'Your Email *'} invalid={''} />
						</div>
						<div className="form-group">
              <FormInput name='regpassword' label='' type='password' placeholder={'Password'} invalid={''} />
						</div>
						<div className="form-group">
              <FormInput name='regpasswordVerify' label='' type='password' placeholder={'Password Repeat'} invalid={''} />
						</div>
            <div>
            <label>
            By creating an account, you agree to our{' '}
            <Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link> and{' '}
            <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.
          </label>
          </div>
						<button disabled={registerIsLoading} className="btn btn-primary btn-block register-form__button">Create your account!
            {registerIsLoading &&  <span className="spinner-grow spinner-grow-sm"></span>}
            </button>
						<div className="register-form__info">
							You'll receive a confirmation email with <br />a link to activate your account!
						</div>

<p>
  <br />
</p>
<p>
  <br />
</p>
            </Form>
        </FormProvider>
        </Col>
      </Row>
    </main>        
  );
};

export default LoginPage

