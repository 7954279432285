import { ReactNode, useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Position, PositionEx, useLazyGetApiPositionGetPositionsOfCQuery } from "../../api/positionApi";
import { toast } from "react-toastify";
import CurrencyView, { CurrencyViewWithColorStyle } from "../../components/CurrencyView";
import PositionTypeBox from "../../components/PositionTypeBox";
import { useAppSelector } from "../../store";
import PnlFrame from "../../components/PnlBox/PnlFrame";
import React from "react";
import { MemoTraderPageClosedRow } from "./TraderPageClosesRow";
import { useMediaQuery } from "react-responsive";

const ChannelPageClosed = ({ chanId}: {chanId:number}) => {
	const userAccessTokenExpiration = useAppSelector(p=>p.auth?.userToken?.accessTokenExpiration);
	const [ getPosOf , { data: getPosOfData, error: getPosOfError }] = useLazyGetApiPositionGetPositionsOfCQuery();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 
useEffect( ()=> {
	if(userAccessTokenExpiration)
		getPosOf({ chanId:chanId, active:true, typ:'PositionClosed', pg: 0});

},[]);

function displayPositions(arg0: PositionEx[]): ReactNode {		
	return(<>
	{
		arg0.map( v => {		
			return <MemoTraderPageClosedRow key={v.id!}  pos={v}  />
		})
	}	
	</>)
}
    return (<>
		<div className="table-responsive-sm" >
		<Table className="table matches-table table-hover table-dark table-insite">
			<thead>
				{
					!isTabletOrMobile && 
				<tr>
					<th title="Contract name will always be displayed when you're scrolling.">Contracts</th>
					<th>Exchange</th>
					
					<th className="text-center" title="A positive value of quantity indicates a long position, while a negative value indicates a short position">Qty</th>
					<th className="text-center" title="">Entry Price</th>
					<th className="text-center" title="Exit Price">Exit Price</th>
																																								
					<th className="text-center">Closed P&L</th>									
					<th className="text-center">Open Time</th>
					<th className="text-center">Close Time</th>
					<th></th>
				</tr>
				}
			</thead>
			<tbody>
			{getPosOfData && getPosOfData.data  && getPosOfData.data.length >0 && displayPositions(getPosOfData.data!) }								
				{ !(getPosOfData && getPosOfData.data  && getPosOfData.data.length >0 ) && 										
				<tr>
					<td colSpan={12}>
						<div className="pt-5 pb-4 text-center color-warning">											
							<svg role="img" style={{fill:'#ffcc00'}} height={25} className="df-icon df-icon--bag">
								<use xlinkHref="/assets/img/necromancers.svg#comments-small"></use></svg>
								<div className="pt-2">No record yet</div>
						</div>
					</td>
				</tr>
				}
			</tbody>
		</Table>	
		</div>	
	</>)
}

export default ChannelPageClosed;