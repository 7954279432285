
import { useEffect, useState } from "react";
import Decors from "../partials/Decors";

interface PNLOpt {
    leverage : number;
    entry_price: number |null;      
    stop_price: number;    
    target_price:number;
    initial_margin:number;
    is_long:boolean;
    risk: number | null;
    quantity: number;
    riskreward:number;
}
const ToolRiskReward = () => {

    const [t, sett] = useState<PNLOpt>({
            entry_price:null,
            is_long:true,
            leverage:1,            
            stop_price:0,
            risk:0,   
            target_price:0,
            initial_margin:0,
            quantity:0,
            riskreward:0
    });


    const goodNum = (n:number, maxFD:number = 10) =>  {

       return new Intl.NumberFormat("en-US", {
            signDisplay: "exceptZero",
            minimumFractionDigits: 2,
            maximumFractionDigits: maxFD
        }).format(n);

    }

    useEffect(()=> {

        const cpt = {...t};

        if(cpt.risk!=null && cpt.entry_price!=null && cpt.stop_price > 0) {
            cpt.quantity = cpt.risk / (cpt.entry_price - cpt.stop_price);
            if(cpt.target_price>0)
                cpt.riskreward  = ((cpt.target_price - cpt.entry_price) * cpt.quantity) / cpt.risk ;
            cpt.initial_margin = (cpt.entry_price * cpt.quantity) / cpt.leverage;
            sett(cpt);
        }
        
    },[t.entry_price, t.stop_price, t.target_price, t.leverage, t.is_long, t.risk]);


    return (
        <main className="site-content" id="wrapper">			
			<div className="site-content__inner">
            <div className="site-content__holder">
            <Decors>                            
                    Risk-reward is a concept in investing that assesses the potential gain compared to the potential loss of a particular investment. It involves evaluating the level of risk associated with an investment in relation to the potential return. Investors often seek a balance between taking on a reasonable amount of risk and achieving a desirable reward. The risk-reward ratio is a common metric used to quantify this relationship, expressing the potential profit (reward) relative to the potential loss (risk). A favorable risk-reward ratio indicates that the potential reward outweighs the potential risk, making the investment more attractive.                            
            </Decors>
                <div className="page-content">
                <h4 className="mb-5">Risk Reward</h4>
                <div className="row ">
                    <div className="col-md-6">
                        <div className="form-group">

                            <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" defaultChecked 
                             onChange={e => sett({...t, is_long:true } )} 
                            />
                            <label className="btn btn-outline-success" htmlFor="success-outlined">Long</label>

                            <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" 
                            onChange={e => sett({...t, is_long:false } )} 
                            />
                            <label className="btn btn-outline-danger" htmlFor="danger-outlined">Short</label>

                        </div>

                        <div className="form-group">                            
                            <label htmlFor="customRange3" className="form-label">Leverage (x{t.leverage})</label>
                            <input type="range" className="form-range" min="1" max="200" value={t.leverage} 
                            onChange={e => sett({...t, leverage: e.target.value ? parseInt(e.target.value) : 1} )}  step="1" id="customRange3"></input>                            
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Entry Price</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={t.entry_price || ""}  
                                onChange={e => sett({...t, entry_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="150" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Stop Price</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={t.stop_price || ""}  
                                onChange={e => sett({...t, stop_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="100" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Target Price</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={t.target_price || ""}  
                                onChange={e => sett({...t, target_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="200" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Risk</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={t.risk || ""}  
                                onChange={e => sett({...t, risk:parseFloat(e.target.value??0)} )} className="form-control" placeholder="10" />
                            </div>
                        </div>

                 

                    </div>
                    <div className="col-md-6">
                    <h4>Result</h4>
                    <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Quantity</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(t.quantity)}</div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Risk Reward Ratio</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(t.riskreward, 2)}%</div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Initial Margin</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(t.initial_margin)} $</div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>    
        )
}

export default ToolRiskReward;