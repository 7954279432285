import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from './store';
import { Mutex } from 'async-mutex'
import { logout, tokenReceived, askForLogin } from '../features/auth/authSlice';
const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;
function makeApi(rp: string) {
     return createApi({
        reducerPath:`${rp}`,    
        baseQuery: fetchBaseQuery({
            baseUrl: `${BASE_URL}`,        
            credentials:'include',            
            prepareHeaders: async (headers, { getState, endpoint }) => {
                const accessToken = (getState() as RootState).auth.userToken?.accessToken;            
                headers.set("Authorization","Bearer "+accessToken);
                return headers;
            },
        }),  
    endpoints: () => ({}),
    });
}

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: `${BASE_URL}`,        
    credentials:'same-origin',
    mode:'cors',
    prepareHeaders: async (headers, { getState, endpoint }) => {
        const accessToken = (getState() as RootState).auth.userToken?.accessToken;            
        headers.set("Authorization","Bearer "+accessToken);
        return headers;
    },
  });



const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if ((result.error && result.error.status === 401)) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const refreshToken = (api.getState() as RootState).auth.userToken?.refreshToken;            
      
      try {

        if(refreshToken !== undefined) {
          const refreshResult = await baseQuery(
            '/api/Auth/RefreshToken?refreshToken='+refreshToken,
            api,
            extraOptions
          )
          if (refreshResult.data) {
            api.dispatch(tokenReceived(refreshResult.data))
            console.warn("call token replace");
            // retry the initial query
            result = await baseQuery(args, api, extraOptions)
          } else {
            api.dispatch(logout())
            console.warn("call logout");
          }
        }
        else {
          api.dispatch(askForLogin(1))
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }


    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}







export const authApiBase = createApi({
    reducerPath:"authApi",    
    baseQuery: baseQueryWithReauth,  
  endpoints: () => ({})
});

export const channelApiBase = createApi({
    reducerPath:"channelApi",    
    baseQuery: baseQueryWithReauth,
  endpoints: () => ({})
});

export const coinApiBase = createApi({
    reducerPath:"coinApi",    
    baseQuery: baseQueryWithReauth,  
  endpoints: () => ({})
});


export const datatickerApiBase = createApi({
    reducerPath:"datatickerApi",    
    baseQuery:baseQueryWithReauth,  
  endpoints: () => ({})
});

export const exchangeApiBase = createApi({
    reducerPath:"exchangeApi",    
    baseQuery: baseQueryWithReauth,  
  endpoints: () => ({})
});

export const tagApiBase = createApi({
    reducerPath:"tagApi",    
    baseQuery: baseQueryWithReauth,  
  endpoints: () => ({})
});

export const telegramApiBase = createApi({
  reducerPath:"telegramApi",    
  baseQuery: baseQueryWithReauth,  
endpoints: () => ({})
});


export const greetApiBase = createApi({
    reducerPath:"greetApi",    
    baseQuery: baseQueryWithReauth,  
  endpoints: () => ({})
});


export const positionApiBase = createApi({
    reducerPath:"positionApi",    
    baseQuery: baseQueryWithReauth,  
  endpoints: () => ({})
});