
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";

const ConfirmBox = ({ title, message, onConfirm, btnName, btnVariant } : 
  { title:string, message:string, onConfirm: () => void, 
  btnName?:string | undefined, btnVariant?:string | undefined }) => {
    
  const handleConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{title}</h1>
            <p>{message}</p>
            <ButtonGroup>
              <button className="btn btn-sm btn-danger" onClick={onClose}>
                No
              </button>
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              >
                Yes, Confirm!
              </button>
            </ButtonGroup>
          </div>
        );
      },
    });
  };

  return (
    <Button variant={btnVariant || "danger"} size="sm" onClick={handleConfirm}>
      {btnName || "Remove"}
    </Button>
  );
};

export default ConfirmBox;