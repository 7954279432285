import React from 'react';

interface CurrencyViewProps {
  value: number;
}

function CurrencyView({ value }: CurrencyViewProps) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // maximumFractionDigits:2,
    // notation:'compact'
  });
  return <>{formatter.format(value)}</>;
}

export default CurrencyView;

// Higher-Order Component
function WithDangerStyle(WrappedComponent: React.ComponentType<CurrencyViewProps>) {
  return function ({ value, ...rest }: CurrencyViewProps) {
    if (value < 0) {
      return <span className="standings-table__ani  standings-table__losses"><WrappedComponent value={value} {...rest} /></span>;
    }
    return <span className="standings-table__ani  standings-table__wins"><WrappedComponent value={value} {...rest} /></span>;
  };
}

// Usage
// Example usage in your application
// <CurrencyViewWithDangerStyle value={-100} />
export const CurrencyViewWithColorStyle = WithDangerStyle(CurrencyView);



// Higher-Order Component
function WithLpStyle(WrappedComponent: React.ComponentType<CurrencyViewProps>) {
  return function ({ value, ...rest }: CurrencyViewProps) {
    
    return <WrappedComponent value={value} {...rest} />;
  };
}


export const CurrencyViewWithLpStyle = WithLpStyle(CurrencyView);