import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAppSelector } from '../../../store';
import openPopup from '../../../components/openPopup';
import { usePostApiTelegramGenerateVerificationCodeMutation } from '../../../api/telegramApi';



const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;


interface TelegramAuthButtonProps {
    show :boolean;
    handleClose: () => void;
    appChannelId: number,
    media:number
}

const TelegramAuthButton = ({chan, media}:{chan:number, media:number}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    return(
        <div>
            <Button variant="primary" size="sm" onClick={() => setShow(true) }>
            <i className="fa-regular fa-circle-check"></i>
                Verify with Telegram
            </Button>
            <TelegramAuthModal media={media} appChannelId={chan} handleClose={handleClose} show={show} />
        </div>        
    )

}

const TelegramAuthModal: React.FC<TelegramAuthButtonProps> = ({ appChannelId, show, handleClose,media }) => {
    const [channelId, setChannelId] = useState('');
    const token = useAppSelector( p => p.auth);
    const [generatedCode, setGeneratedCode] = useState('');
    const [verificationStatus, setVerificationStatus] = useState('');


    const [generate , { isLoading: g_loading }] = usePostApiTelegramGenerateVerificationCodeMutation();
    //const [verify, { isLoading: v_loading }] = usePostApiTelegramVerifyMutation();

    const handleGenerateCode = async () => {
        try {
            const response = await generate({ verificationRequest: { appChannelId: appChannelId, channelId: media.toString(), verificationCode:''}});
            if('data' in response) {
                var resp = response.data as any;
                setGeneratedCode(resp.data.verificationCode!);
                setVerificationStatus('Verification code generated. It may take some time to verify, please check later.');
            }
            else {
                console.error('Error generating verification code: unknown', );
                setVerificationStatus('Error generating verification code.');
            }
        } catch (error) {
            console.error('Error generating verification code:', error);
            setVerificationStatus('Error generating verification code.');
        }
    };

    /*const handleVerify = async () => {
        try {
            const response = await verify({ verificationRequest: {  appChannelId: appChannelId, channelId: channelId, verificationCode:''}});
            if( 'data' in response) {
                setVerificationStatus(response.data);
            }
        } catch (error) {
            console.error('Error verifying channel:', error);
            setVerificationStatus('Verification failed.');
        }
    };*/

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Verify Channel/Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                   <p>
                    Please generate verification code and add to your Telegram channel/group info.
                   </p>
                    {generatedCode && (
                        <Form.Group>
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control 
                                type="text" 
                                style={{'fontWeight':'bold', 'fontSize':'16px'}}
                                value={generatedCode} 
                                readOnly 
                            />
                        </Form.Group>
                    )}
                    {verificationStatus && <p>{verificationStatus}</p>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleClose}>
                    Close
                </Button>
                {!generatedCode && (
                    <Button variant="primary" onClick={handleGenerateCode} size="sm">
                        Generate Verification Code
                    </Button>
                )}
                
            </Modal.Footer>
        </Modal>
    );
};

export default TelegramAuthButton;