import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CurrencyDetail, CurrencyInfo, ExchangeInfoDetail, useGetApiGreetGetCurrenciesQuery, useGetApiGreetGetExchangeQuery } from "../api/greetApi";
import SelectFx from "../components/SelectFx/SelectFx";
import PriceDisplay from "../components/PriceDisplay";
import CurrencyView from "../components/CurrencyView";
import { debounce } from 'lodash';
import { useFetch } from "usehooks-ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import useBodyClassnames from "../components/useBodyClassnames";
const ExchangePage = () => {
    const { exchange } = useParams();
    const [exdata, setExdata] = useState<ExchangeInfoDetail | null>(null);

	const { data, isLoading, isError, isSuccess, error } = useGetApiGreetGetExchangeQuery({ id: parseInt(exchange|| "0") },{
		refetchOnMountOrArgChange:true
	});
    
    useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);

    useEffect( ()=> {

        if(isSuccess) {
            const edata = data?.data;
            if(edata!=null) {
                setExdata(edata as ExchangeInfoDetail);
            }
        }

    },[ isSuccess, isError])

	const navigate = useNavigate();

    return (<main className="site-content team-info-page" id="wrapper">
    <div className="container container--large">
        <div className="row">
            <div className="col-lg-7">
                <div className="team-carousel">
                    <div className="team-carousel__content">
                        <div className="team-carousel__item" data-icon="team-overview">
                            <div className="row">
                                <div className="col-lg-11">
                                    <h3 className="player-info-subtitle h4 text-uppercase">
                                        <a className="px-3" onClick={() => navigate(-1) }>
											<i className="fa-solid fa-circle-left"></i>
										</a>
                                        { exdata?.name }</h3>
                                    <h2 className="player-info-title h1 text-uppercase">{ exdata?.name }</h2>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-xl-4">
                                            <div className="player-info-detail">
                                                <div className="player-info-detail__label">Country</div>
                                                <div className="player-info-detail__title">{ exdata?.country }</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-xl-4">
                                            <div className="player-info-detail">
                                                <div className="player-info-detail__label">Type</div>
                                                <div className="player-info-detail__title">{ exdata?.exType}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-xl-4">
                                            <div className="player-info-detail">
                                                <div className="player-info-detail__label text-center">Score</div>
                                                {
                                                    (exdata?.score || 0) > 7 &&  <div className="player-info-detail__title color-primary text-center">{ exdata?.score}</div>
                                                }
                                                {
                                                    (exdata?.score || 0) <= 7 && (exdata?.score || 0) > 4  &&  <div className="player-info-detail__title color-warning text-center">{ exdata?.score}</div>
                                                }
                                                {
                                                    (exdata?.score || 0) <= 4  &&  <div className="player-info-detail__title color-danger text-center">{ exdata?.score}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-4 col-md-4 col-xl-4">
                                            <div className="player-info-detail">
                                                <div className="player-info-detail__label">Watching Pairs</div>
                                                <div className="player-info-detail__title">{ exdata?.exchangePairs?.length}</div>
                                            </div>
                                        </div>
                                    <div className="col-4 col-md-4 col-xl-4">
											<div className="player-info-detail">
												<div className="player-info-detail__label">Links</div>
												<ul className="social-menu social-menu--default text-small">
                                                    {
                                                        exdata && exdata.homepage &&
                                                        <li>
                                                            <a href={exdata?.homepage} target="_blank">Visit Exchange</a>
                                                        </li>
                                                    }
													{
                                                        exdata && exdata.links && exdata?.links.map( (m:any)=> <li><a href={m.url} title={m.lType}  target="_blank">{' '} {m.name}</a></li>)
													}
													
												</ul>
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                
                      
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-5  d-flex align-items-center justify-content-center">
                <img src={exdata?.icon || ''}  alt={exdata?.name|| ''}/>
            </div>
        </div>
    </div>
    
</main>)

}

export default ExchangePage;