import React, { useRef, useImperativeHandle, ForwardedRef } from 'react';
import Select, { ActionMeta, OptionProps, PropsValue, SingleValue, SingleValueProps, Props as Prps, GroupBase } from 'react-select';

export interface OptionType {
  label: string;
  value: string;
  imageSrc: string;
}

const CustomOption: React.FC<OptionProps<OptionType, false>> = (props) => {
  const { imageSrc, value } = props.data as OptionType;

  const handleClick = () => {
    props.selectOption(props.data);
  };

  return (
    <div style={{ height: '32px' }} onClick={handleClick}>
      {imageSrc && imageSrc !='' && <img src={imageSrc} alt={props.label} style={{ width: '30px', marginRight: '10px' }} /> }
      {props.label} ({value})
    </div>
  );
};

const CustomSingleValue: React.FC<SingleValueProps<OptionType, false>> = (props) => {
  const { imageSrc, value, label } = props.data as OptionType;

  return (
    <div>
      {imageSrc && imageSrc !='' && <img src={imageSrc} alt={label} style={{ width: '30px', marginRight: '10px' }} /> }
      {label} ({value})
    </div>
  );
};

export interface NiceDropdownProps {
  options: OptionType[];
  disabled: boolean;
  onChange?: (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => void;
  value?: PropsValue<OptionType>;
}

const NiceDropdown = React.forwardRef((props: NiceDropdownProps, ref: ForwardedRef<any>) => {
  const { value, options, disabled, onChange } = props;
  
  return (
    <Select<OptionType, false, GroupBase<OptionType>>
      options={options}
      isDisabled={disabled}
      
      value={value}
      onChange={onChange}
      className="react-select-container"
      classNamePrefix="react-select"
      components={{
        Option: CustomOption,
        //SingleValue: CustomSingleValue,
      }}
    />
  );
});

export default NiceDropdown;
