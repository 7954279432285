import React from 'react';
const useAnimationFrame = (callback:any) => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef<number>();
    const previousTimeRef = React.useRef();
    const cb = React.useRef(callback);
    cb.current = callback;

    const animate = (time:any) => {
      if (previousTimeRef.current != undefined) {
        const deltaTime = time - previousTimeRef.current;
        cb.current(deltaTime)
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    }
    
    React.useEffect(() => {
      requestRef.current = requestAnimationFrame(animate);
      return () => {
        if(requestRef.current != undefined)
            cancelAnimationFrame(requestRef.current);
      }
    }, []); // Make sure the effect runs only once
  };

  export default useAnimationFrame;