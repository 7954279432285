import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

const Decors = ({children} : {children?: ReactNode}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 

    const th = isTabletOrMobile ? '300px' : '90%';

    return(<figure className="page-thumbnail page-thumbnail--default">
    {!children && 
        <img className="page-bg-logo" src="/assets/img/samples/page-bg-logo.png" alt="" />
    }
    {children && <div className="page-bg-logo" style={{ 'height': th, 'overflowY':'auto', 'paddingTop':'30px', 'color':'white', 'width':'90%', 'textShadow':'1px 1px 1px black'}}>{children}</div> }
    <div className="ncr-page-decor"><div className="ncr-page-decor__layer-1">
        <div className="ncr-page-decor__layer-bg"></div></div><div className="ncr-page-decor__layer-2"></div>
        <div className="ncr-page-decor__layer-3"><div className="ncr-page-decor__layer-bg"></div>        
    </div>    
    <div className="ncr-page-decor__layer-4"></div>
    <div className="ncr-page-decor__layer-5"></div>
    <div className="ncr-page-decor__layer-6"></div></div>
    </figure>)

};

export default Decors;