import { useEffect, useState } from "react";
import DebounceInput from "../../components/DebounceInput";
import useBodyClassnames from "../../components/useBodyClassnames";
import { Channel, ChannelModel, Linked, useLazyGetApiChannelGetChannelByIdQuery, useLazyGetApiChannelGetChannelsMineQuery, usePutApiChannelUpdateChannelPorpUpdatechannelpropMutation } from "../../api/channelApi";
import { Button, ButtonGroup } from "react-bootstrap";
import ChannelCreateModal from "./trader.channes.page.create";
import ActiveSign from "../../components/active_sign";
import PageHeading from "../../components/pageHeading/PageHeading";
import TimeAgo from 'react-timeago'
import TraderChannelPageInviteModal, { ChannelInviteModalProps } from './trader.channels.page.invite';
import TraderChannelMembers from "./trader.channel.member";
import TraderChannelAvatarUpload from "./trader.channel.avatarupload";
import Avatar from 'react-avatar';
import ChannelEditModal from "./trader.channes.page.edit";
import useWindowTitle from "../../hooks/useWindowTitle";
import Guider from "../../hooks/useGuidBox";
import React from "react";
import { Link } from "react-router-dom";
import { verify } from "crypto";
import { toast } from "react-toastify";
import TwitterAuthButton from "./socials/TwitterAuthButton";
import TelegramAuthButton from "./socials/TelegramAuthButton";
import { useMediaQuery } from "react-responsive";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;
const TraderChannelsPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 
    const [ getMyChannels, { data: myChannels,  isLoading : getMyChannelsLoading }] = useLazyGetApiChannelGetChannelsMineQuery();    
    const [ getChannel, { data: chan_data }] = useLazyGetApiChannelGetChannelByIdQuery();
    const [ updatePart ]  = usePutApiChannelUpdateChannelPorpUpdatechannelpropMutation();
    const [ expandData, setExpandData] = useState<Channel | undefined>();
    useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);
    useEffect( () => {
        getMyChannels();
    },[]);

    useEffect(() =>  console.log(myChannels),[myChannels])
    const [searchTerm, setSearchTerm] = useState("");

    const [inviteP, setInviteP]= useState<ChannelInviteModalProps>({
        channelId:0,
        inviteType:'fromOwner',
        inviting:'trader',
        show:false,
        onHide:() => {
           setInviteP({ ...inviteP, show:false });
        }
    })

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [editChan, showEditChan] = useState({
        id: 0,
        show:false
    });
  
    
    const [isAvatarModalOpen, setIsAvatarModalOpen] = useState({chanId:0, open:false});


    const handleShowCreateModal = () => setShowCreateModal(true); 
    const [ mType, setMType] = useState<"requested"|"invited"|"user"|"trader"|null>(null);
    const showChannelMembers = (chanid:number, t:"requested"|"invited"|"user"|"trader") => {
        setMType(t);
    }

    const handleEditChan = () => 
    {
        showEditChan({ id:0, show: false});
        getMyChannels();
    }
    
    const handleHideCreateModal = () => {
        setShowCreateModal(false);
        getMyChannels();
    };
    const [expandedRow, setExpandedRow] = useState<number|null>(null);

    const toggleRowExpansion = (rowIndex:number) => {
        if (rowIndex === expandedRow) {
          setExpandedRow(null); // Close the expanded row if it's already open
        } else {
          setExpandedRow(rowIndex); // Expand the clicked row
        }
      };

    const FragmantedTD = ( a : ChannelModel, index: number) => {
        return( <React.Fragment>
                {!isTabletOrMobile && <React.Fragment><td><TimeAgo date={a.createdAt!}  /></td>
                <td><ActiveSign value={a.active} /></td></React.Fragment>}
            <td className="p-0 p-sm-2 p-md-2 p-lg-2 p-xl-2 text-xs-end">      
                <ButtonGroup className="btn-short text-xs-end">                                         
                <Button size="sm" variant={expandedRow === index ? "link" : "link"}
                    onClick={async () =>  { 
                        if(a.id && a.id > 0 ) { 
                            setMType(null);
                            const resp = await getChannel({ id: a.id });
                            setExpandData(resp.data?.data);                                    
                            toggleRowExpansion(index); 
                        }
                    } }>
                    <i title="Channel details" className="fa-regular fa-square-caret-down"></i>              
                </Button>   
            <Button disabled={!!!a.active}  size="sm" variant="link" onClick={ e => setIsAvatarModalOpen({ chanId: a.id!, open:true}) } title="Upload Avatar">
                <i className="fa-regular fa-image"></i>
            </Button>
            <Button disabled={!!!a.active}  size="sm" variant="link" onClick={e => showEditChan({ id: a.id!, show: true})}>
                <i className="fa-solid fa-pen-to-square"></i>
            </Button>
            <Button disabled={!!!a.active}  size="sm" variant="link" title="Invite Trader" onClick={e => { 
                setInviteP({...inviteP, show:true, channelId: a.id!, inviteType: 'fromOwner', inviting: 'trader'}); }}>
                <i className="fa-solid fa-user-astronaut"></i>
            </Button>
            <Button disabled={!!!a.active}  size="sm" variant="link" title="Invite Member" onClick={e => {          
                setInviteP({...inviteP, show:true, channelId: a.id!, inviting:'user', inviteType: 'fromOwner'}); }}>
                <i className="fa-solid fa-circle-user"></i>
            </Button>
            </ButtonGroup> 
            </td></React.Fragment>)
    }

    return (<> <main className="site-content site-content--center page" id="wrapper">
    <div className="container container--large">
        <PageHeading title="Trader" subTitle="Channels" smallSubTitle={true}  />
        <div className="row">
            <div className="col-md-6 text-start">
                {false && <div className="form-group" style={{'background':'#13151e'}}>
                    <label className="control-label" htmlFor="input-default">Search</label>							
                    <DebounceInput onChange={e =>  setSearchTerm(e)} placeHolder="search"  value={searchTerm} bounce={200} key={'a'} />
                </div>}
            </div>
            <div className="col-md-6 text-end">
                   
                    <Button onClick={handleShowCreateModal} size="sm">
                    <i className="fa-regular fa-square-plus"></i> Create Channel</Button>
                    <ChannelCreateModal show={showCreateModal} onHide={handleHideCreateModal} />
            </div>
        </div>

        <ChannelEditModal  chanId={editChan.id} show={editChan.show} onHide={handleEditChan} />
        <TraderChannelAvatarUpload show={isAvatarModalOpen.open} chanId={isAvatarModalOpen.chanId} onClose={() => setIsAvatarModalOpen({ chanId:0, open:false } )}
        />
        <TraderChannelPageInviteModal 
            inviting={inviteP?.inviting} 
            onHide={inviteP.onHide} 
            channelId={inviteP.channelId} 
            inviteType={inviteP.inviteType} show={inviteP.show}  />                     

        <div className="table-responsive" style={{'flexGrow':1}}>
			<table className="table matches-table standings-table w-100 h-100">
				<thead>
					<tr>						
                        <th>Name</th>
                        <th>Traders</th>
						<th>Members</th>
						<th>Type</th>
						<th>Owner</th>           
                        <th>D/W/M Postions</th>             
                        { !isTabletOrMobile && 
                        <React.Fragment>
                        <th>Create Date</th>
                        <th>Active</th></React.Fragment>
                        }
					</tr>
				</thead>
				<tbody>
                    { getMyChannelsLoading && <tr><td colSpan={7}>Loading please wait...</td></tr> }
                    { myChannels && myChannels?.data?.length == 0  &&  <tr><td colSpan={8}>
                    <div className="pt-5 pb-4 text-center color-warning">											
											<svg role="img" style={{fill:'#ffcc00'}} height={25} className="df-icon df-icon--bag">
												<use xlinkHref="/assets/img/necromancers.svg#comments-small"></use></svg>
												<div className="pt-2">No channel yet</div>
										</div>    
                    </td></tr>
                    }
                    { myChannels?.data?.length! > 0 &&  myChannels?.data!.map( (a, index) => {
                        return(
                            <React.Fragment key={a.id!}>
                                <tr key={`sum-${a.id!}`}  className={a.active! == true ? '':'text-muted'}>
                                    <td style={{'width':'30%'}} className="text-center text-md-start">
                                        <Link to={'/channel/'+a.id!} >
                                            <Avatar name={a.name!}
                                            src={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${a.id}?entity=Channel`}
                                            size="48" round={true} />
                                            {a.name}
                                        </Link>
                                </td>
                            <td className="text-center">{a.traderCount}</td>
                            <td className="text-center">{a.memberCount}</td>
                            <td className="text-center">{a.channelType === 0 ? "Public" : "Private"}</td>
                            <td><Link to={`/account/${a.ownerId!}`}><Guider guid={a.ownerId!} /></Link></td>
                            <td>{a.dailyMinPosition}/{a.weeklyMinPosition}/{a.monthlyMinPosition}</td>
                            
                            { !isTabletOrMobile && FragmantedTD(a, index) }
                        </tr>
                        { isTabletOrMobile && <tr><td colSpan={6} className="p-0 p-sm-0 p-md-3 p-lg-4 p-xl-5"><table style={{'width':'100%'}}><tr>{FragmantedTD(a, index)}</tr></table></td></tr>}
                        {expandedRow != null && expandedRow === index && (
                            <tr key={`det-${a.id!}`}>
                                <td colSpan={9}>
                                    <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-xs-12">
                                        <div className="match-stats-widget match-stats-widget--general">
                                            <h5 className="px-3 text-white">Media</h5>
                                            <ul className="match-stats-widget__body">
                                                {
                                                    expandData?.linkedList!.map( (v, i) => {
                                                        return(<li className="align-items-baseline">
                                                            <div className="d-flex lh24">
                                                            {v.verified==true ? 
                                                                    <span className="badge  bg-success text-white ">Verified</span> : 
                                                                    <span className="badge  bg-danger text-white " >Unverified</span>}
                                                                <span className="mx-2" ><i className={'px-2 fa-brands fa-'+v.lType?.toLowerCase()}></i>{v.lType}</span>                                                                
                                                                <span className="text-white">{v.url}</span>
                                                                {

                                                                    <span className="mx-2">
                                                                    {(v.lType == "twitter" || v.lType=="Twitter") && !!!v.verified && <TwitterAuthButton media={v.id!} chan={a.id!} />}
                                                                    {(v.lType == "telegram" || v.lType=="Telegram") && !!!v.verified && <TelegramAuthButton media={v.id!} chan={a.id!} />}
                                                                    </span>

                                                                }
                                                            </div>
                                                            </li>)
                                                    })
                                                }
                                            {expandData && 
                                                <li className="align-items-baseline">
                                                    <span className="d-flex">
                                                        <span className="mx-2 text-nowrap"><i className="px-2 fa-solid fa-bars"></i> Description</span>                                                       
                                                        <span className="text-white" style={{'textAlign':'left'}}>
                                                            {expandData?.description}
                                                        </span>
                                                    </span>
                                                    </li>
                                                }
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12">
                                        <div className="match-stats-widget match-stats-widget--general h-100">
                                            <h5 className="px-3 text-white">Membership</h5>
                                            <ButtonGroup>
                                                <Button size="sm" variant="secondary" className={mType=='trader' ? 'active': ''} onClick={()=>showChannelMembers(a.id!, 'trader')} > Traders</Button>
                                                <Button size="sm" variant="secondary" className={mType=='user' ? 'active': ''} onClick={()=>showChannelMembers(a.id!, 'user')}>Members</Button>

                                                <Button size="sm" variant="secondary" className={mType=='invited' ? 'active': ''} onClick={()=>showChannelMembers(a.id!, 'invited')} > Invited</Button>
                                                <Button size="sm" variant="secondary" className={mType=='requested' ? 'active': ''} onClick={()=>showChannelMembers(a.id!, 'requested')}>Requested</Button>
                                            </ButtonGroup>
                                                
                                                        <TraderChannelMembers channelId={a.id!} memberType={mType} />
                                                
                                        </div>

                                        </div>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                        </React.Fragment>)
                    })
                    }
					

				</tbody>
			</table>
		</div>




    </div>
    </main>
        </>)

}

export default TraderChannelsPage;