import { authApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiAuthForgotPassword: build.mutation<
      PostApiAuthForgotPasswordResp,
      PostApiAuthForgotPasswordReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/ForgotPassword`,
        method: "POST",
        body: queryArg.forgotPassword,
      }),
    }),
    postApiAuthResetPassword: build.mutation<
      PostApiAuthResetPasswordResp,
      PostApiAuthResetPasswordReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/ResetPassword`,
        method: "POST",
        body: queryArg.resetPassword,
      }),
    }),
    postApiAuthPing: build.mutation<PostApiAuthPingResp, PostApiAuthPingReq>({
      query: () => ({ url: `/api/Auth/Ping`, method: "POST" }),
    }),
    postApiAuthCreateToken: build.mutation<
      PostApiAuthCreateTokenResp,
      PostApiAuthCreateTokenReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/CreateToken`,
        method: "POST",
        body: queryArg.signIn,
      }),
    }),
    postApiAuthConfirmEmail: build.mutation<
      PostApiAuthConfirmEmailResp,
      PostApiAuthConfirmEmailReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/ConfirmEmail`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postApiAuthConfirmEmailValidate: build.mutation<
      PostApiAuthConfirmEmailValidateResp,
      PostApiAuthConfirmEmailValidateReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/ConfirmEmailValidate`,
        method: "POST",
        body: queryArg.signInEmailConfirmation,
      }),
    }),
    postApiAuthDeleteUser: build.mutation<
      PostApiAuthDeleteUserResp,
      PostApiAuthDeleteUserReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/DeleteUser`,
        method: "POST",
        body: queryArg.signIn,
      }),
    }),
    postApiAuthLoginWithWeb3: build.mutation<
      PostApiAuthLoginWithWeb3Resp,
      PostApiAuthLoginWithWeb3Req
    >({
      query: (queryArg) => ({
        url: `/api/Auth/LoginWithWeb3`,
        method: "POST",
        body: queryArg.signInEther,
      }),
    }),
    postApiAuthUpdateProfile: build.mutation<
      PostApiAuthUpdateProfileResp,
      PostApiAuthUpdateProfileReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/UpdateProfile`,
        method: "POST",
        params: { displayName: queryArg.displayName },
      }),
    }),
    postApiAuthCreateTokenByClient: build.mutation<
      PostApiAuthCreateTokenByClientResp,
      PostApiAuthCreateTokenByClientReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/CreateTokenByClient`,
        method: "POST",
        body: queryArg.clientSignIn,
      }),
    }),
    postApiAuthRevokeRefreshToken: build.mutation<
      PostApiAuthRevokeRefreshTokenResp,
      PostApiAuthRevokeRefreshTokenReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/RevokeRefreshToken`,
        method: "POST",
        params: { refreshToken: queryArg.refreshToken },
      }),
    }),
    getApiAuthRefreshToken: build.query<
      GetApiAuthRefreshTokenResp,
      GetApiAuthRefreshTokenReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/RefreshToken`,
        params: { refreshToken: queryArg.refreshToken },
      }),
    }),
    postApiAuthRegisterUser: build.mutation<
      PostApiAuthRegisterUserResp,
      PostApiAuthRegisterUserReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/RegisterUser`,
        method: "POST",
        body: queryArg.userLoginModel,
      }),
    }),
    postApiAuthUploadAvatarAvatar: build.mutation<
      PostApiAuthUploadAvatarAvatarResp,
      PostApiAuthUploadAvatarAvatarReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/UploadAvatar/avatar`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiAuthUserProfile: build.query<
      GetApiAuthUserProfileResp,
      GetApiAuthUserProfileReq
    >({
      query: (queryArg) => ({
        url: `/api/Auth/UserProfile`,
        params: { userid: queryArg.userid },
      }),
    }),
    getApiTwitterAuthenticate: build.query<
      GetApiTwitterAuthenticateResp,
      GetApiTwitterAuthenticateReq
    >({
      query: (queryArg) => ({
        url: `/api/Twitter/authenticate`,
        params: {
          marker: queryArg.marker,
          chan: queryArg.chan,
          media: queryArg.media,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as authApi };
export type PostApiAuthForgotPasswordResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiAuthForgotPasswordReq = {
  forgotPassword: ForgotPassword;
};
export type PostApiAuthResetPasswordResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiAuthResetPasswordReq = {
  resetPassword: ResetPassword;
};
export type PostApiAuthPingResp = /** status 200 Success */ MessageQrRead;
export type PostApiAuthPingReq = void;
export type PostApiAuthCreateTokenResp = /** status 200 Success */ TokenQrRead;
export type PostApiAuthCreateTokenReq = {
  signIn: SignIn;
};
export type PostApiAuthConfirmEmailResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiAuthConfirmEmailReq = {
  body: string;
};
export type PostApiAuthConfirmEmailValidateResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiAuthConfirmEmailValidateReq = {
  signInEmailConfirmation: SignInEmailConfirmation;
};
export type PostApiAuthDeleteUserResp = /** status 200 Success */ MessageQrRead;
export type PostApiAuthDeleteUserReq = {
  signIn: SignIn;
};
export type PostApiAuthLoginWithWeb3Resp =
  /** status 200 Success */ TokenQrRead;
export type PostApiAuthLoginWithWeb3Req = {
  signInEther: SignInEther;
};
export type PostApiAuthUpdateProfileResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiAuthUpdateProfileReq = {
  displayName?: string;
};
export type PostApiAuthCreateTokenByClientResp =
  /** status 200 Success */ ClientTokenQrRead;
export type PostApiAuthCreateTokenByClientReq = {
  clientSignIn: ClientSignIn;
};
export type PostApiAuthRevokeRefreshTokenResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiAuthRevokeRefreshTokenReq = {
  refreshToken?: string;
};
export type GetApiAuthRefreshTokenResp = /** status 200 Success */ TokenQrRead;
export type GetApiAuthRefreshTokenReq = {
  refreshToken?: string;
};
export type PostApiAuthRegisterUserResp = /** status 200 Success */ TokenQrRead;
export type PostApiAuthRegisterUserReq = {
  userLoginModel: UserLoginModel;
};
export type PostApiAuthUploadAvatarAvatarResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiAuthUploadAvatarAvatarReq = {
  body: {
    avatar?: Blob;
  };
};
export type GetApiAuthUserProfileResp = /** status 200 Success */ ProfileQrRead;
export type GetApiAuthUserProfileReq = {
  userid?: string;
};
export type GetApiTwitterAuthenticateResp = unknown;
export type GetApiTwitterAuthenticateReq = {
  marker?: string;
  chan?: number;
  media?: number;
};
export type Message = {};
export type MessageRead = {
  resultMessage?: string | null;
  resultAction?: string | null;
};
export type MessageQr = {
  data?: Message;
};
export type MessageQrRead = {
  data?: MessageRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ForgotPassword = {
  email?: string | null;
};
export type ResetPassword = {
  email: string;
  password: string;
  confirmPassword?: string | null;
  refcode?: string | null;
  token?: string | null;
  code?: string | null;
};
export type Token = {
  id?: string | null;
  displayName?: string | null;
  email?: string | null;
  userName?: string | null;
  web3?: string | null;
  accessToken?: string | null;
  accessTokenExpiration?: string;
  refreshToken?: string | null;
  refreshTokenExpiration?: string;
};
export type TokenRead = {
  resultMessage?: string | null;
  resultAction?: string | null;
  id?: string | null;
  displayName?: string | null;
  email?: string | null;
  userName?: string | null;
  web3?: string | null;
  accessToken?: string | null;
  accessTokenExpiration?: string;
  refreshToken?: string | null;
  refreshTokenExpiration?: string;
};
export type TokenQr = {
  data?: Token;
};
export type TokenQrRead = {
  data?: TokenRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type SignIn = {
  email?: string | null;
  password?: string | null;
  token?: string | null;
};
export type SignInEmailConfirmation = {
  userId?: string | null;
  code?: string | null;
};
export type SignInEther = {
  signature?: string | null;
  ethereumAddress?: string | null;
};
export type ClientToken = {
  accessToken?: string | null;
  expiration?: string;
};
export type ClientTokenQr = {
  data?: ClientToken;
};
export type ClientTokenQrRead = {
  data?: ClientToken;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ClientSignIn = {
  clientId?: string | null;
  clientSecret?: string | null;
};
export type UserLoginModel = {
  email: string;
  password: string;
  confirmPassword?: string | null;
  refcode?: string | null;
  token?: string | null;
};
export type ProfileStatRecord = {};
export type ProfileStatRecordRead = {
  completed?: boolean;
  count?: number;
  sumPnl?: number;
  maxPnl?: number;
  minPnl?: number;
};
export type Profile = {
  id?: string | null;
  email?: string | null;
  summary?: ProfileStatRecord[] | null;
};
export type ChannelBasic = {
  id?: number;
  name?: string | null;
  chanType?: string | null;
};
export type ProfileRead = {
  id?: string | null;
  email?: string | null;
  displayName?: string | null;
  userName?: string | null;
  ethereumWallet?: string | null;
  registerAt?: string | null;
  summary?: ProfileStatRecordRead[] | null;
  owned_chan?: ChannelBasic[] | null;
  prove_chan?: ChannelBasic[] | null;
  subss_chan?: ChannelBasic[] | null;
  activeOrders?: number;
};
export type ProfileQr = {
  data?: Profile;
};
export type ProfileQrRead = {
  data?: ProfileRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export const {
  usePostApiAuthForgotPasswordMutation,
  usePostApiAuthResetPasswordMutation,
  usePostApiAuthPingMutation,
  usePostApiAuthCreateTokenMutation,
  usePostApiAuthConfirmEmailMutation,
  usePostApiAuthConfirmEmailValidateMutation,
  usePostApiAuthDeleteUserMutation,
  usePostApiAuthLoginWithWeb3Mutation,
  usePostApiAuthUpdateProfileMutation,
  usePostApiAuthCreateTokenByClientMutation,
  usePostApiAuthRevokeRefreshTokenMutation,
  useGetApiAuthRefreshTokenQuery,
  useLazyGetApiAuthRefreshTokenQuery,
  usePostApiAuthRegisterUserMutation,
  usePostApiAuthUploadAvatarAvatarMutation,
  useGetApiAuthUserProfileQuery,
  useLazyGetApiAuthUserProfileQuery,
  useGetApiTwitterAuthenticateQuery,
  useLazyGetApiTwitterAuthenticateQuery,
} = injectedRtkApi;
