import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';


interface ITraderState {
    
    newOrderCreated:boolean;
    newPositionCreated:boolean; 


}

const initialState: ITraderState = {
    newOrderCreated: false,
    newPositionCreated: false,
};


const traderSlice = createSlice({
    initialState,
    name: 'trader',  
    reducers: {

        orderCreated: (state) => {
            state.newOrderCreated = true;
        },
        resetOrderCreated: (state) => {
            state.newOrderCreated = false;
        },
        positionCreated: (state) => {
            state.newPositionCreated = true;
        },
        resetPositionCreated: (state) => {
            state.newPositionCreated = true;
        },

    }

});





  
export const { actions: traderActions } = traderSlice;  
export default traderSlice.reducer;