import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useLazyGetApiPositionGetPositionsLatestPriceQuery, PositionLate } from '../../api/positionApi';
import { useAppSelector } from '../../store';

export interface LatestPrices {
  [key: string]: number;
}

interface PriceContextProps {
  children: ReactNode;
}
interface PositionLateDic {
  [key:string]:PositionLate
}
console.log("CONTEXT CREATED: PriceContext");
export const PriceContext = createContext< PositionLateDic>({});

export const PriceProvider: React.FC<PriceContextProps> = ({ children }) => {

  const userAccessTokenExpiration = useAppSelector(p=>p.auth?.userToken?.accessTokenExpiration)

  const [latestPrices, setLatestPrices] = useState<PositionLateDic>({});
  const [fetchNow, { data: fetchData }] = useLazyGetApiPositionGetPositionsLatestPriceQuery();

  const fetchLatestPrices = async () => {

    if(userAccessTokenExpiration) {
      try {
        await fetchNow();  
      } catch (error) {
        console.error('Error fetching latest prices:', error);
      }
    }
  };

  useEffect( ()=> {
    if(fetchData?.data!=null)
      setLatestPrices(fetchData?.data);
  },[fetchData])

  useEffect(() => {
    fetchLatestPrices();
    const intervalId = setInterval(fetchLatestPrices, 3000); // Update every 3 seconds
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return <PriceContext.Provider value={latestPrices}>{children}</PriceContext.Provider>;
};

export const useLatestPrices = ():  PositionLateDic => {
  
  const context = useContext(PriceContext);  
  if (context === undefined) {
    throw new Error('useLatestPrices must be used within a PriceProvider');
  }
  return context;
};