import { useEffect, useState } from 'react';
import './App.scss';

import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import { RouterProvider } from "react-router-dom";
import useRandomInterval from './app/hooks/useRandomInterval';
import { useDarkMode } from 'usehooks-ts';
import Preloader from './app/components/Preloader';
import { useGetApiGreetGetMarketQuery } from './app/api/greetApi';
import { AppRouter } from './app/AppRouter';
import OneSignal from 'react-onesignal';
import { useTranslation } from 'react-i18next';
import useLocalizeDocumentAttributes from './i18n/useLocalizeDocumentAttributes';


const App = () =>  {
  const { t } = useTranslation();
  useLocalizeDocumentAttributes();
  const { isDarkMode, toggle, enable, disable } = useDarkMode();
  
  const { data, status, error, refetch } = useGetApiGreetGetMarketQuery({most: 10}, {
    pollingInterval:15000,
    refetchOnMountOrArgChange:true
  });
  const [initialized, setInitialized] = useState(false);

  useEffect(() => { 
    
    if(!initialized) {    
      
      console.warn("onesignal- initing.");
      OneSignal.init({ 
        appId: "35f884af-d3ba-4057-9835-601a0ff3d1a8",
        allowLocalhostAsSecureOrigin:true,
        safari_web_id: "web.onesignal.auto.054b4fc9-febb-45d1-b366-8fc183da0d9e",
        notifyButton: {
          enable: false,
        },
      }).then(() => {        
          OneSignal.Slidedown.promptPush().finally( () => {
            setInitialized(true);  
            console.warn("onesignal- user prompted");
          });
        // do other stuff
      })
    }

  },[]);



	useEffect( ()=> {
		const body = document.querySelector("body");		
		if(body!=null) {				
				body.classList.remove("preloader-is--active")
				setTimeout( function () {
					body.classList.add('scroll-is--active');
				}, 1300);
		}
		return () => { }
	},[]);

  return (<>    
      <AddToHomeScreen 
      cookie={{name:'pwa-coinsynthesis', expireDays:7 }} 
      delayNotify={300} 
      
      styles={{
        body: {
          backgroundColor:'rgb(33, 37, 41)',
          color:'white',
          border:'1px solid #a3ff12'
        },
        button:{
          backgroundColor:'#a3ff12'
        }
      }}      
      />
      <RouterProvider router={AppRouter} fallbackElement={<p>Loading...</p>} />        
      <Preloader />      
	</>);
}

export default App;
