import { useEffect, useState } from "react";
import Decors from "../partials/Decors";

interface PNLOpt {
    leverage : number;
    entry_price: number |null;
    target_price: number;    
    roe:number |null;
    is_long:boolean;
}
const ToolTargetPrice = () => {

    const [t, sett] = useState<PNLOpt>({
            entry_price:null,
            is_long:true,
            leverage:1,
            target_price:0,
            roe:null,                    
    });


    const goodNum = (n:number) =>  {

       return new Intl.NumberFormat("en-US", {
            signDisplay: "exceptZero",
            minimumFractionDigits: 2,
            maximumFractionDigits: 10
        }).format(n);

    }

    useEffect(()=> {

        const cpt = {...t};


        if(t.entry_price != null && t.roe != null) {
            if(cpt.is_long) {
                cpt.target_price  = t.entry_price  + ((t.entry_price / 100.0)  * (t.roe / t.leverage))
            }else {
                cpt.target_price  = t.entry_price   -  ((t.entry_price / 100.0)  * (t.roe / t.leverage))
            }
        }

        sett(cpt);
       
        
    },[t.entry_price, t.roe, t.leverage, t.is_long]);


    return (
        <main className="site-content" id="wrapper">			
			<div className="site-content__inner">
            <div className="site-content__holder">
            <Decors>
                
The "target price" is a price level at which an investor or trader aims to sell an asset to realize a profit. It's a pre-determined price objective set by an individual based on their analysis, trading strategy, or investment goals.
            </Decors>
                <div className="page-content">
                <h4 className="mb-5">Targert Price</h4>
                <div className="row ">
                    <div className="col-md-6">


                        <div className="form-group">

                            <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" defaultChecked 
                             onChange={e => sett({...t, is_long:true } )} 
                            />
                            <label className="btn btn-outline-success" htmlFor="success-outlined">Long</label>

                            <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" 
                            onChange={e => sett({...t, is_long:false } )} 
                            />
                            <label className="btn btn-outline-danger" htmlFor="danger-outlined">Short</label>

                        </div>

                        <div className="form-group">                            
                            <label htmlFor="customRange3" className="form-label">Leverage (x{t.leverage})</label>
                            <input type="range" className="form-range" min="1" max="200" value={t.leverage} 
                            onChange={e => sett({...t, leverage: e.target.value ? parseInt(e.target.value) : 1} )}  step="1" id="customRange3"></input>                            
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Entry Price</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={t.entry_price || ""}  
                                onChange={e => sett({...t, entry_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="1.5" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">ROE</label>
                            <div className="input-group mb-3">
                            <span className="input-group-text">%</span>
                                <input type="number"   value={t.roe|| ""}  onChange={e => sett({...t, roe:parseFloat(e.target.value??0)} )} className="form-control" placeholder="100" />
                            </div>
                        </div>
                                            

                    </div>
                    <div className="col-md-6">
                    <h4>Result</h4>
                    <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Target Price</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(t.target_price)} $</div>
                        </div>
       

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>    
        )
}

export default ToolTargetPrice;