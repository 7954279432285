
import Slider from "react-slick";
import SelectFx from "../SelectFx/SelectFx";
import { Link, NavLink } from "react-router-dom";
import NavLinkC from "../NavLinkC";
import { MobileMenu, MobileSubMenu } from "./MobileMenu";
import { useAppDispatch, useAppSelector } from "../../store";
import  { askForLogin, logout } from '../../../features/auth/authSlice';
import { PnLBoard, useLazyGetApiGreetGetPnlQuery } from "../../api/greetApi";
import { useEffect, useState } from "react";
import PnlBox from "../PnlBox/PnlBox";

const MenuPanel: React.FC = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(p => p.auth.user);
    

    const team = [

        { nick:'Nico' , img:'/assets/img/team/Nico_I.png'},
        { nick:'Coco' , img:'/assets/img/team/Coco_F.png'},
        { nick:'Ken' , img:'/assets/img/team/Ken_J.png'},
        { nick:'Lola' , img:'/assets/img/team/Lola_S.png'},
        { nick:'Sasha' , img:'/assets/img/team/Sasha_R.png'},
        { nick:'Tobi' , img:'/assets/img/team/Tobi_N.png'},
        { nick:'Leni' , img:'/assets/img/team/Leni_G.png'},
        { nick:'Rafa' , img:'/assets/img/team/Rafa_S.png'},
        { nick:'Dima' , img:'/assets/img/team/Dima_R.png'},
        { nick:'Remy' , img:'/assets/img/team/Remy_F.png'},
        { nick:'Anu' , img:'/assets/img/team/Anu_I.png'},        
        { nick:'Titan' , img:'/assets/img/team/Titan_U.png'},        
    ];


    const team_style = {

        width:'36px',
        margin:'2px',
        padding: '4px',
        cursor:'pointer',
        

    }

    const [kKe, setKke] = useState<number>(1);

    const [ getPnl, { isLoading : pnlLoading, data: pnlData }] = useLazyGetApiGreetGetPnlQuery({ });
    const [ getPnlW, { isLoading : pnlLoadingW, data: pnlDataW }] = useLazyGetApiGreetGetPnlQuery({ });

    useEffect( () => {
        getPnl({ key : kKe, dir:true });
        getPnlW({ key :kKe+3,  });
    },[kKe]);


    return(<div className="menu-panel">
    <ul className="menu-panel__mobile-bar list-unstyled d-md-none">
    <li className="mobile-bar-item">
        <NavLink className="mobile-bar-item__header collapsed" to={'./'}>
          Home
        </NavLink>
      </li>

      <MobileMenu title="Cryptocurrencies">
        <MobileSubMenu to={'./currencies/spot'}>Ranking</MobileSubMenu>
        <MobileSubMenu to={'./currencies/currenciesgainerlooser'}>Gainers & Losers</MobileSubMenu>
        <MobileSubMenu to={'./currencies/changes'}>Live Changes</MobileSubMenu>
      </MobileMenu>

      <MobileMenu title="Exchanges">
        <MobileSubMenu to={'./exchanges/cryptoexchanges'}>Crypto Exchanges</MobileSubMenu>
        <MobileSubMenu to={'./exchanges/dexexchanges'}>Decentralized Exchanges</MobileSubMenu>        
      </MobileMenu>

      <MobileMenu title="Tools">
        <MobileSubMenu to={'./tools/poster'}>Poster</MobileSubMenu>
        <MobileSubMenu to={'./tools/pnl'}>PNL</MobileSubMenu>        
        <MobileSubMenu to={'./tools/target-price'}>Target Price</MobileSubMenu>        
        <MobileSubMenu to={'./tools/liquidation-price'}>Liquidation Price</MobileSubMenu>        
        <MobileSubMenu to={'./tools/max-open'}>Max Open</MobileSubMenu>        
        <MobileSubMenu to={'./tools/open-price'}>Open Price</MobileSubMenu>        
        <MobileSubMenu to={'./tools/risk-reward'}>Risk Reward</MobileSubMenu>        
      </MobileMenu>


      <MobileMenu title="Learn">
        <MobileSubMenu to={'./learn/guides'}>Guides</MobileSubMenu>
        <MobileSubMenu to={'./learn/glossary'}>Glossary</MobileSubMenu>        
      </MobileMenu>

      <MobileMenu title="Trader">  
        <MobileSubMenu to={'./trader'}>Trade Now</MobileSubMenu>     
        <MobileSubMenu to={'./trader/best'}>Hall of Fame</MobileSubMenu>   
        <MobileSubMenu to={'./trader/channels'}>My Channels</MobileSubMenu>
      </MobileMenu>


        {false && <li className="mobile-bar-item">
            <a className="mobile-bar-item__header collapsed" data-toggle="collapse" href="#mobile_collapse_2" role="button" aria-expanded="false" aria-controls="mobile_collapse_2">
                Social Links
                <span className="main-nav__toggle">&nbsp;</span>
            </a>
            <div id="mobile_collapse_2" className="collapse mobile-bar-item__body">
                <ul className="social-menu social-menu--mobile-bar">
                    <li><a href="https://www.facebook.com/danfisher.dev/"><span>Facebook</span></a></li>
                    <li><a href="https://twitter.com/danfisher_dev"><span>Twitter</span></a></li>
                    <li><a href="https://twitch.tv"><span>Twitch</span></a></li>
                    <li><a href="https://discordapp.com"><span>Discord</span></a></li>
                </ul>
            </div>
        </li>}
        
        
        {user!=null && 
        <>
        <li className="mobile-bar-item">
            <Link className="mobile-bar-item__header" to={'/account'}>
                Account Settings
            </Link>
        </li>        
        <li className="mobile-bar-item">
            <a className="mobile-bar-item__header" onClick={() => {  dispatch(logout()); console.warn("logged out"); }}>
                Logout
            </a>
        </li> </>}
        {user == null && 
        <>
        <li className="mobile-bar-item">
            <NavLinkC className="mobile-bar-item__header"  to={'./login'}>
                Register
            </NavLinkC>
        </li> 
        <li className="mobile-bar-item">
            <NavLinkC className="mobile-bar-item__header"  to={'./login'}>
                Login
            </NavLinkC>
        </li> 
        </>        
        }            
    </ul>
    <div className="menu-panel__top-bar">
        <div className="top-bar-filter">
            <label className="top-bar-filter__label">Best / Worst Trades</label>
            <SelectFx placeHolder="Choose Date Range" 
            selected={'1'} 
            onChange={v => { if(v && v!='')  setKke(parseInt(v)) } }
            options={[{ value:'0', text:'Today'},{ value:'1', text:'Lasy 7 Days'},{ value:'2', text:'Last 30 Days'}]} />        
        </div>        
        <ul className="top-bar-carousel slick-initialized slick-slider" >
        <button className="slick-prev slick-arrow"></button>            
    <div className="slick-list draggable">
            <div className="slick-track" style={{opacity: '1', width: '90000px'}}>
                <li className="top-bar-carousel__headline x2018 slick-slide">
                    <span>Best Trades</span>
                </li>
                {
                    pnlData && ((pnlData as any).data as PnLBoard[] ).map( (a,b)=> <PnlBox key={a.id!} data={a}/>  )
                }         
                <li className="top-bar-carousel__headline xwleague slick-slide">
                    <span>Worst Trades</span>
                </li>
                {
                    pnlDataW && ((pnlDataW as any).data as PnLBoard[] ).map( (a,b)=> <PnlBox key={a.id!} data={a} />  )
                }                    
            </div>
        </div>
            <button className="slick-next slick-arrow"></button>
        </ul>
    </div>
    <div className="menu-panel__content d-none d-md-flex">
        <div className="menu-panel__navigation">
            <div id="dl-menu" className="dl-menuwrapper center">          
                <div style={{textAlign:'center'}}><img src="/assets/img/logo@2x.png" alt="" /></div>            
            </div>
        </div>
        <div className="menu-panel__widget-area">
            <div className="row">
                <div className="col-md-12 col-lg-6 col-xl-5">                    
                    <section className="widget widget-text">
                        <h5 className="widget__title">Join our team</h5>
                        <div className="widget__content">
                            <span>
                                We’re always looking for new talent to join our teams. If you wanna join us, just send us and email and we’ll get back to you!
                            </span>
                            <div className="info-box">
                                <div className="info-box__label">The Super - Recruiter</div>
                                <div className="info-box__content"><a href="mailto:super@coinsynthesis.com">super<span className="color-primary">@</span>coinsynthesis.com</a></div>
                            </div>

                            <div><Link to="/terms-and-conditions">Terms & Conditions</Link></div>
                            <div> <Link to="/privacy-policy">Privacy Policy</Link></div>
                        </div>
                    </section>    
                   
                </div>
                <div className="col-md-12 col-lg-6 col-xl-5 offset-xl-2 mt-5 mt-lg-0">                    
                    <section className="widget widget-contact-info">
                        <h5 className="widget__title">Contact Info</h5>
                        <div className="widget__content">
                            <p>
                                If you have any questions, please send us and email. We will respond as soon as possible!
                            </p>
                            <div className="info-box">
                                <div className="info-box__label">General inquiries</div>
                                <div className="info-box__content"><a href="mailto:info@coinsynthesis.com">info<span className="color-primary">@</span>coinsynthesis.com</a></div>
                            </div>
                            <div className="info-box">
                                <div className="info-box__label">Be our partner!</div>
                                <div className="info-box__content"><a href="mailto:partner@coinsynthesis">partner<span className="color-primary">@</span>coinsynthesis.com</a></div>
                            </div>
                        </div>
                    </section>                    
                </div>
            </div>

<div className="row">
    <div className="col-md-12">

    <section className="widget widget-text">
                        <h5 className="widget__title">OUR Team</h5>
                        <div className="widget__content">{
                            team.map( (m) => {

                                return (<span 
                                    style={{ 'textAlign':'center', 'padding':'6px', 'border':'1px solid black', 'width':'130px', 'display':'inline-block'}} 
                                    title={m.nick}>
                                        <span style={{'backgroundColor':'#85da00', 'backgroundImage':'linear-gradient(#b6e900, #55cc00)', 'width':'75%'}} />
                                        <b className="text-white">{m.nick}</b><img style={team_style} src={m.img} alt={m.nick} />
                                    </span>)

                            })
                        }</div>
                    </section>                

    </div>
</div>

{ false &&            <div className="row">
                <div className="col-md-12">                    
                   <section className="widget widget-partners-carousel">
                        <h5 className="widget__title">Trusted</h5>
                        <div className="widget__content">
                            <ul className="slick-initialized slick-slider">

                            <div className="slick-list draggable">
                                <div className="slick-track" style={{opacity: '1', width: '4290px'}}>

                                <li className="slick-slide slick-cloned">
                                    <img src="assets/img/samples/partner-carousel-img-01.png" alt="" />
                                </li>
                                <li className="slick-slide slick-cloned">
                                    <img src="assets/img/samples/partner-carousel-img-02.png" alt="" />
                                </li>
                                <li className="slick-slide slick-cloned">
                                    <img src="assets/img/samples/partner-carousel-img-03.png" alt=""/>
                                </li>
                                <li className="slick-slide slick-cloned">
                                    <img src="assets/img/samples/partner-carousel-img-01.png" alt=""/>
                                </li>
                                <li className="slick-slide slick-cloned">
                                    <img src="assets/img/samples/partner-carousel-img-02.png" alt=""/>
                                </li>
                                <li className="slick-slide slick-cloned">
                                    <img src="assets/img/samples/partner-carousel-img-03.png" alt=""/>
                                </li>
                                </div>
                                </div>
                            </ul>
                        </div>
                    </section>                   
                </div>
            </div> }
        </div>
    </div>
</div>)

}

export default MenuPanel;

/*
*/