import React, { useState, useRef, useEffect } from 'react';


const openPopup = (url: string, title: string, w: number, h: number): Window | null => {
    const dualScreenLeft = window.screenLeft ;
    const dualScreenTop = window.screenTop ;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ;
    const height = window.innerHeight ? window.innerHeight :  document.documentElement.clientHeight ;

    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;

    const newWindow = window.open(url, title, `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`);

    if (newWindow && newWindow.focus) {
        newWindow.focus();
    }

    return newWindow;
};

export default openPopup;