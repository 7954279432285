import { ReactNode, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Position, PositionEx, useLazyGetApiPositionGetPositionsOfQuery } from "../../api/positionApi";
import { TraderPageOrderRow, MemoTraderPageOrderRow } from "./TraderPageOrderRow";
import { useAppDispatch, useAppSelector } from "../../store";
import { CP } from "./trader.page.positions";
import { traderActions } from "../../../features/trader/traderSlice";
import { useMediaQuery } from "react-responsive";

const TraderPageOrders : React.FC<CP> = (props) => {
	const userAccessTokenExpiration = useAppSelector(p=>p.auth?.userToken?.accessTokenExpiration);
	const [ getPosOf , { data: getPosOfData, error: getPosOfError }] = useLazyGetApiPositionGetPositionsOfQuery();
	const [refresh ,setRefresh] = useState<number>(0);

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 
	
	const traderState = useAppSelector(p => p.tader);
	const dispatch = useAppDispatch();
    
	useEffect(()=> {	

		if(traderState.newOrderCreated === true) {
			dispatch(traderActions.resetOrderCreated());
			getPosOf({ active: true, typ: 'Order'});
		}

	},[traderState.newOrderCreated,refresh]);
	

	useEffect( ()=> {
		if(userAccessTokenExpiration)
			getPosOf({ active: true, typ: 'Order'});
	},[refresh])

    return (<>    
		<div className="table-responsive-sm">
        	<Table className="table matches-table table-hover table-dark table-insite">
							<thead>
							{!isTabletOrMobile  && 
								<tr>
									<th title="Contract name will always be displayed when you're scrolling.">Contracts</th>
									<th>Exchange</th>
																																													
									<th className="text-center" title="A positive value of quantity indicates a long position, while a negative value indicates a short position">Qty</th>
									<th className="text-center" title="Notional value of the current position">Value</th>
									<th className="text-center" title="The weighted average entry price of your positions">Order Price</th>	
									<th className="text-center">Order Date</th>								
									<th className="text-center">Take Profit</th>
									<th className="text-center">Stoploss</th>
									<th className="text-center">Order No</th>
									<th>									
									</th>
								</tr>}
							</thead>
							<tbody>
							{getPosOfData && getPosOfData.data  && getPosOfData.data.length >0 && 
								getPosOfData.data!.map((a,b)=><MemoTraderPageOrderRow key={a.id!} gotoPair={props.gotoPair} pos={a} pig={() => setRefresh(refresh + 1 )} />)		
							 }								
								{ !(getPosOfData && getPosOfData.data  && getPosOfData.data.length >0 ) && 							
								<tr>
									<td colSpan={12}>
										<div className="pt-5 pb-4 text-center color-warning">											
											<svg role="img" style={{fill:'#ffcc00'}} height={25} className="df-icon df-icon--bag">
												<use xlinkHref="/assets/img/necromancers.svg#comments-small"></use></svg>
												<div className="pt-2">No record yet</div>
										</div>
									</td>
								</tr> }
							</tbody>
			</Table>		
		</div>
    </>)
}

export default TraderPageOrders;


