import React, { useState } from 'react';
import FilerobotImageEditor, {TABS, TOOLS} from 'react-filerobot-image-editor';


const PlasterEditor = () => {

    const [isImageEditorShown, setIsImageEditorShown] = useState(true);

    const openImgEditor = () => setIsImageEditorShown(true);

    const closeImgEditor = () => setIsImageEditorShown(false);

    return (  <div style={{'width':'100%', 'height':'75vh', 'backgroundColor':'orange'}}>
        {false && <button onClick={openImgEditor}>Open Filerobot image editor</button> }
        {isImageEditorShown && (
        <FilerobotImageEditor 
                source="/assets/img/stephen-walker-unsplash.jpg"
                onBeforeSave={ (im:any) => { console.log('info', im); return false; }}                
                onSave={(editedImageObject: any, designState: any) => console.log('saved', editedImageObject, designState)}
                onClose={closeImgEditor}                
                annotationsCommon={{
                    fill: '#ff0000',
                }} 
                theme={{
                    palette: {
                        'bg-primary':'black',
                        'bg-primary-active':'#131313',
                        'bg-secondary':'#141414',
                        'txt-primary':'white',
                        'txt-secondary':'white',
                        'txt-secondary-invert':'black'
                    },
                    typography: {
                        fontFamily: 'Rajdhani, Arial',
                      },
                }}
                Text={{ 
                    fontFamily:'Rajdhani',
                    text: 'Write...',
                    fonts: [
                        { label: 'Arial', value: 'Arial' },
                        'Tahoma',
                        'Sans-serif',
                        { label: 'Comic Sans', value: 'Comic-sans' },
                        { label: 'Rajdhani', value:'Rajdhani' }
                      ]
                }}
                Rotate={{ angle: 90, componentType: 'slider' }}
                Crop={{
                    presetsItems: [
                        {
                            titleKey: 'classicTv',
                            descriptionKey: '4:3',
                            ratio: 4 / 3,
                            // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                        },
                        {
                            titleKey: 'cinemascope',
                            descriptionKey: '21:9',
                            ratio: 21 / 9,
                            // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                        },
                    ],
                    presetsFolders: [
                        {
                            titleKey: 'socialMedia',

                            // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                            groups: [
                                {
                                    titleKey: 'facebook',
                                    items: [
                                        {
                                            titleKey: 'profile',
                                            width: 180,
                                            height: 180,
                                            descriptionKey: 'fbProfileSize',
                                        },
                                        {
                                            titleKey: 'coverPhoto',
                                            width: 820,
                                            height: 312,
                                            descriptionKey: 'fbCoverPhotoSize',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                }}
                
                tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK, TABS.FILTERS, TABS.FINETUNE, TABS.RESIZE]} // or {['Adjust', 'Annotate', 'Watermark']}
                defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                defaultToolId={TOOLS.TEXT} // or 'Text'
                savingPixelRatio={0} 
                previewPixelRatio={0} />
      )}
        </div>)


}


export default PlasterEditor;