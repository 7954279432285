import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePostApiAuthConfirmEmailValidateMutation } from '../../api/authApi';
import { useEffect } from 'react';

const ConfirmEmailAcceptPage : React.FC = () => {
    
    const [searchParams] = useSearchParams();
	
    const userId = searchParams.get("userId");
    const code  = searchParams.get("code");
    
	const navigate = useNavigate();

    const [check, {isLoading, isSuccess, isError }] = usePostApiAuthConfirmEmailValidateMutation()

    useEffect(()=>{
        if(isSuccess==true){
            navigate('/login');
        }   
        else {
            
        }

    },[isSuccess]);


    useEffect(()=>{

        if(userId != undefined && code != undefined ){
            check({ signInEmailConfirmation : { code : code, userId : userId}});
        }

    },[]);

    return (
    
        <main className="site-content login-page" id="wrapper">
            <Row>             
                <Col md={6} className="d-none d-sm-block" style={{'justifyContent':'center'}}>
                    <div style={{ 'textAlign': 'center'}}><img onClick={e => console.log('suicide')} src="/assets/img/logo@2x.png" alt="" /></div>
                </Col>
                <Col md={6} style={{'justifyContent':'center'}}>
                    { isSuccess && 
                        <h2 className="h4">Confirmation Completed. Please login now.</h2>
                    }
                    {
                        isError && 
                        <h2 className="h4">Confirmation Failed! Please try again later.</h2>
                    }
                    { isLoading &&
                        <h2 className='h4'>Please wait...</h2>
                    }
                    {
                        (userId == undefined || code == undefined ) &&
                            <h2 className='h4'>Missing Arguments...</h2>
                    }
                </Col>                     
            </Row>        
        </main>
    )

};


export default ConfirmEmailAcceptPage;