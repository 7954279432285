export enum ChannelTypeE {
    Public = 0,
    Private = 1,
  }


export interface LinkedItem {
    lType: LinkedOptions;
    name:string;
    icon:string;
    url: string;
  }

export interface ChannelFormData {
  Name: string;
  Description: string;
  EthereumWallet: string | null;
  DailyMinPosition: number;
  WeeklyMinPosition: number;
  MonthlyMinPosition: number;
  ChannelType: ChannelTypeE;  
  OwnerId : string | null;
  LinkedList: LinkedItem[];
}
export interface ChannelFormDataU extends ChannelFormData {
  id:number;
}
export enum LinkedOptions {
  Telegram = 'Telegram',
  Twitter = 'Twitter',
  /*Instagram = 'Instagram',
  Website = 'Website',  
  Discord = 'Discord',
  Medium = 'Medium',
  Github = 'Github',
  Facebook = 'Facebook',
  Reddit = 'Reddit',
  Youtube = 'Youtube',*/
  
}