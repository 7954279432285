import React from 'react';
import SimplePage from './simple.page';


const TermsAndConditions: React.FC = () => {
  return (
    <SimplePage title='Terms and Conditions'>
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>
      <p>Last Updated: 2024-07-15</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing the App, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use the App.
      </p>
      
      <h2>2. Use of the App</h2>
      <p>
        a. The App allows users to create profiles, upload profile photos, and link their Twitter and Telegram accounts.
      </p>
      <p>
        b. Users can create and join channels where they can publish positions on virtual cryptocurrency trading.
      </p>
      <p>
        c. All trading on the App is conducted with virtual money. No real money is involved.
      </p>
      
      <h2>3. User Responsibilities</h2>
      <p>
        a. Users are fully responsible for all content they upload, create, write, or post on the App.
      </p>
      <p>
        b. Users must not create or post fake information, including but not limited to fake Telegram accounts and fake Twitter accounts.
      </p>
      <p>
        c. Users are prohibited from using the App for any illegal activities.
      </p>
      <p>
        d. Users are solely responsible for any actions they take on the App and any consequences resulting from those actions. This includes any damages, slogans, or bad behaviors.
      </p>
      <p>
        e. Users agree that they are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.
      </p>
      
      <h2>4. Permitted Uses</h2>
      <p>
        By accessing our Service, you represent and warrant that:
      </p>
      <p>
        a. You are not under 13 years of age. If you are under 18 years old, you are using the Service with the approval of your parent or guardian.
      </p>
      <p>
        b. You have full power and authority to enter into these Terms of Service.
      </p>
      <p>
        c. You are not located in, under the control of, or a national or resident of any country subject to sanctions by the United States.
      </p>
      <p>
        d. You have not been placed on the U.S. Department of Commerce's Denied Persons List.
      </p>
      <p>
        e. You are not identified as a Specially Designated National (“SDN”) by the United States government.
      </p>
      <p>
        f. Neither you nor any person or entity with direct or indirect ownership or control over your access to our Service are on any trade or economic sanctions lists, such as the SDN list or other sanctions.
      </p>
      <p>
        g. You will not access the Service if you have previously been prohibited from doing so or if any laws prohibit you from doing so.
      </p>
      
      <h2>5. Disclaimer of Warranties</h2>
      <p>
        a. The App is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the App's functionality or the accuracy of any data provided.
      </p>
      <p>
        b. We are not responsible for any content posted by users or any actions taken by users on the App.
      </p>
      
      <h2>6. Limitation of Liability</h2>
      <p>
        a. To the maximum extent permitted by law, coinsynthesis.com will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the App; (ii) any conduct or content of any third party on the App; (iii) any content obtained from the App; and (iv) unauthorized access, use, or alteration of your transmissions or content.
      </p>
      
      <h2>7. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless coinsynthesis.com, its affiliates, officers, directors, employees, and agents from any claims, damages, obligations, losses, liabilities, costs, debt, or expenses arising from (i) your use of the App; (ii) your violation of these Terms; (iii) your violation of any third-party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your content caused damage to a third party.
      </p>
      
      <h2>8. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of Czech Republic, without regard to its conflict of law provisions.
      </p>
      
      <h2>9. Changes to Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </p>
      
      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at info@coinsynthesis.com.
      </p>
    </div>
    </SimplePage>
  );
};

export default TermsAndConditions;
