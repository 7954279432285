import { channelApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChannelGetChannelById: build.query<
      GetApiChannelGetChannelByIdResp,
      GetApiChannelGetChannelByIdReq
    >({
      query: (queryArg) => ({ url: `/api/Channel/GetChannel/${queryArg.id}` }),
    }),
    getApiChannelGetChannelStatsById: build.query<
      GetApiChannelGetChannelStatsByIdResp,
      GetApiChannelGetChannelStatsByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/GetChannelStats/${queryArg.id}`,
      }),
    }),
    postApiChannelUploadAvatarByIdAvatar: build.mutation<
      PostApiChannelUploadAvatarByIdAvatarResp,
      PostApiChannelUploadAvatarByIdAvatarReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/UploadAvatar/${queryArg.id}/avatar`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiChannelGetChannelsMine: build.query<
      GetApiChannelGetChannelsMineResp,
      GetApiChannelGetChannelsMineReq
    >({
      query: () => ({ url: `/api/Channel/GetChannelsMine` }),
    }),
    getApiChannelGetChannelsProved: build.query<
      GetApiChannelGetChannelsProvedResp,
      GetApiChannelGetChannelsProvedReq
    >({
      query: () => ({ url: `/api/Channel/GetChannelsProved` }),
    }),
    getApiChannelGetChannelsSubed: build.query<
      GetApiChannelGetChannelsSubedResp,
      GetApiChannelGetChannelsSubedReq
    >({
      query: () => ({ url: `/api/Channel/GetChannelsSubed` }),
    }),
    postApiChannelCreateChannelCreate: build.mutation<
      PostApiChannelCreateChannelCreateResp,
      PostApiChannelCreateChannelCreateReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/CreateChannel/create`,
        method: "POST",
        body: queryArg.channelDto,
      }),
    }),
    postApiChannelInvite: build.mutation<
      PostApiChannelInviteResp,
      PostApiChannelInviteReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/Invite`,
        method: "POST",
        body: queryArg.channelInviteModel,
      }),
    }),
    getApiChannelGetInvites: build.query<
      GetApiChannelGetInvitesResp,
      GetApiChannelGetInvitesReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/GetInvites`,
        params: {
          IsOwnerFrom: queryArg.isOwnerFrom,
          asTrader: queryArg.asTrader,
          chan: queryArg.chan,
          rejected: queryArg.rejected,
          accepted: queryArg.accepted,
          expired: queryArg.expired,
        },
      }),
    }),
    postApiChannelGetInvite: build.mutation<
      PostApiChannelGetInviteResp,
      PostApiChannelGetInviteReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/GetInvite`,
        method: "POST",
        params: { inviteCode: queryArg.inviteCode },
      }),
    }),
    postApiChannelInviteResponse: build.mutation<
      PostApiChannelInviteResponseResp,
      PostApiChannelInviteResponseReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/InviteResponse`,
        method: "POST",
        params: {
          inviteCode: queryArg.inviteCode,
          accepted: queryArg.accepted,
          comment: queryArg.comment,
        },
      }),
    }),
    putApiChannelUpdateChannelPorpUpdatechannelprop: build.mutation<
      PutApiChannelUpdateChannelPorpUpdatechannelpropResp,
      PutApiChannelUpdateChannelPorpUpdatechannelpropReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/UpdateChannelPorp/updatechannelprop`,
        method: "PUT",
        body: queryArg.updatePart,
      }),
    }),
    putApiChannelUpdateChannelUpdate: build.mutation<
      PutApiChannelUpdateChannelUpdateResp,
      PutApiChannelUpdateChannelUpdateReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/UpdateChannel/update`,
        method: "PUT",
        body: queryArg.channelDto,
      }),
    }),
    putApiChannelDeactivateChannelByIdDeactivate: build.mutation<
      PutApiChannelDeactivateChannelByIdDeactivateResp,
      PutApiChannelDeactivateChannelByIdDeactivateReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/DeactivateChannel/${queryArg.id}/deactivate`,
        method: "PUT",
      }),
    }),
    postApiChannelAddPositionProviderByIdProvidersAdd: build.mutation<
      PostApiChannelAddPositionProviderByIdProvidersAddResp,
      PostApiChannelAddPositionProviderByIdProvidersAddReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/AddPositionProvider/${queryArg.id}/providers/add`,
        method: "POST",
        body: queryArg.channelPositionProvider,
      }),
    }),
    getApiChannelGetPositionProviderByIdProviders: build.query<
      GetApiChannelGetPositionProviderByIdProvidersResp,
      GetApiChannelGetPositionProviderByIdProvidersReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/GetPositionProvider/${queryArg.id}/providers`,
      }),
    }),
    putApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivate:
      build.mutation<
        PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateResp,
        PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateReq
      >({
        query: (queryArg) => ({
          url: `/api/Channel/DeactivatePositionProvider/${queryArg.id}/providers/${queryArg.providerId}/deactivate`,
          method: "PUT",
        }),
      }),
    putApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdate:
      build.mutation<
        PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateResp,
        PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateReq
      >({
        query: (queryArg) => ({
          url: `/api/Channel/UpdatePositionProvider/${queryArg.id}/providers/${queryArg.providerId}/update`,
          method: "PUT",
          body: queryArg.channelPositionProvider,
        }),
      }),
    postApiChannelAddSubscriberByIdSubscribersAdd: build.mutation<
      PostApiChannelAddSubscriberByIdSubscribersAddResp,
      PostApiChannelAddSubscriberByIdSubscribersAddReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/AddSubscriber/${queryArg.id}/subscribers/add`,
        method: "POST",
        body: queryArg.channelSubscriber,
      }),
    }),
    getApiChannelGetSubscriberByIdSubscribers: build.query<
      GetApiChannelGetSubscriberByIdSubscribersResp,
      GetApiChannelGetSubscriberByIdSubscribersReq
    >({
      query: (queryArg) => ({
        url: `/api/Channel/GetSubscriber/${queryArg.id}/subscribers`,
      }),
    }),
    putApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivate:
      build.mutation<
        PutApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivateResp,
        PutApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivateReq
      >({
        query: (queryArg) => ({
          url: `/api/Channel/DeactivateSubscriber/${queryArg.id}/subscribers/${queryArg.subscriberId}/deactivate`,
          method: "PUT",
        }),
      }),
    postApiGreetFindChannels: build.mutation<
      PostApiGreetFindChannelsResp,
      PostApiGreetFindChannelsReq
    >({
      query: (queryArg) => ({
        url: `/api/Greet/FindChannels`,
        method: "POST",
        body: queryArg.searchBox,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as channelApi };
export type GetApiChannelGetChannelByIdResp =
  /** status 200 Success */ ChannelQrRead;
export type GetApiChannelGetChannelByIdReq = {
  id: number;
};
export type GetApiChannelGetChannelStatsByIdResp =
  /** status 200 Success */ ChanStatListQrRead;
export type GetApiChannelGetChannelStatsByIdReq = {
  id: number;
};
export type PostApiChannelUploadAvatarByIdAvatarResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiChannelUploadAvatarByIdAvatarReq = {
  id: number;
  body: {
    avatar?: Blob;
  };
};
export type GetApiChannelGetChannelsMineResp =
  /** status 200 Success */ ChannelModelListQrRead;
export type GetApiChannelGetChannelsMineReq = void;
export type GetApiChannelGetChannelsProvedResp =
  /** status 200 Success */ ChannelModelListQrRead;
export type GetApiChannelGetChannelsProvedReq = void;
export type GetApiChannelGetChannelsSubedResp =
  /** status 200 Success */ ChannelModelListQrRead;
export type GetApiChannelGetChannelsSubedReq = void;
export type PostApiChannelCreateChannelCreateResp =
  /** status 200 Success */ ChannelQrRead;
export type PostApiChannelCreateChannelCreateReq = {
  channelDto: ChannelDto;
};
export type PostApiChannelInviteResp =
  /** status 200 Success */ ChannelInviteModelQrRead;
export type PostApiChannelInviteReq = {
  channelInviteModel: ChannelInviteModel;
};
export type GetApiChannelGetInvitesResp =
  /** status 200 Success */ ChannelInviteListModelIListQrRead;
export type GetApiChannelGetInvitesReq = {
  isOwnerFrom?: boolean;
  asTrader?: boolean;
  chan?: number;
  rejected?: boolean;
  accepted?: boolean;
  expired?: boolean;
};
export type PostApiChannelGetInviteResp =
  /** status 200 Success */ ChannelInviteViewModelQrRead;
export type PostApiChannelGetInviteReq = {
  inviteCode?: string;
};
export type PostApiChannelInviteResponseResp =
  /** status 200 Success */ MessageQrRead;
export type PostApiChannelInviteResponseReq = {
  inviteCode?: string;
  accepted?: boolean;
  comment?: string;
};
export type PutApiChannelUpdateChannelPorpUpdatechannelpropResp =
  /** status 200 Success */ StringQrRead;
export type PutApiChannelUpdateChannelPorpUpdatechannelpropReq = {
  updatePart: UpdatePart;
};
export type PutApiChannelUpdateChannelUpdateResp =
  /** status 200 Success */ ChannelDtoQrRead;
export type PutApiChannelUpdateChannelUpdateReq = {
  channelDto: ChannelDto;
};
export type PutApiChannelDeactivateChannelByIdDeactivateResp =
  /** status 200 Success */ StringQrRead;
export type PutApiChannelDeactivateChannelByIdDeactivateReq = {
  id: number;
};
export type PostApiChannelAddPositionProviderByIdProvidersAddResp =
  /** status 200 Success */ ChannelPositionProviderQrRead;
export type PostApiChannelAddPositionProviderByIdProvidersAddReq = {
  id: number;
  channelPositionProvider: ChannelPositionProvider;
};
export type GetApiChannelGetPositionProviderByIdProvidersResp =
  /** status 200 Success */ BaseUserIEnumerableQrRead;
export type GetApiChannelGetPositionProviderByIdProvidersReq = {
  id: number;
};
export type PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateResp =
  /** status 200 Success */ StringQrRead;
export type PutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateReq =
  {
    id: number;
    providerId: string;
  };
export type PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateResp =
  /** status 200 Success */ StringQrRead;
export type PutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateReq =
  {
    id: number;
    providerId: number;
    channelPositionProvider: ChannelPositionProvider;
  };
export type PostApiChannelAddSubscriberByIdSubscribersAddResp =
  /** status 200 Success */ ChannelSubscriberRead;
export type PostApiChannelAddSubscriberByIdSubscribersAddReq = {
  id: number;
  channelSubscriber: ChannelSubscriber;
};
export type GetApiChannelGetSubscriberByIdSubscribersResp =
  /** status 200 Success */ BaseUserIEnumerableQrRead;
export type GetApiChannelGetSubscriberByIdSubscribersReq = {
  id: number;
};
export type PutApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivateResp =
  /** status 200 Success */ StringQrRead;
export type PutApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivateReq =
  {
    id: number;
    subscriberId: string;
  };
export type PostApiGreetFindChannelsResp =
  /** status 200 Success */ SearchBoxResultListQrRead;
export type PostApiGreetFindChannelsReq = {
  searchBox: SearchBox;
};
export type UploadBox = {
  id?: number;
  fileName?: string | null;
  binData?: string | null;
  mimeType?: string | null;
};
export type ChannelType = 0 | 1;
export type Linked = {
  id?: number;
  name?: string | null;
  url?: string | null;
  icon?: string | null;
  order?: number;
  lType?: string | null;
  verified?: boolean;
  verifiedAt?: string | null;
  active?: boolean;
  verificationCode?: string | null;
};
export type Channel = {
  id?: number;
  name?: string | null;
  createdAt?: string;
  ownerId?: string | null;
  active?: boolean;
  ethereumWallet?: string | null;
  dailyMinPosition?: number;
  weeklyMinPosition?: number;
  monthlyMinPosition?: number;
  description?: string | null;
  avatar?: UploadBox;
  channelType?: ChannelType;
  linkedList?: Linked[] | null;
};
export type ChannelQr = {
  data?: Channel;
};
export type ChannelQrRead = {
  data?: Channel;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ChanStatListQr = {};
export type ChanStat = {
  completed?: boolean;
  count?: number;
  sumPnl?: number;
  createdAt?: string;
  pnlP?: number;
};
export type ChanStatListQrRead = {
  data?: ChanStat[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type Message = {};
export type MessageRead = {
  resultMessage?: string | null;
  resultAction?: string | null;
};
export type MessageQr = {
  data?: Message;
};
export type MessageQrRead = {
  data?: MessageRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ChannelModelListQr = {};
export type ChannelModel = {
  id?: number;
  name?: string | null;
  createdAt?: string;
  ownerId?: string | null;
  active?: boolean;
  ethereumWallet?: string | null;
  dailyMinPosition?: number;
  weeklyMinPosition?: number;
  monthlyMinPosition?: number;
  description?: string | null;
  avatar?: UploadBox;
  channelType?: ChannelType;
  linkedList?: Linked[] | null;
  memberCount?: number;
  traderCount?: number;
};
export type ChannelModelListQrRead = {
  data?: ChannelModel[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ChannelDto = {
  id?: number;
  name: string;
  description: string;
  createdAt?: string | null;
  ownerId?: string | null;
  active?: boolean;
  ethereumWallet?: string | null;
  dailyMinPosition?: number;
  weeklyMinPosition?: number;
  monthlyMinPosition?: number;
  channelType: ChannelType;
  linkedList?: Linked[] | null;
};
export type ChannelInviteModel = {
  toUserId?: string | null;
  subExpireDaysAfter?: number | null;
  inivteAsTrader?: boolean;
  toChannelId?: number;
};
export type ChannelInviteModelQr = {
  data?: ChannelInviteModel;
};
export type ChannelInviteModelQrRead = {
  data?: ChannelInviteModel;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ChannelInviteListModelIListQr = {};
export type ChannelInviteListModel = {
  id?: number;
  fromUserId?: string | null;
  fromUserName?: string | null;
  toUserId?: string | null;
  toUserName?: string | null;
  acceptedDate?: string | null;
  acceptComment?: string | null;
  invitationCode?: string | null;
  invivationUntil?: string | null;
  toChannelName?: string | null;
  toChannelId?: number;
  inivteAsTrader?: boolean;
  rejected?: boolean;
  isOwnerFrom?: boolean;
};
export type ChannelInviteListModelIListQrRead = {
  data?: ChannelInviteListModel[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ChannelInviteViewModel = {
  fromUser?: string | null;
  toUser?: string | null;
  expireDate?: string;
  inivteAsTrader?: boolean;
  toChannel?: string | null;
};
export type ChannelInviteViewModelRead = {
  fromUser?: string | null;
  toUser?: string | null;
  expireDate?: string;
  inivteAsTrader?: boolean;
  toChannel?: string | null;
  answerDate?: string | null;
  answer?: boolean;
  isOwnerFrom?: boolean;
};
export type ChannelInviteViewModelQr = {
  data?: ChannelInviteViewModel;
};
export type ChannelInviteViewModelQrRead = {
  data?: ChannelInviteViewModelRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type StringQr = {};
export type StringQrRead = {
  data?: string | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type UpdatePart = {
  id?: number;
  key?: string | null;
  value?: string | null;
};
export type ChannelDtoQr = {
  data?: ChannelDto;
};
export type ChannelDtoQrRead = {
  data?: ChannelDto;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ChannelPositionProvider = {
  id?: number;
  channelId?: number;
  positionProviderId?: string | null;
  active?: boolean;
};
export type ChannelPositionProviderRead = {
  id?: number;
  channelId?: number;
  positionProviderId?: string | null;
  active?: boolean;
  registerAt?: string;
};
export type ChannelPositionProviderQr = {
  data?: ChannelPositionProvider;
};
export type ChannelPositionProviderQrRead = {
  data?: ChannelPositionProviderRead;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type BaseUserIEnumerableQr = {};
export type BaseUser = {
  username?: string | null;
  userId?: string | null;
  joinDate?: string;
  expire?: string;
};
export type BaseUserIEnumerableQrRead = {
  data?: BaseUser[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ChannelSubscriber = {
  id?: number;
  channelId?: number;
  subscriberId?: string | null;
  suspend?: boolean;
  expireDate?: string;
  active?: boolean;
};
export type ChannelSubscriberRead = {
  id?: number;
  channelId?: number;
  subscriberId?: string | null;
  suspend?: boolean;
  expireDate?: string;
  active?: boolean;
  registerAt?: string;
};
export type SearchBoxResultListQr = {};
export type SearchBoxResult = {
  ownerId?: string | null;
  date?: string;
  num?: number;
  pnl?: number;
  name?: string | null;
};
export type SearchBoxResultListQrRead = {
  data?: SearchBoxResult[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type SearchBoxOrder = 0 | 1 | 2 | 3;
export type SearchBox = {
  name?: string | null;
  ethWallet?: string | null;
  best?: boolean;
  page?: number;
  sOrder?: SearchBoxOrder;
};
export const {
  useGetApiChannelGetChannelByIdQuery,
  useLazyGetApiChannelGetChannelByIdQuery,
  useGetApiChannelGetChannelStatsByIdQuery,
  useLazyGetApiChannelGetChannelStatsByIdQuery,
  usePostApiChannelUploadAvatarByIdAvatarMutation,
  useGetApiChannelGetChannelsMineQuery,
  useLazyGetApiChannelGetChannelsMineQuery,
  useGetApiChannelGetChannelsProvedQuery,
  useLazyGetApiChannelGetChannelsProvedQuery,
  useGetApiChannelGetChannelsSubedQuery,
  useLazyGetApiChannelGetChannelsSubedQuery,
  usePostApiChannelCreateChannelCreateMutation,
  usePostApiChannelInviteMutation,
  useGetApiChannelGetInvitesQuery,
  useLazyGetApiChannelGetInvitesQuery,
  usePostApiChannelGetInviteMutation,
  usePostApiChannelInviteResponseMutation,
  usePutApiChannelUpdateChannelPorpUpdatechannelpropMutation,
  usePutApiChannelUpdateChannelUpdateMutation,
  usePutApiChannelDeactivateChannelByIdDeactivateMutation,
  usePostApiChannelAddPositionProviderByIdProvidersAddMutation,
  useGetApiChannelGetPositionProviderByIdProvidersQuery,
  useLazyGetApiChannelGetPositionProviderByIdProvidersQuery,
  usePutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateMutation,
  usePutApiChannelUpdatePositionProviderByIdProvidersAndProviderIdUpdateMutation,
  usePostApiChannelAddSubscriberByIdSubscribersAddMutation,
  useGetApiChannelGetSubscriberByIdSubscribersQuery,
  useLazyGetApiChannelGetSubscriberByIdSubscribersQuery,
  usePutApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivateMutation,
  usePostApiGreetFindChannelsMutation,
} = injectedRtkApi;
