import React from 'react';

interface RankingIconProps {
  place: number;
}

const getColor = (place: number): string => {
  switch (place) {
    case 1:
      return '#FFD700'; // Gold
    case 2:
      return '#C0C0C0'; // Silver
    case 3:
      return '#CD7F32'; // Bronze
    default:
      return '#3D3D3D'; // Grey for places 4 and beyond
  }
};
const RankingIcon: React.FC<RankingIconProps> = ({ place }) => {
  const color = getColor(place);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="12" cy="12" r="10" fill={color} stroke="black" strokeOpacity="0.5" />
      <text x="12" y="15" fontFamily="Arial" fontSize="8" textAnchor="middle" fill="black" fontWeight="lighter">
        {place}
      </text>
    </svg>
  );
};
export default RankingIcon;
