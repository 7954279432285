import { createBrowserRouter, Navigate, Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom";
import CryptoExchangesPage from "./pages/cryptoexchangespage";
import CurrenciesPage from "./pages/currencies";
import CurrencyPage from "./pages/currencies.one";
import CurrenciesGainerLooser from "./pages/currenciesgainerlooser";
import DexExchangesPage from "./pages/dexexchangespage";
import ExchangePage from "./pages/exchanges";
import ForgotPasswordPage from "./pages/auth/forgotpassword.page";
import HomePage from "./pages/home.page";
import LandingPage from "./pages/landing.page";
import LoginPage from "./pages/auth/login.page";

import CartPanel from "./components/cart/CartPanel";
import MenuPanel from "./components/menupanel/MenuPanel";
import SearchPanel from "./components/search/SearchPanel";
import HeaderLanding from "./pages/partials/HeaderLanding";
import { useAppDispatch, useAppSelector } from "./store";
import Header from "./pages/partials/Header";
import ToolPNL from "./pages/tools/tools.pnl";
import ToolTargetPrice from "./pages/tools/tools.target_price";
import ToolLiquidationPrice from "./pages/tools/tools.liquidation_price";
import ToolMaxOpen from "./pages/tools/tools.max_open";
import ToolOpenPrice from "./pages/tools/tools.open_price";
import ToolRiskReward from "./pages/tools/tools.risk_reward";
import CurrenciesChangePage from "./pages/currencies.change";
import GlossaryPage from "./pages/learn/glossarypage";
import GuidePage from "./pages/learn/guidepage";
import TraderPage from "./pages/trader/trader.page";
import ToolPoster from "./pages/tools/tools.poster";
import TraderChannelsPage from "./pages/trader/trader.channels.page";
import { ToastContainer } from "react-toastify";
import InvitePage from "./pages/trader/invite.page";
import { useEffect, useState } from "react";
import { PreviousUrlProvider, usePreviousUrl } from "./components/PreviousUrl";
import { logout } from "../features/auth/authSlice";
import ConfirmEmailPage from "./pages/auth/confirmemail.page";
import ConfirmEmailAcceptPage from "./pages/auth/confirmemailaccept.page";
import ForgotPasswordSentPage from "./pages/auth/forgotpasswordsent.page";
import ForgotPasswordAcceptPage from "./pages/auth/forgotpasswordaccept.page";
import ChannelPage from "./pages/trader/channel.page";

import { useAnalytics } from './hooks/useAnalytics';
import ProfilePage from "./pages/auth/profile.page";
import TraderBestsPage from "./pages/trader/trader.best.page";
import TermsAndConditions from "./pages/termsandconditions";
import PrivacyPolicy from "./pages/privacypolicy";
import LocaleSwitcher from "../i18n/LocaleSwitcher";


const Layout = () => {
    const config = useAppSelector( p => p.config);
    useAnalytics() // add it here
   
   
   
    const lwt = "site-layout--default";
    const location = useLocation();
    
    

    return(<>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}      
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
    <div className={`site-wrapper ${location.pathname==="/" ? "" : lwt} ${location.pathname!=="/" &&config.menuShow ? 'site-wrapper--has-menu-overlay' : ''} `}>  
              <div className="text-white position-fixed bg-warning" style={{'width':'100%', 'height':'1px'}}></div>
              <LocaleSwitcher />
              {location.pathname==="/" && <HeaderLanding />}
              {location.pathname!=="/" && <Header />}
              
              <Outlet />
            <div className="site-overlay"></div>
            <CartPanel />
            <SearchPanel />
            <MenuPanel />
          </div>
          </>);
}
const NoRoute = () => {
  return(<main className="site-content text-center" id="wrapper">
      <div className='site-content__center'>
            <h1 className="text-white landing-title">page does not exists</h1>
            <a href="/" className="btn btn-primary"><span>Homepage</span></a>
      </div>
    </main>);
}


const LayoutWithPreviousUrl = () => <PreviousUrlProvider><Layout /></PreviousUrlProvider>


const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());    
  }, []);

  return <p>logging out</p>;
};

export const AppRouter = createBrowserRouter([
    {
      path: "/",
      element: <LayoutWithPreviousUrl />,
      children:[
        {
          index:true,        
          element:<LandingPage />
        },
        {
          path:"login",
          element:<LoginPage/>
        },
        {
          path:"confirmemail",
          element:<ConfirmEmailPage/>
        }, {
          path:"forgotpasswordsent",
          element:<ForgotPasswordSentPage/>
        }, 
        {
          path:"confirmemailaccept",
          element:<ConfirmEmailAcceptPage/>
        },
        {
          path:"forgotpasswordaccept",
          element:<ForgotPasswordAcceptPage/>
        },
        {
          path:"forgotpassword",
          element:<ForgotPasswordPage/>
        },
        {
          path:"logout",     
          element:<Logout/>
          
        },
        {
          path:"register",
          element:<LoginPage/>
        },
        {
          path:"currencies/currenciesgainerlooser",
          element:<CurrenciesGainerLooser/>
        },
        {
          path:"currencies",
          element:<Navigate to='/currencies/spot' replace/>
        },
        {
          path:"currencies/spot",
          element:<CurrenciesPage/>
        }
        ,
        {
          path:"currencies/changes",
          element:<CurrenciesChangePage/>
        }
        ,{
          path:"exchanges/cryptoexchanges",
          element:<CryptoExchangesPage/>
        },
        {
          path:"exchanges",
          element:<Navigate to='/exchanges/cryptoexchanges' replace/>
        },
        {
          path:"exchanges/dexexchanges",
          element:<DexExchangesPage/>
        },
        {
          path:"exchanges/:exchange",
          element:<ExchangePage/>
        },
        {
          path:"account",
          element:<ProfilePage/>
        },
        {
          path:"account/:id",
          element:<ProfilePage/>
        },
        {
          path:"invite/:invitecode",
          element:<InvitePage/>
        },
        {
          path:"currencies/detail/:currency",
          element:<CurrencyPage/>
        },
        {
          path:"learn/glossary",
          element:<GlossaryPage/>
        },
        {
          path:"learn/guides",
          element:<GuidePage/>
        },
        {
          path:"home",
          element:<HomePage/>
        },
        {
        path:"tools/pnl",
        element:<ToolPNL/>
        },
        {
          path:"tools/target-price",
          element:<ToolTargetPrice/>
        },
        {
          path:"tools/max-open",
          element:<ToolMaxOpen/>
        },
        {
          path: "tools/poster",
          element:<ToolPoster />
        },
        {
          path:"tools/liquidation-price",
          element:<ToolLiquidationPrice/>
        },
        {
          path:"tools/open-price",
          element:<ToolOpenPrice/>
        },
        {
          path:"tools/risk-reward",
          element:<ToolRiskReward/>
        },
        {
          path:"channel/:chanId",
          element:<ChannelPage/>
        },
        {
          path:"trader",
          element:<TraderPage/>
        },{
          path:"trader/channels",
          element:<TraderChannelsPage/>
        },
        {
          path:"trader/best",
          element:<TraderBestsPage/>
        },
        {
          path:"trader/invite/:invitecode",
          element:<InvitePage/>
        },
        {
          path:"trader/:exchange",
          element:<TraderPage/>
        },
        {
          path:"terms-and-conditions",
          element: <TermsAndConditions />
        },
        {
          path:"privacy-policy",
          element: <PrivacyPolicy />
        },
        {
          path:"trader/:rexchange/:rpair/:spair",
          element:<TraderPage/>
        },
      ]
    },
    {
      path:"*",
      element:<NoRoute />
    }
  ]);

  