import { useEffect, useState } from "react";
import { BaseUserIEnumerableQrRead, useLazyGetApiChannelGetPositionProviderByIdProvidersQuery,
     useLazyGetApiChannelGetSubscriberByIdSubscribersQuery, 
     usePutApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivateMutation,
     usePutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateMutation, 
     useLazyGetApiChannelGetInvitesQuery,
     ChannelInviteListModelIListQrRead,
     usePostApiChannelInviteResponseMutation} from "../../api/channelApi";
import { Button, ButtonGroup } from "react-bootstrap";
import ConfirmBox from "../../components/ConfirmBox";
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // 
import { datatickerApi } from "../../api/datatickerApi";
import { useNavigate } from "react-router-dom";
interface ITraderChannelMembers {
    channelId:number;
    memberType: "requested"| "invited" | "trader" | "user" | null
}
const TraderChannelMembers = ({ channelId, memberType}: ITraderChannelMembers) => {

    const [ subTrigger, { data: subData, isLoading: subL } ] = useLazyGetApiChannelGetSubscriberByIdSubscribersQuery();
    const [ traTrigger, { data: traData, isLoading: traL } ] = useLazyGetApiChannelGetPositionProviderByIdProvidersQuery();

    const [remoProv, { isLoading: remoProvL, isSuccess: remoProvS  } ] = usePutApiChannelDeactivatePositionProviderByIdProvidersAndProviderIdDeactivateMutation();
    const [remoMemb, { isLoading: remoMembL, isSuccess: remoMembS  } ] = usePutApiChannelDeactivateSubscriberByIdSubscribersAndSubscriberIdDeactivateMutation();

    const [fInviteResponse, {isLoading : fInviteResponseL, isSuccess: fInviteResponseS }] = usePostApiChannelInviteResponseMutation();

    const [ invites, { data:invData,  isLoading: invL} ] = useLazyGetApiChannelGetInvitesQuery();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    
    useEffect(() => {

        if(memberType == "user") {
            subTrigger({ id : channelId});
        }
        else if ( memberType == "trader") {
            
            traTrigger({ id: channelId}); 
        }
        else if(memberType == "invited") {
            invites({ expired:false,  chan : channelId, isOwnerFrom: true });
        }
        else if(memberType == "requested") {
            invites({expired:false, chan : channelId, isOwnerFrom: false });
        }

    },[channelId, memberType]);


    const removeIt = (userid:string, issuer: boolean) => {

        if(issuer)
        {
            remoMemb({ id: channelId!, subscriberId : userid });
        }
        else {
            remoProv({ id: channelId!, providerId : userid });
        }
    }

    const removeItI = (code:string, userid:string, b: boolean) => {
        fInviteResponse({
            accepted:false,
            inviteCode:code, 
            comment:"Cancelled by Owner",
        })     
    }

    const acceptItI = (code:string, userid:string, b: boolean) => {
        fInviteResponse({
            accepted:true,
            inviteCode:code, 
            comment:"Accepted by Owner",
        })     
    }

    function showDataI(dat: ChannelInviteListModelIListQrRead) {

        if(dat && dat.data && dat.data.length > 0) {
            return (<ul className="match-stats-widget__body">
                {
                    dat.data.map( (a,b)=> {
                        return (<li key={a.id}>
                            <span className="text-success">
                                {a.isOwnerFrom == false && <a href="" onClick={e => navigate("/account/"+a.fromUserId!)}>{a.fromUserId}</a>}
                                {a.isOwnerFrom == true && <a  href="" onClick={e => navigate("/account/"+a.toUserId!)}>{a.toUserId}</a>}
                            </span>
                            <span>
                                Until: {a.invivationUntil}
                            </span>
                            <span>
                                {a.inivteAsTrader == true ? "Trader" : "Member"}
                            </span>
                            <span>
                            {a.acceptedDate !==null && <span><Button size="sm" onClick={e => console.log("nope") } variant="disabled">{ a.rejected==true ? "Rejected" : "Accepted" }</Button></span> }  
                            {a.acceptedDate ==null &&  a.isOwnerFrom==false && <ConfirmBox btnName="Cancel" message="Removing user from area" title="Are you sure?" onConfirm={() => removeItI(a.invitationCode!, a.fromUserId!, a.isOwnerFrom!)} /> }
                            {a.acceptedDate ==null &&  a.isOwnerFrom==false && <ConfirmBox btnVariant="success" btnName="Accept" message="Accepting user from area" title="Are you sure?" onConfirm={() => acceptItI(a.invitationCode!, a.fromUserId!, a.isOwnerFrom!)} /> }
                            {a.acceptedDate ==null && a.isOwnerFrom==true && <ConfirmBox btnName="Cancel" message="Removing user from area" title="Are you sure?" onConfirm={() => removeItI(a.invitationCode!, a.toUserId!, a.isOwnerFrom!)} /> }                                
                            </span>
                        </li>)
                    })
                }
            </ul>)
        }
        else return(<h5 className="danger text-danger">There is no user found at this time!</h5>)

    }

    function showData(dat: BaseUserIEnumerableQrRead, isuser:boolean) {
        
        if(dat && dat.data && dat.data?.length>0){
                return (<ul className="match-stats-widget__body">
                    {
                    dat.data?.map( (a,b) => {
                        return (<li key={a.userId}>{a.username}
                            <ConfirmBox message="Removing user from area" title="Are you sure?" onConfirm={() => removeIt(a.userId!, isuser===true)} />
                        </li>)
                    })
                    }
            </ul>);            
        }
        else return(<h5 className="danger text-danger">There is no user found at this time!</h5>)
    }

    return(
        <div  className="h-75 d-flex flex-column justify-content-center align-content-md-center text-center">
        {  memberType && <div><br />Showing {memberType}s</div> }
        {  !memberType && <div className="h4 pt-3 text-center">Please select to view</div> }
        {
            memberType == "user"  && subData && showData(subData, true)
        }
        {
            memberType == "trader"  && traData && showData(traData, false)
        }
        {
            memberType == "invited" && invData && showDataI(invData)
        }
        {
            memberType == "requested" && invData && showDataI(invData)
        }
        {
            (traL || subL) && <p className="text-info">Loading please wait...</p>
        }




        </div>
    );

}


export default TraderChannelMembers;