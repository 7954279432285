import {
  PnLBoard,
  PnLBoardRead,
  useGetApiGreetGetMarketQuery,
  useLazyGetApiGreetGetMarketPriceQuery,
} from "../../api/greetApi";
import CurrencyView, { CurrencyViewWithColorStyle } from "../CurrencyView";
import PriceDisplay from "../PriceDisplay";
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import { useGetApiPositionGetPositionByIdQuery } from "../../api/positionApi";
import { toast } from "react-toastify";
import openPopup from "../openPopup";
import "./PnlFrame.css";
import { useAppSelector } from "../../store";
interface PnlFrameOpts {
  id: number;
  onHide: () => void;
  isOpen: boolean;
}

function getRandomStripeColor(): string {
  const min = 0x00;
  const max = 0x15;
  const randomValue = () => Math.floor(Math.random() * (max - min + 1)) + min;
  const toHex = (value: number) => value.toString(16).padStart(2, '0');

  const r = randomValue();
  const g = randomValue();
  const b = randomValue();

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
const StripeBackground = React.memo( ()  => {
  // Generate an array of random colors
  const stripes = Array.from({ length: 10 }, () => getRandomStripeColor());

  return (
      <div className="stripe-background">
          {stripes.map((color, index) => (
              <div
                  key={index}
                  className="stripe"
                  style={{ backgroundColor: color }}
              />
          ))}
      </div>
  );
});





const PnlFrame: React.FC<PnlFrameOpts> = (opts) => {
  useEffect(() => {
    if (opts.isOpen) {
    }
  }, [opts.isOpen]);

  const auth_user = useAppSelector( p => p.auth.user);

  const { data, isLoading, isSuccess, isError } =
    useGetApiPositionGetPositionByIdQuery({ id: opts.id });

  const [marketget, { data: mdata, isLoading: mloading, isSuccess: msuccess }] =
    useLazyGetApiGreetGetMarketPriceQuery();

  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => {
    takeScreenshot(ref.current);
    toast.success("Image copied to clipboard.");
  }

  const [calcPerc, setCalcPerc] = useState(0);
  const [lp, setLp] = useState<number>(0);

  useEffect(() => {    
    if (isSuccess) {
      const position_leverage =  data?.data?.leverageMax!;
      var total_pnl = data?.data?.pnl!; //closed part of the positions total PnL
      const total_invesment =
        (data?.data?.entryPrice! * data?.data?.orderQty!) /
        position_leverage;  //total invesment

      //if positions still open, we need to add curent PnL to calculation
      if (data?.data?.qty! > 0) {
        marketget({
          exchangeName: data?.data?.exchangeName!,
          pairName: data?.data?.pairName!,
        }).then((r) => {
          //current price of the crypto
          setLp(r.data?.data?.dValue!); //dValue is current price
          total_pnl +=
            (data?.data?.type! == 0 //type 0 is LONG 1 is SHORT
              ? r.data?.data?.dValue! - data?.data?.entryPrice!
              : data?.data?.entryPrice! - r.data?.data?.dValue!) *
            data?.data?.qty!;
            console.log(r.data?.data?.dValue!, total_pnl, total_invesment);

          setCalcPerc( (total_pnl / total_invesment) * 100.0 );
        });
      } else {
        console.log(total_pnl, total_invesment);
        setLp(data?.data?.exitPrice!);
        setCalcPerc((total_pnl / total_invesment) * 100.0);
      }
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (image != null) {
      try {
        // Base64 image string
        const base64ImageString = image.substring(22);

        // Convert base64 to Blob
        const byteCharacters = atob(base64ImageString);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blobData = new Blob([byteArray], { type: "image/png" });

        // Create ClipboardItem
        const clipboardItemInput = new ClipboardItem({
          "image/png": blobData,
    
        });
        console.log("writing clipboard", clipboardItemInput);
        // Write to clipboard
        navigator.clipboard
          .write([clipboardItemInput])
          .then((p) => console.log(p));
      } catch (e) {
        console.error(e);
      }
    }
  }, [image]);

  const CopyTextOpen = ( text: string) => {
    try {
      openPopup(text, "Share Coinsynthesis", window.screen.width / 2, window.screen.height / 2)
      CopyText(text);
    }
    catch (e) {
      console.error(e);
    }
  }

  const CopyText = (text:string) => {
    try {
        const type = 'text/plain';
        const blob = new Blob([text], { type });
    
        const clipboardItemInput = new ClipboardItem({
          [type]: blob
        });
        console.log("writing clipboard", clipboardItemInput);
        // Write to clipboard
        navigator.clipboard
            .write([clipboardItemInput])
            .then((p) => console.log(p));
        toast.success("Copied to clipboard.");

    } catch (e) {
      console.error(e);
    }
  }

  useEffect(()=> {
    console.log(opts.id, data?.data?.ownerId , auth_user?.id);
  },[data])

  return (
    <Modal
        size="lg"
      show={opts.isOpen}
      onHide={() => opts.onHide()}
      centered={true}
      className="match-stats-widget match-stats-widget--general"
    >
      <Modal.Header
        closeButton
        style={{ padding: "1rem 1rem 0 1rem", border: 0, background: "#151720" }}
      >
        <h5 className="text-white">P&L Share</h5>
      </Modal.Header>
      <Modal.Body style={{ background: "#151720" }}>
        <div className="row">
          <div className="col-md-7 col-xs-12">
            <div
              className="pnl-frame p-1"
              ref={ref}
              style={{ background: "#151720" }}
            >
              <div
                className="pnl-frame__header d-flex justify-content-between "
                style={{ width: "100%" }}
              >
                <div>
                  <span style={{ fontSize: "1.6rem", color: "#a3ff12" }}>
                    Coin Synthesis
                  </span>
                </div>
                <div className="p-1">{data?.data?.createdAt}</div>
              </div>
              <div
                className="pnl-frame__body pl-3"
                style={{
                  background: "url(/assets/img/logo.png) no-repeat",
                  backgroundPosition: "right center",
                  backgroundSize:"40%"
                }}
              >
                <div
                  className="pnl-frame__summary d-flex"
                  style={{ fontSize: "1.4rem" }}
                >
                  {data?.data?.type! == 0 ? (
                    <div
                      className="p-1 bg-muted"
                      style={{ backgroundColor: "#88df00", textShadow:'1px 1px 0px black' }}
                    >
                      LONG
                    </div>
                  ) : (
                    <div className="p-1 bg-muted" style={{ color: "pink" }}>
                      SHORT
                    </div>
                  )}
                  {data?.data?.type! == 0 ? (
                    <div
                      className="p-1"
                      style={{
                        textShadow: "1px 1px 0px black",
                        backgroundColor: "#88df00",
                      }}
                    >
                      {data?.data?.leverageMax}x
                    </div>
                  ) : (
                    <div className="p-1 bg-danger">
                      {data?.data?.leverageMax}x
                    </div>
                  )}
                  <div className="p-1">{data?.data?.pairName}</div>
                </div>
                <div className="pnl-frame__winrate h3 pt-2 ">
                  {calcPerc > 0 ? (<span className="text-success" style={{'fontSize':'3.35rem'}}>+{calcPerc.toFixed(2)}%</span>) : 
                  (<span className="text-danger" style={{'fontSize':'3.35rem'}}>{calcPerc.toFixed(2)}%</span>)}
                </div>
                <div
                  className="pnl-frame__summary"
                  style={{ fontSize: "0.9rem" }}
                >
                  <div className="p-1">
                    <span
                      className="text-muted"
                      style={{ width: "90px", display: "inline-block" }}
                    >
                      Entry Price :
                    </span>
                    <PriceDisplay value={data?.data?.entryPrice!} sta={true} sign={""} pre={false} />
                  </div>
                  <div className="p-1">
                    <span
                      className="text-muted"
                      style={{ width: "90px", display: "inline-block" }}
                    >
                      Last Price :
                    </span>
                    <PriceDisplay value={ (data?.data?.exitPrice && data?.data?.exitPrice! >0) ? data?.data?.exitPrice :  lp } sta={true} sign={""} pre={false} />
                  </div>
                  <div className="p-1">
                    <span
                      className="text-muted"
                      style={{ width: "90px", display: "inline-block" }}
                    >
                      Exchange :
                    </span>{" "}
                    {data?.data?.exchangeName}
                  </div>
                  <div className="p-1">
                    <span
                      className="text-muted"
                      style={{ width: "90px", display: "inline-block" }}
                    >
                    User : 
                    </span>{" "}
                    {data?.data?.ownerName!}
                  </div>
                  <div className="p-1">
                    <span
                      className="text-muted"
                      style={{ width: "90px", display: "inline-block" }}
                    >
                      Channel :
                    </span>{" "}
                    {data?.data?.channelName!}
                  </div>
                  <small>
                  ROI calculation is total PnL vs total invesment.
                </small>
                </div>
              </div>
              <div className="pnl-frame__footer" style={{ display:'none', fontSize: "1rem" }}>
                <Button
                  onClick={(e) => getImage()}
                  className="btn btn-sm"
                  style={{ float: "right" }}
                >
                  Copy
                </Button>
              
              </div>
            </div>
          </div>
         
         
            <div className="col-md-5 col-xs-12">

            <h5 className="text-success">Referral Code</h5>
            <div className="h5 text-white cur_pointer" onClick={(e)=> CopyText('https://coinsynthesis.com/login?ref='+data?.data?.ownerRef! )} >{data?.data?.ownerRef!} <i className="fa-solid fa-copy"  
            style={{'lineHeight':'1rem', 'padding':'5px 6px', 'verticalAlign':'middle', 'fontSize':'0.9rem'}}></i></div>
            <h5 className="text-success">Share</h5>
            <div>
                <ul className="match-stats-widget__body">
                    <li className="lbt01 lbt02 d-inline-block"><span className="btn btn-sm btn-success" onClick={(e) => getImage()}><i className="fa-solid fa-download"></i>Copy Image</span></li>
                    <li className="lbt01 lbt02 d-inline-block"><span className="btn btn-sm btn-success" 
                        onClick={(e)=> CopyText('https://coinsynthesis.com/login?ref='+data?.data?.ownerRef! )}
                    ><i className="fa-solid fa-copy"></i>Copy link</span></li>
                    <li className="lbt01 lbt02 d-inline-block"><span className="btn btn-sm btn-success" 
                        onClick={(e)=> CopyTextOpen('https://x.com/intent/post?text=Join+Coin+Synthesis+with+me+and+earn%21&url=https%3A%2F%2Fcoinsynthesis.com%2Flogin%3Fref%3D'+data?.data?.ownerRef! ) }
                     ><i className="fa-brands fa-twitter"></i>Twitter</span></li>
                    <li className="lbt01 lbt02 d-inline-block"><span className="btn btn-sm btn-success" 
                       onClick={(e)=> CopyTextOpen('https://t.me/share/url?url=https%3A%2F%2Fcoinsynthesis.com%login%3Fref%3D'+data?.data?.ownerRef!+'&text=Join%Coin+Synthesis%20with%20me!') }
                    ><i className="fa-brands fa-telegram"></i>Telegram</span></li>
                    <li className="lbt01 lbt02 d-inline-block"><span className="btn btn-sm btn-success" 
                        onClick={(e)=> CopyTextOpen('https://www.facebook.com/sharer.php?quote=Join%20Coin+Synthesis%20with%20me%20and%20earn!&u=https%3A%2F%2Fcoinsynthesis.com%2Flogin%3Fref%3D'+data?.data?.ownerRef! ) }
                    ><i className="fa-brands fa-facebook"></i>Facebook</span></li>
                    <li className="lbt01 lbt02 d-inline-block"><span className="btn btn-sm btn-success" 
                        onClick={(e)=> CopyTextOpen('https://api.whatsapp.com/send?text=Join%20Coin+Synthesis%20with%20me%20and%20earn!') }
                    ><i className="fa-brands fa-whatsapp"></i>WhatsApp</span></li>
                    
                </ul>
            
            </div>
            </div>
         
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PnlFrame;
