import React, { useEffect } from 'react';
import useBodyClassnames from '../../components/useBodyClassnames';
import { useNavigate } from 'react-router-dom';

interface ErrorDisplayProps {
  logoSrc: string;
  title: string;
  subtitle: string;
  description: string;
  linkHref: string;
  linkText: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ 
  logoSrc, 
  title, 
  subtitle, 
  description, 
  linkHref, 
  linkText 
}) => {



  
    const navigate = useNavigate();
    useBodyClassnames(["site-layout--landing","bg-image","bg-fixed", "bg--texture-01","bg--dotted-3x3","site-layout--landing-error","scroll-is--active"]);


  

  return (
    <div className="row">
      <div className="error__logo-wrapper col-md-6 d-none d-md-block">
        <img src={logoSrc} className="error__logo" alt="" />
        {/* Decoration */}
        <div className="ncr-page-decor">
          <div className="ncr-page-decor__layer-3" style={{'width':'35%', 'left': '30%'}}></div>
        </div>
        {/* Decoration / End */}
      </div>
      <div className="error__txt-wrapper col-md-5 align-self-center offset-md-1" style={{'zIndex':10}}>
        <h1 className="error__title">{title}</h1>
        <h2 className="error__subtitle">{subtitle}</h2>
        <div className="error__desc">
          <p>
            {description} <a  onClick={() => navigate(linkHref) }>{linkText}</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorDisplay;
