import { Col, Form, Row } from 'react-bootstrap';

const ConfirmEmailPage = () => {


    return (
    
        <main className="site-content login-page" id="wrapper">
            <Row>             
                <Col md={6} className="d-none d-sm-block" style={{'justifyContent':'center'}}>
                    <div style={{ 'textAlign': 'center'}}><img onClick={e => console.log('suicide')} src="/assets/img/logo@2x.png" alt="" /></div>
                </Col>
                <Col md={6} style={{'justifyContent':'center'}}>                    
                        <h2 className="h4">Confirmation email has been sent to your mail, please check your emailbox.</h2>
                </Col>                     
            </Row>        
        </main>
    )

};


export default ConfirmEmailPage;