import { Form  } from 'react-bootstrap';
  import { FC } from 'react';
  import { Controller, useFormContext } from 'react-hook-form';
  
interface IFormInputProps {
    name:string;
    label: string;
    type: string;
    placeholder: string;
    invalid: string;
}

  const FormInput: FC<IFormInputProps> = ({ name, label, type, placeholder, invalid, ...otherProps }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
  
    const errmessage = errors[name]?.message?.toString();

    return (
      <Controller
        control={control}
        defaultValue=''
        name={name}
        render={({ field }) => (
            <Form.Group className="form-group">
                <Form.Label>{label}</Form.Label>
                <Form.Control type={type} placeholder={placeholder}  {...field} {...otherProps}></Form.Control>
                <Form.Control.Feedback type="invalid">
                    {invalid}
                </Form.Control.Feedback>
                <div>
                    {errmessage}
                </div>
            </Form.Group>
        )}
      />
    );
  };
  
  export default FormInput;