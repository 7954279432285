import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLazyGetApiPositionGetPositionHistoryByIdQuery } from "../../api/positionApi";
import CurrencyView from "../../components/CurrencyView";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import TimeAgo from 'react-timeago'

import { TradeTypeText } from './interfaces/iTradeType';


export const TraderPagePositionHistoryModal = ({ isOpen, qty, price, posId, onHide }: { isOpen: boolean; price: number; posId: number; qty: number; onHide: () => void; }) => {

	const [ getHistory, { isLoading, isError, isFetching, isSuccess, data } ]
	= useLazyGetApiPositionGetPositionHistoryByIdQuery({});
		
	useEffect(() => {	
		if(isOpen) {
		console.log("getHistory");
			getHistory({  id: posId});
		}
	},[isOpen]);

	const closePositionNow = (event: any): void => {
		onHide();
	};

	return (<>
		<Modal scrollable={true} show={isOpen} onHide={() => closePositionNow(null)}  centered={true} className="match-stats-widget match-stats-widget--general">
			<Modal.Header closeButton style={{ 'padding': '.5rem', 'border': 0, 'background': '#151720' }}>
				<div className="match-stats-widget__header p-2" style={{ 'color': 'white' }}>
					Position History
				</div>
			</Modal.Header>
			<Modal.Body style={{ 'background': '#151720' }}>
			<div className="table-responsive-sm">
				<table className="table matches-table table-dark table-insite table-order">
					<tbody>
						<tr>
							<td colSpan={3}>Quantity: {qty}</td>
							<td colSpan={2} style={{ 'textAlign': 'right' }}>
								Order Price: <CurrencyView value={price!} /></td>
						</tr>
						<tr>
							<td>Time</td>	
							<td>Type</td>
							<td>Price</td>
							<td>Qty</td>
							<td>Pnl</td>
						</tr>
						{
							data && (data as any).data.length > 0 && (data as any).data.map((item:any, index:number) => (
                                <tr key={item.id!}>
                                    <td><TimeAgo date={item.createdAt!}></TimeAgo></td>
									<td><TradeTypeText  tradeType={item.tradeTyp!}  /> </td>
									<td>{item.price!}</td>
									<td>{(item.qty!).toFixed(5)}</td>
									<td>{(item.pnl!).toFixed(2)}</td>
                                </tr>
                            ))	
						}
					</tbody>
				</table>
			</div>

			</Modal.Body>
			<Modal.Footer style={{ 'background': '#151720', 'border': 0 }}>
				<Button size="sm" variant="danger" onClick={() => onHide()}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	</>);
};
