import React, { Suspense, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import PriceBox from '../components/PriceBox';
import useTextTyping from '../hooks/useTextTyping';
import { useAppSelector } from '../store';
import NavLinkC from '../components/NavLinkC';
import useBodyClassnames from '../components/useBodyClassnames';
import { useTranslation } from "react-i18next";
const LandingPage = () => {
    const { t } = useTranslation();
	const {text, setEnabled} = useTextTyping("COINSYNTHESIS");



	useBodyClassnames(["site-layout--landing","bg--texture-05"]);

	React.useEffect(()=> {		
		setTimeout(()=>setEnabled(true), 600);		
	},[])
    

	const landingTop = useAppSelector(t => t.greet.market);

    

        return(<>
		<main className="site-content text-center" id="wrapper">
		<div className='site-content__center'>
        	<h1 className="text-white landing-title"><span className="subtitle landing-subtitle">
				<TypeAnimation sequence={['Top Cryptocurrencies', 1000, 'Top Exchanges', 1000 , 'Best Traders', 1000,'Start Earn', 1000]} speed={30} style={{fontSize:'2em'}} repeat={Infinity} />				
				</span>{text}</h1>
        	<a href="./login" className="btn btn-primary btn-lg btn--landing"><span>&gt; &gt; {t('Enter Beta Site')} &lt; &lt; </span></a>
		</div>
      </main>
      <footer id="footer" className="footer text-center">
			<ul className="social-menu social-menu--landing social-menu--landing-glitch">
				<li>
					
						{landingTop && landingTop.length>0 &&  <PriceBox Name={landingTop[0]["name"]} price={landingTop[0]["price"]}  Symbol={landingTop[0]["symbol"]}  key={landingTop[0]["symbol"]}  />}
						{!landingTop  &&  <PriceBox Name={'________'} price={0}  Symbol={'__'}  key={'___'} glimmer={true}  />}
					
				</li>
				<li>
					{landingTop && landingTop.length>0 &&  <PriceBox Name={landingTop[1]["name"]} price={landingTop[1]["price"]}  Symbol={landingTop[1]["symbol"]}  key={landingTop[1]["symbol"]}  />}
					{!landingTop  &&  <PriceBox Name={'________'} price={0}  Symbol={'__'}  key={'___'} glimmer={true}/>}
				</li>
				<li>
					<NavLinkC to="./currencies/spot" ><i className="fas fa-coins glitch-layer glitch-layer--1"></i><i className="fas fa-coins glitch-layer glitch-layer--2"></i>
						<i className="fas fa-coins"></i>
						<span className="link-subtitle">Markets</span>Cryptocurrency
					</NavLinkC>
				</li>
				<li>
					<Link to="./trader" ><i className="fas fa-check-double glitch-layer glitch-layer--1"></i><i className="fas fa-check-double glitch-layer glitch-layer--2"></i>
						<i className="fas fa-check-double"></i>
						<span className="link-subtitle">Earn Today</span>Traders
					</Link>
				</li>
			</ul>
	  </footer>
    	<div className="landing-detail landing-detail--left">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
    	<div className="landing-detail-cover landing-detail-cover--left">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
    	<div className="landing-detail landing-detail--right">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
    	<div className="landing-detail-cover landing-detail-cover--right">
			<span>&nbsp;</span>
			<span>&nbsp;</span>
			<span>&nbsp;</span>
		</div>
        </>)

};


export default LandingPage;
