import React, { useState } from 'react';
import { usePostApiChannelUploadAvatarByIdAvatarMutation } from '../../api/channelApi';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import useApiErrorHandling from '../../hooks/useApiErrorHandling';

const TraderChannelAvatarUpload = ({show, chanId,  onClose } : {show:boolean, chanId: number, onClose: () => void }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadAvatar] = usePostApiChannelUploadAvatarByIdAvatarMutation();
  const { handleApiError } = useApiErrorHandling(); // Use the hoo
  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      console.log("uploading file", selectedFile);
      try {
        const fd = new FormData();
        fd.append("avatar", selectedFile);

        const resp = await uploadAvatar({
          id:chanId, 
          body: fd as any    
        });
        if('error' in resp) {
          if('data' in resp.error)          
            handleApiError(resp.error.data as any);
        }
        else {
          toast.success("Uploaded");
        }
        onClose();
        // Optionally, you can trigger a refresh or update of the avatar display
      } catch (error) {
        console.error('Error uploading avatar:', error);
        toast.error(error as any);
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} size='lg' scrollable={true} centered={true}>
    <Modal.Header closeButton>
      <Modal.Title>Upload Channel Avatar</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <Button size="sm" variant="primary"  onClick={handleUpload}>Upload</Button>
      <Button size="sm" variant="danger" onClick={onClose}>Cancel</Button>
    </Modal.Body>
    </Modal>
  );
};

export default TraderChannelAvatarUpload;