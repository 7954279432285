import Decors from "../partials/Decors";

const GuidePage = () => {


    return( <main className="site-content" id="wrapper">			
    <div className="site-content__inner">
    <div className="site-content__holder">
    <Decors>                            
            <h1 className="text-white">Guides</h1>
    </Decors>
        <div className="page-content">
        <h4 className="mb-5">
            Getting Started to Coin Synthesis
        </h4>
        <div className="row ">
            <div className="col-md-12">
                <p>
                    Coin Synthesis Trader connected to well known exchanges and fetches futures and spot data directly from exchanges. Coin Synthesis trading system simulates close to real trading. 
                    Our aim is finding the best traders in crypto futures and show their not only best trades also worst ones. 
                </p>
                <p>
                    All traders has equal wallet balance, every trader is equal here. Each trader will start with $1000 balance, with maxium 5 active orders, and 5 active positions, current system allow 10x leverage to all traders. 
                    Maintanance Margin rates and Funding Fee will be calculated close as real exchanges. 
                    When trader created an order, the balance will adjusted as the order size. Traders can not pass total of $1000 with their orders and positions. 
                </p>
                <p>
                    Trading Channels are helping traders work as a team and people who are following them will be notified in time. Owner of channels can invite other traders to join their channel and also users can join channels. There are two types of channels in the system; Public and Private.
                    <b>Public Channel</b> is open for all and anyone can join channel within limits. <b>Private Channel</b> similar to public ones instead only owner of channel can invite people as trader or user.
                    Users joined to a channel can see their trades live with all details like exchange, pair, amount, stoploss and take profit levels others can only see masked information while trade is active. When trade is closed, everyone can see details of the trade.
                    All channels have some public information like social acconts, like facebook, x, telegram etc.
                </p>
                <p>

                </p>
            </div>
        </div>
                </div>
            </div>
        </div>
    </main> )

};


export default GuidePage;