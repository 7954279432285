import React, { useEffect, useState } from 'react';
import { usePostApiAuthUpdateProfileMutation } from '../api/authApi';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch } from '../store';
import { changeDisplay } from '../../features/auth/authSlice';

const UserUpdateProfile = ({show, onClose } : { show:boolean, onClose: () => void }) => {
  const [newName, setNewName] = useState("");
  const [updateName, { isSuccess: nameUpdated }] = usePostApiAuthUpdateProfileMutation();

  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    setNewName(e.target.value);
  };

  useEffect(()=> {
    
  },[nameUpdated])

  const handleUpdate = async () => {
    if (newName != null && newName != "") {
      try {
        const resp = await updateName({
         displayName: newName
        }).unwrap();

        if(resp.ok==true) {
          toast.success('Nickname updated successfully');
          dispatch(changeDisplay(newName))
          onClose();
        }

        // Optionally, you can trigger a refresh or update of the avatar display
      } catch (error) {
        console.error('Error upading profile:', error);
        toast.error( (error as any).data["errors"]["$"][0] );
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} size='lg' scrollable={true} centered={true}>
    <Modal.Header closeButton>
      <Modal.Title>Change Displayname</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <input type="text"  value={newName} onChange={handleChange} />
      <Button size="sm" variant="primary"  onClick={handleUpdate}>Update</Button>
      <Button size="sm" variant="danger" onClick={onClose}>Cancel</Button>
    </Modal.Body>
  </Modal>
  );
};

export default UserUpdateProfile;