import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '../../components/FormInput';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePostApiAuthForgotPasswordMutation, PostApiAuthForgotPasswordReq } from '../../api/authApi';
import { Col, Form, Row } from 'react-bootstrap';
import { useAppDispatch } from '../../store';


const passwordSchema = object({  
  email: string()
    .min(1,'Email address is required')
    .email('Email Address is invalid') 
});

export type passwordInput = TypeOf<typeof passwordSchema>;

const ForgotPasswordPage = () => {
  const pwdMethods = useForm<passwordInput>({
    resolver: zodResolver(passwordSchema),
  });

  
  const [forgotPwd, { 
          isLoading: pwdIsLoading, 
          isError: pwdIsError, 
          error: pwdError, 
          isSuccess: pwdIsSuccess }] = usePostApiAuthForgotPasswordMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const dipatch = useAppDispatch();
  
  

  const {
    reset: pwdReset,
    handleSubmit : pwdSubmit,
    formState: { isSubmitSuccessful : pwdSubmitOk },
  } = pwdMethods;


  useEffect(() => {
    if (pwdIsSuccess) {
      toast.success('Email sent to your mailbox');
      navigate("/forgotpasswordsent");
    }
    if (pwdIsError) {
      if(pwdError!=undefined){
        const err = pwdError as any;
        if(err.status=="PARSING_ERROR" || err.status=="FETCH_ERROR"){
          toast.error('Most likely there is an connection or server error.');
        }
        else {          
          if (typeof((err).data.errors)=="object" ) {
            const ers = (err).data.errors["$"] as Array<string>;
            if(ers) {
              ers.forEach( (el: any)  => {              
                toast.error(el, { position: 'top-right', });
              });
            }
          } else {
            toast.error((pwdError as any).data.message, {
              position: 'top-right',
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pwdIsLoading]);

  
  const onSubmitPwdHandler: SubmitHandler<passwordInput> = (values) => {
    var vl: PostApiAuthForgotPasswordReq = {
      forgotPassword: {
        email : values.email        
      }
    };
    forgotPwd(vl);
  };



  return (
    <main className="site-content login-page" id="wrapper">
      <Row>     
      <Col md={6} className="d-none d-sm-block center-logo">
            <img onClick={e => console.log('suicide')} src="/assets/img/logo@2x.png" alt="" />             
        </Col>   
        <Col id={'forgot'}  md={6} >
          <h2 className="h4">Forgot Password</h2>
        <FormProvider {...pwdMethods}>
          <Form className="form login-form"            
            onSubmit={pwdSubmit(onSubmitPwdHandler)}
            noValidate
            autoComplete='off'                 
          >
            <FormInput name='email'  label='' type='email' placeholder={'Your Email *'} invalid={''} />
                   
           
            <div className="form-group d-flex justify-content-between align-items-center">
							<div></div>
							<span className="password-reminder">
                  <Link to={'/login'}>Already have account?</Link>
							</span>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
              <button className="btn btn-block login-form__button" disabled={pwdIsLoading} >Send Recovery Code
              {pwdIsLoading &&  <span className="spinner-grow spinner-grow-sm"></span>}
            </button>
						</div>
            
          </Form>
        </FormProvider>
        </Col>
        
        <Col md={6} id={'pwdleft'} >
      
        </Col>
      </Row>
    </main>        
  );
};

export default ForgotPasswordPage

