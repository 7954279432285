import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useLazyGetApiPositionGetPositionsOfQuery } from "../../api/positionApi";
import {  PriceProvider } from '../trader/trader.page.context';
import { TraderPagePositionsRow, MemoTraderPagePositionsRow } from "./TraderPagePositionsRow";
import { useAppDispatch, useAppSelector } from "../../store";
import { traderActions } from "../../../features/trader/traderSlice";
import { useMediaQuery } from "react-responsive";
export interface CP {
	gotoPair: (exchangeId: string, exchangeName: string, pairId: string, pairName: string) => void
}

const TraderPagePositions: React.FC<CP> = (props) => {
	
	const userAccessTokenExpiration = useAppSelector(p=>p.auth?.userToken?.accessTokenExpiration);
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 


	const traderState = useAppSelector(p => p.tader);
	const dispatch = useAppDispatch();

	const [ getPosOf , { data: getPosOfData, error: getPosOfError }] = useLazyGetApiPositionGetPositionsOfQuery();
	
	const [refresh ,setRefresh] = useState<number>(0);
	useEffect( ()=> {
		if(userAccessTokenExpiration)
			getPosOf({ active: true, typ: 'Position'});
	},[refresh])
	
	useEffect(()=> {	

		if(traderState.newPositionCreated === true) {
			dispatch(traderActions.resetPositionCreated());
			getPosOf({ active: true, typ: 'Position'});
		}

	},[traderState.newPositionCreated,refresh]);
	
    return (<>
		<div className="table-responsive-sm">
			<Table  className="table matches-table table-hover table-dark table-insite">
				<thead>
					{!isTabletOrMobile &&
					<tr>
						<th title="Contract name will always be displayed when you're scrolling.">Contracts</th>
						<th>Exchange</th>
						
						<th className="text-center" title="A positive value of quantity indicates a long position, while a negative value indicates a short position">Qty</th>
						<th className="text-center" title="Notional value of the current position">Value</th>
						<th className="text-center" title="The weighted average entry price of your positions">Entry Price</th>
						<th className="text-center">Mark Price</th>
						<th className="text-center" title="Liquidation refers to an event when the Mark Price reaches the liquidation price, and the position is closed at the Bankruptcy Price (0% margin price level)">Liq. Price</th>
						<th className="text-center">Position Margin</th>
						<th className="text-center">Unrealized P&L ($)</th>
						<th className="text-center">Realized P&L ($)</th>
						<th className="text-center">Take Profit</th>
						<th className="text-center">Stoploss</th>
						<th>&nbsp;</th>
					</tr>}
				</thead>
				<tbody>
					<PriceProvider>
					{getPosOfData && getPosOfData.data  && getPosOfData.data.length >0 && 									
							getPosOfData.data!.map((a,b)=> <MemoTraderPagePositionsRow gotoPair={props.gotoPair} key={a.id!} pos={a} pig={() => setRefresh(refresh + 1 )} />)									
					}								
					</PriceProvider>
					{ !(getPosOfData && getPosOfData.data  && getPosOfData.data.length >0 ) && 
					<tr>
						<td colSpan={14}>
							<div className="pt-5 pb-4 text-center color-warning">											
								<svg role="img" style={{fill:'#ffcc00'}} height={25} className="df-icon df-icon--bag">
									<use xlinkHref="/assets/img/necromancers.svg#comments-small"></use></svg>
									<div className="pt-2">No record yet</div>
							</div>
						</td>
					</tr>
					}
				</tbody>
			</Table>		
		</div>
    </>)
}

export default TraderPagePositions;


