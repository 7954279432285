import React, { memo, useMemo } from "react";

const generateGuidSvg = (guid: string): string => {
    // Convert the GUID to an array of characters
    const guidChars = (guid).split('');
    
    // Generate color from character code
    const generateColor = (charCode: number): string => {
        const r = (charCode * 13) % 255; // Randomize red component
        const g = (charCode * 17) % 255; // Randomize green component
        const b = (charCode * 19) % 255; // Randomize blue component

        return `rgb(${r},${g},${b})`;
    };
    // Generate SVG pattern using the GUID characters
    const svgContent = guidChars.map((char, index) => {
        // Calculate position within the 64x64 grid
        const x = (index % 6) * 6;
        const y = Math.floor(index / 6) * 6;

        // Generate color from the current character
        const color = generateColor(char.charCodeAt(0));

        // Create a square element using the current character as color
        return `<rect x="${x}" y="${y}" width="6" height="6" fill="${color}" />`;
    }).join('');

    // Generate border color from the GUID
    const borderColor = '#' + guid.substr(0, 6); // Use the first 6 characters of the GUID for border color

    // Wrap the squares in an SVG element and add border
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" style="border: 1px solid ${borderColor};">${svgContent}</svg>`;

    return svg;
};


const generateGuidSvg_v4 = (guid: string): string => {
    // Convert the GUID to an array of characters
    const guidChars = guid.split('');

    // Generate SVG pattern using the GUID characters
    const svgContent = guidChars.map((char, index) => {
        // Calculate position within the 64x64 grid
        const x = (index % 8) * 8;
        const y = Math.floor(index / 8) * 8;

        // Create a square element using the current character as color
        return `<rect x="${x}" y="${y}" width="8" height="8" fill="#${char}${char}${char}" />`;
    }).join('');

    // Wrap the squares in an SVG element
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">${svgContent}</svg>`;

    return svg;
};

function generateGuidSvg_v3(guid: string): string {
    // Split the GUID into sections
    const sections = [
      guid.substring(0, 8),
      guid.substring(8, 12),
      guid.substring(12, 16),
      guid.substring(16, 20),
      guid.substring(20),
    ];
  
    // Define a base color palette
    const baseColors = ['#f00', '#00f', '#0f0', '#ff0'];
  
    // Function to generate a new color based on a base color
    const getNewColor = (baseColor: string) => {
      const color = baseColor.slice(1); // Remove the hash symbol (#)
      const adjusted = parseInt(color, 16) + (Math.floor(Math.random() * 2) - 1) * 30; // Adjust color value slightly
      return `#${adjusted.toString(16).padStart(6, '0')}`;
    };
  
    // Function to map a GUID section to a color
    const getColor = (section: string, index: number) => {
      return index < 4 ? baseColors[index] : getNewColor(baseColors[index % 4]);
    };
  
    // Start building the SVG string
    let svg = '<svg width="64" height="64">';
  
    // Loop through each section and create a path element
    for (let i = 0; i < sections.length; i++) {
      const color = getColor(sections[i], i);
      let path = `M ${i % 2 * 32},${Math.floor(i / 2) * 32} `;
      const section = sections[i];
  
      // Generate path based on section characters
      for (let j = 0; j < section.length; j++) {
        const charCode = section.charCodeAt(j) % 16;
        switch (charCode) {
          case 0:
          case 1:
          case 2:
          case 3:
            path += 'h 10'; // Right
            break;
          case 4:
          case 5:
          case 6:
          case 7:
            path += 'v 10'; // Down
            break;
          case 8:
          case 9:
          case 10:
          case 11:
            path += 'h -10'; // Left
            break;
          case 12:
          case 13:
          case 14:
          case 15:
            path += 'v -10'; // Up
            break;
        }
      }
  
      path += ' Z'; // Close the path
      svg += `<path d="${path}" fill="${color}" stroke="none" />`;
    }
  
    // Add closing tag
    svg += '</svg>';
  
    return svg;
  }
function generateGuidSvg_v2(guid: string): string {
    // Split the GUID into sections
    const sections = [
      guid.substring(0, 8),
      guid.substring(8, 12),
      guid.substring(12, 16),
      guid.substring(16, 20),
      guid.substring(20),
    ];
  
    // Define a base color palette
    const baseColors = ['#f00', '#00f', '#0f0', '#ff0'];
  
    // Function to generate a new color based on a base color
    const getNewColor = (baseColor: string) => {
      const color = baseColor.slice(1); // Remove the hash symbol (#)
      const adjusted = parseInt(color, 16) + (Math.floor(Math.random() * 2) - 1) * 30; // Adjust color value slightly
      return `#${adjusted.toString(16).padStart(6, '0')}`;
    };
  
    // Function to map a GUID section to a color
    const getColor = (section: string, index: number) => {
      if (index < 4) {
        return baseColors[index];
      } else {
        return getNewColor(baseColors[index % 4]);
      }
    };
  
    // Start building the SVG string
    let svg = '<svg width="64" height="64">';
  
    // Process first section (0-8) as path
    const pathSection = sections[0];
    let path = `M 10,10 `;
    for (let i = 0; i < pathSection.length; i++) {
      const charCode = pathSection.charCodeAt(i) % 16;
      if (charCode <= 3) {
        path += 'h 10'; // Right
      } else if (charCode <= 7) {
        path += 'v 10'; // Down
      } else if (charCode <= 11) {
        path += 'h -10'; // Left
      } else {
        path += 'v -10'; // Up
      }
    }
    svg += `<path d="${path}" fill="none" stroke="black" stroke-width="2" />`;
  
    // Process remaining sections (8-20+)
    for (let i = 1; i < 5; i++) {
      const color = getColor(sections[i], i);
      const x = (i % 2) * 32;
      const y = Math.floor(i / 2) * 32;
  
      if (i === 4) {
        // Lines for the last section (20+)
        const isHorizontal = Math.random() < 0.5;
        const lineStart = isHorizontal ? x : y;
        const lineEnd = isHorizontal ? x + 32 : y + 32;
        svg += `<line x1="${x}" y1="${y}" x2="${lineEnd}" y2="${y}" stroke="${color}" stroke-width="2" />`;
      } else {
        // Rectangles for sections 8-16
        svg += `<rect x="${x}" y="${y}" width="32" height="32" fill="${color}" />`;
      }
    }
  
    // Add closing tag
    svg += '</svg>';
  
    return svg;
  }
function generateGuidSvg_v1(guid: string): string {
    // Split the GUID into sections
    const sections = [
      guid.substring(0, 8),
      guid.substring(8, 12),
      guid.substring(12, 16),
      guid.substring(16, 20),
      guid.substring(20),
    ];
  
    // Define a limited color palette
    const colors = ['#f00', '#00f', '#0f0', '#ff0'];
  
    // Function to map a GUID section to a color index
    const getColorIndex = (section: string) => {
      // Convert the first character of the section to a number (0-15)
      const charCode = section.charCodeAt(0) % 16;
      // Use the character code to get the color index within the limited palette
      return Math.floor(charCode / 4);
    };
  
    // Start building the SVG string
    let svg = '<svg width="64" height="64">';
  
    // Loop through each GUID section and create a path element
    for (let i = 0; i < sections.length; i++) {
      const colorIndex = getColorIndex(sections[i]);
      const color = colors[colorIndex];
      const x = (i % 2) * 32;
      const y = Math.floor(i / 2) * 32;
  
      // Create a path that combines a rectangle and a diagonal line
      const path = `M ${x},${y} h 32 v 32 L ${x},${y + 32} Z`;
      svg += `<path d="${path}" fill="${color}" />`;
    }
  
    // Add closing tag
    svg += '</svg>';
  
    return svg;
  }

function generateGuidSvg_v0(guid: string): string {
    // Split the GUID into sections
    const sections = [
      guid.substring(0, 8),
      guid.substring(8, 12),
      guid.substring(12, 16),
      guid.substring(16, 20),
      guid.substring(20),
    ];
  
    // Function to generate a random color in hexadecimal format
    const randomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
  
    // Start building the SVG string
    let svg = '<svg width="64" height="64">';
  
    // Loop through each GUID section and create a rectangle element
    for (let i = 0; i < sections.length; i++) {
      const color = randomColor();
      const x = (i % 2) * 32;
      const y = Math.floor(i / 2) * 32;
      svg += `<rect x="${x}" y="${y}" width="32" height="32" fill="${color}" />`;
    }
  
    // Add closing tag
    svg += '</svg>';
  
    return svg;
  }
const generateSvgFromGuid = (guid:string) => {
    // Convert the GUID to a hash (SHA-1 for example)
    const hashedGuid = sha1(guid);
console.log("sha1",hashedGuid); 
    // Define SVG parameters
    const svgWidth = 100;
    const svgHeight = 100;

    // Function to map a value from one range to another
    function mapValue(value:number, inMin:number, inMax:number, outMin:number, outMax:number) {
        return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }

    // Generate SVG path based on the hashed GUID
    let path = 'M 0,0 '; // Start at the top-left corner
    for (let i = 0; i < hashedGuid.length; i++) {
        // Convert each character to a number
        const charValue = parseInt(hashedGuid[i], 16);
        // Map the value to a coordinate within the SVG bounds
        const x = mapValue(charValue, 0, 15, 0, svgWidth);
        const y = mapValue(charValue, 0, 15, 0, svgHeight);
        // Append the coordinate to the path
        path += `L ${x},${y} `;
    }
    // Close the path
    path += 'Z';

    // Construct the SVG string
    const svgString = `<svg width="${svgWidth}" height="${svgHeight}" viewBox="0 0 ${svgWidth} ${svgHeight}" xmlns="http://www.w3.org/2000/svg"><path d="${path}" fill="none" stroke="black" stroke-width="1" /></svg>`;

    return svgString;
}
function sha1(message:string) {
    function rotateLeft(n :any, s:any) {
        return (n << s) | (n >>> (32 - s));
    }

    function cvtHex(val:any) {
        var str = '';
        var i;
        var v;

        for (i = 7; i >= 0; i--) {
            v = (val >>> (i * 4)) & 0x0f;
            str += v.toString(16);
        }
        return str;
    }

    function utf8Encode(str:any) {
        str = str.replace(/\r\n/g, '\n');
        var utf8str = '';

        for (var n = 0; n < str.length; n++) {
            var c = str.charCodeAt(n);

            if (c < 128) {
                utf8str += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utf8str += String.fromCharCode((c >> 6) | 192);
                utf8str += String.fromCharCode((c & 63) | 128);
            } else {
                utf8str += String.fromCharCode((c >> 12) | 224);
                utf8str += String.fromCharCode(((c >> 6) & 63) | 128);
                utf8str += String.fromCharCode((c & 63) | 128);
            }
        }

        return utf8str;
    }

    var blockstart;
    var i, j;
    var W = new Array(80);
    var H0 = 0x67452301;
    var H1 = 0xEFCDAB89;
    var H2 = 0x98BADCFE;
    var H3 = 0x10325476;
    var H4 = 0xC3D2E1F0;
    var A, B, C, D, E;
    var temp;

    message = utf8Encode(message);
    var messageLength = message.length;
    var wordArray = [];

    for (i = 0; i < messageLength - 3; i += 4) {
        j = message.charCodeAt(i) << 24 | message.charCodeAt(i + 1) << 16 | message.charCodeAt(i + 2) << 8 | message.charCodeAt(i + 3);
        wordArray.push(j);
    }

    switch (messageLength % 4) {
        case 0:
            i = 0x080000000;
            break;
        case 1:
            i = message.charCodeAt(messageLength - 1) << 24 | 0x0800000;
            break;
        case 2:
            i = message.charCodeAt(messageLength - 2) << 24 | message.charCodeAt(messageLength - 1) << 16 | 0x08000;
            break;
        case 3:
            i = message.charCodeAt(messageLength - 3) << 24 | message.charCodeAt(messageLength - 2) << 16 | message.charCodeAt(messageLength - 1) << 8 | 0x80;
            break;
    }

    wordArray.push(i);

    while ((wordArray.length % 16) != 14) {
        wordArray.push(0);
    }

    wordArray.push(messageLength >>> 29);
    wordArray.push((messageLength << 3) & 0x0ffffffff);

    for (blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
        for (i = 0; i < 16; i++) {
            W[i] = wordArray[blockstart + i];
        }

        for (i = 16; i <= 79; i++) {
            W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
        }

        A = H0;
        B = H1;
        C = H2;
        D = H3;
        E = H4;

        for (i = 0; i <= 19; i++) {
            temp = (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        for (i = 20; i <= 39; i++) {
            temp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        for (i = 40; i <= 59; i++) {
            temp = (rotateLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        for (i = 60; i <= 79; i++) {
            temp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = temp;
        }

        H0 = (H0 + A) & 0x0ffffffff;
        H1 = (H1 + B) & 0x0ffffffff;
        H2 = (H2 + C) & 0x0ffffffff;
        H3 = (H3 + D) & 0x0ffffffff;
        H4 = (H4 + E) & 0x0ffffffff;
    }

    var sha1 = cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4);

    return sha1.toLowerCase();
}



const Guider_ = ({ guid }: { guid: string }) => {
    if(!guid) guid="00000000-0000-0000-0000-000000000000";
    const svg = useMemo(() => {
        return generateGuidSvg(guid);
    }, [guid]);

    return <div dangerouslySetInnerHTML={{ __html: svg }} />;
};

export default memo(Guider_, (prevProps, nextProps) => {
    
    return prevProps.guid === nextProps.guid;
});