import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { greetApi } from '../app/api/greetApi';
import { RootState, AppThunk } from '../app/store';

export interface IConfigState {
    menuShow : boolean,    
}
const initialState: IConfigState = {
    menuShow : false,    
};

const configSlice = createSlice({
    initialState,
    name: 'config',    
    reducers: {

        openMenu(state) { 
            state.menuShow = true;
        },
        closeMenu(state) {
            state.menuShow=false;
        },
        toggleMenu(state) {            
            state.menuShow = !state.menuShow;
        }

    },
    extraReducers: (builder) => {
    },
  });
  
  export const { toggleMenu, closeMenu, openMenu } = configSlice.actions;
  
  export default configSlice.reducer;