import { ReactElement } from "react";

export type PageHadingProps = {
    title: string;
    subTitle: string | undefined;
    smallSubTitle? : boolean;
    children?: ReactElement<any, any>;
}

const PageHeading = (props: PageHadingProps) => {

    return (<div className="page-heading page-heading--default">
            
            { props.subTitle && (props.smallSubTitle == false || props.smallSubTitle==undefined) && 
            <><div className="page-heading__subtitle h5 color-primary">{props.title}</div><h5 className="page-heading__title h2">{props.subTitle}</h5></> }
            { props.subTitle && (props.smallSubTitle == true) && 
            <div className="page-heading__subtitle h5 color-primary">{props.title}<h5 className="page-heading__title h2" style={{'paddingLeft':'10px', 'display':'inline-block', 'fontSize':'2rem'}}>{props.subTitle}</h5></div> }
            {props.children}
        </div>)

};

export default PageHeading;