import { coinApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCoinGetCoins: build.query<
      GetApiCoinGetCoinsResp,
      GetApiCoinGetCoinsReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/GetCoins`,
        params: { offset: queryArg.offset, limit: queryArg.limit },
      }),
    }),
    getApiCoinGetCoinById: build.query<
      GetApiCoinGetCoinByIdResp,
      GetApiCoinGetCoinByIdReq
    >({
      query: (queryArg) => ({ url: `/api/Coin/GetCoin/${queryArg.id}` }),
    }),
    postApiCoinCreateCoin: build.mutation<
      PostApiCoinCreateCoinResp,
      PostApiCoinCreateCoinReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/CreateCoin`,
        method: "POST",
        body: queryArg.coin,
      }),
    }),
    putApiCoinUpdateCoinById: build.mutation<
      PutApiCoinUpdateCoinByIdResp,
      PutApiCoinUpdateCoinByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/UpdateCoin/${queryArg.id}`,
        method: "PUT",
        body: queryArg.coin,
      }),
    }),
    deleteApiCoinDeactivateCoinById: build.mutation<
      DeleteApiCoinDeactivateCoinByIdResp,
      DeleteApiCoinDeactivateCoinByIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/DeactivateCoin/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    postApiCoinAddTagByIdTags: build.mutation<
      PostApiCoinAddTagByIdTagsResp,
      PostApiCoinAddTagByIdTagsReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/AddTag/${queryArg.id}/tags`,
        method: "POST",
        body: queryArg.tag,
      }),
    }),
    deleteApiCoinRemoveTagByIdTagsAndTagId: build.mutation<
      DeleteApiCoinRemoveTagByIdTagsAndTagIdResp,
      DeleteApiCoinRemoveTagByIdTagsAndTagIdReq
    >({
      query: (queryArg) => ({
        url: `/api/Coin/RemoveTag/${queryArg.id}/tags/${queryArg.tagId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coinApi };
export type GetApiCoinGetCoinsResp =
  /** status 200 Success */ CoinIEnumerableQrRead;
export type GetApiCoinGetCoinsReq = {
  offset?: number;
  limit?: number;
};
export type GetApiCoinGetCoinByIdResp = /** status 200 Success */ CoinQrRead;
export type GetApiCoinGetCoinByIdReq = {
  id: number;
};
export type PostApiCoinCreateCoinResp = /** status 201 Created */ CoinQrRead;
export type PostApiCoinCreateCoinReq = {
  coin: Coin;
};
export type PutApiCoinUpdateCoinByIdResp = /** status 204 No Content */ void;
export type PutApiCoinUpdateCoinByIdReq = {
  id: number;
  coin: Coin;
};
export type DeleteApiCoinDeactivateCoinByIdResp =
  /** status 204 No Content */ void;
export type DeleteApiCoinDeactivateCoinByIdReq = {
  id: number;
};
export type PostApiCoinAddTagByIdTagsResp = /** status 201 Created */ TagQrRead;
export type PostApiCoinAddTagByIdTagsReq = {
  id: number;
  tag: Tag;
};
export type DeleteApiCoinRemoveTagByIdTagsAndTagIdResp =
  /** status 204 No Content */ void;
export type DeleteApiCoinRemoveTagByIdTagsAndTagIdReq = {
  id: number;
  tagId: number;
};
export type CoinIEnumerableQr = {};
export type CoinTag = {
  id?: number;
  coinId?: number;
  tagId?: number;
};
export type Linked = {
  id?: number;
  name?: string | null;
  url?: string | null;
  icon?: string | null;
  order?: number;
  lType?: string | null;
  verified?: boolean;
  verifiedAt?: string | null;
  active?: boolean;
  verificationCode?: string | null;
};
export type DataProperty = {
  id?: number;
  name?: string | null;
  value?: string | null;
};
export type Coin = {
  id?: number;
  name?: string | null;
  symbol?: string | null;
  decimals?: number;
  active?: boolean;
  homepage?: string | null;
  cirSup?: number;
  totalSup?: number;
  marketCap?: number;
  vol24hr?: number;
  maxSup?: number;
  rank?: number;
  tier?: number;
  ath?: number;
  atl?: number;
  color?: string | null;
  notes?: string | null;
  description?: string | null;
  icon?: string | null;
  tags?: CoinTag[] | null;
  linkedList?: Linked[] | null;
  properties?: DataProperty[] | null;
  confirmedSup?: boolean;
  stable?: boolean;
  fIndex?: number;
};
export type CoinIEnumerableQrRead = {
  data?: Coin[] | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type CoinQr = {
  data?: Coin;
};
export type CoinQrRead = {
  data?: Coin;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type Tag = {
  id?: number;
  name?: string | null;
  description?: string | null;
};
export type TagQr = {
  data?: Tag;
};
export type TagQrRead = {
  data?: Tag;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export const {
  useGetApiCoinGetCoinsQuery,
  useLazyGetApiCoinGetCoinsQuery,
  useGetApiCoinGetCoinByIdQuery,
  useLazyGetApiCoinGetCoinByIdQuery,
  usePostApiCoinCreateCoinMutation,
  usePutApiCoinUpdateCoinByIdMutation,
  useDeleteApiCoinDeactivateCoinByIdMutation,
  usePostApiCoinAddTagByIdTagsMutation,
  useDeleteApiCoinRemoveTagByIdTagsAndTagIdMutation,
} = injectedRtkApi;
