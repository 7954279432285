import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { usePostApiPositionClosePositionPartialByIdClosepartialpositionMutation, usePutApiPositionUpdatePositionByIdMutation } from "../../api/positionApi";
import CurrencyView from "../../components/CurrencyView";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";

export const TraderPagePositionPriceModal = ({ isOpen, price, posId, qty, onHide }: { isOpen: boolean; price: number; qty:number, posId: number; onHide: () => void; }) => {

	const [closePFunc, { isSuccess: closePSuc, isError: closePErr, error: closePError }] = usePutApiPositionUpdatePositionByIdMutation();

	const [mdlState, setMdlState] = useState<{ newPrice: number; }>({ newPrice: price });

	const handleCloseInputChange = (value: any, name: any, ...rest: any[]): void => {
		if (value<0 && name === "stopLossPrice") value = 0;
		setMdlState({ ...mdlState, newPrice: value });
	};

	const closePositionNow = (event: any): void => {
		onHide();
	};

	const CloseBut = async (event: any) => {
		const resp = await closePFunc({ id: posId, positionUpd: { leverageMax:0, stopLoss:0, orderQty:0, orderPrice:mdlState.newPrice  } });
	};
	useEffect(() => {

		if (closePSuc) {
			onHide();
		}
		if (closePErr) {
			toast.error("Unable to update position!");
		}
	}, [closePErr, closePSuc]);


	
	return (<>
		<Modal show={isOpen} onHide={() => closePositionNow(null)} centered={true} className="match-stats-widget match-stats-widget--general">
			<Modal.Header closeButton style={{ 'padding': '.5rem', 'border': 0, 'background': '#151720' }}>
				<div className="match-stats-widget__header" style={{ 'color': 'white' }}>
					Set Order Price
				</div>
			</Modal.Header>
			<Modal.Body style={{ 'background': '#151720' }}>
			<div className="table-responsive-sm">
				<table className="table matches-table table-dark table-insite table-order">
					<tbody>
					<tr>
							<td>Quantity: {qty}</td>
							<td style={{ 'textAlign': 'right' }}>
								Order Price: <CurrencyView value={price!} /></td>
						</tr>
						<tr><td colSpan={2}>
							<label className="text-white d-block p-2">Order Price</label>
							<div className="input-group">

								<CurrencyInput
									id="trigger-perc"
									name="closePerc"
									placeholder="trigger perc value"
									className="form-control form-control-sm text-white"
									defaultValue={price}									
									value={mdlState.newPrice}
									style={{'fontSize':'1.125rem', 'textAlign':'center', 'fontWeight':'bold'}}
									decimalsLimit={8}
									prefix="$"
									max={999999}
									onValueChange={handleCloseInputChange} />
								
							</div>					
						</td></tr>
					</tbody>
				</table>
			</div>

			</Modal.Body>
			<Modal.Footer style={{ 'background': '#151720', 'border': 0 }}>
				<Button size="sm" variant="danger" onClick={() => onHide()}>
					Cancel
				</Button>
				<Button size="sm" variant="primary" onClick={CloseBut}>
					Update
				</Button>
			</Modal.Footer>
		</Modal>
	</>);
};
