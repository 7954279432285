import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


interface PreviousUrlContextData {
  previousUrl: string | null;
  lHistory: string[] | null;
  callbackUrl: () => string;
}

const initialContextValue: PreviousUrlContextData = {
  previousUrl: null,  
  lHistory: null,
  callbackUrl : () => ""
};

export const PreviousUrlContext = createContext<PreviousUrlContextData>(initialContextValue);
// Create a provider component
export const PreviousUrlProvider = ({ children }: {children : ReactNode}) => {
  const [previousUrl, setPreviousUrl] = useState<string>("");
  
  const location = useLocation();
  const [lHistory, setlHistory] = useState<Array<string>>([]);  
  const callbackUrl = () =>  {
    if(lHistory.length>1) return lHistory[lHistory.length-2];
    return "/trader";
  }
  useEffect( ()=> {
    setPreviousUrl(location.pathname);
    setlHistory( o =>  [...o , location.pathname ]);      
  },[location]);

// Context value
  const contextValue = {
    previousUrl,
    lHistory,
    callbackUrl
  };

  return (
    <PreviousUrlContext.Provider value={contextValue}>
      {children}
    </PreviousUrlContext.Provider>
  );
};

// Custom hook to access the context
export const usePreviousUrl = () => {
  const context = useContext(PreviousUrlContext);
  if (!context) {
    throw new Error('usePreviousUrl must be used within a PreviousUrlProvider');
  }
  return context;
};
