import { useNavigate, useParams } from "react-router-dom";
import PriceBox from "../components/PriceBox";
import { useEffect, useRef, useState } from "react";
import TradingViewWidget from "../components/TradingViewWidget";
import { useGetApiGreetGetCurrencyQuery } from "../api/greetApi";
import PriceDisplay from "../components/PriceDisplay";
import { useFetch } from "usehooks-ts";
import useBodyClassnames from "../components/useBodyClassnames";

const CurrencyPage = () => {
    const refTradingView = useRef(null);
    const { currency } = useParams();
	const { data, isLoading, isError, isSuccess, error } = useGetApiGreetGetCurrencyQuery({ coin: currency },{
		refetchOnMountOrArgChange:true
	});
	const navigate = useNavigate();
	const [activeButton, setActiveButton] = useState(1);
	const [TWValue, setTWValue] = useState(   ""  );


	useEffect( () => {
		if( (data?.data?.exchangePairs?.length ?? 0) > 0){
			const TWValue = data?.data?.exchangePairs ? data?.data?.exchangePairs[0]?.exchangeUpper || undefined : undefined;
			if(TWValue != undefined)
				setTWValue(TWValue);
		}
	},[data, isSuccess]);

	const makeActiveLi = (buttonId: any) => {
	  setActiveButton(buttonId);
	};

	useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);


    return(
        <main className="site-content player-info-page" id="wrapper">
			{isLoading && <p>loading now...</p>}			
			{!isLoading && 
			<div className="container container--large">
				<div className="team-carousel">
					<div className="team-carousel__content slick-initialized slick-slider slick-dotted slick-vertical">						
                    <div className="slick-list draggable" style={{'overflow':'inherit'}}>
                        <div className="slick-track">
                    	<div className="team-carousel__item slick-slide pt-5 mt-5 pb-5 mb-5" data-icon="stats">
							<div className="row">
								<div className="col-lg-8">									
									<h3 className="player-info-subtitle h4 text-uppercase">
										<a className="px-3" onClick={() => navigate(-1) }>
											<i className="fa-solid fa-circle-left"></i>
										</a>
										{data?.data?.symbol}
										<span className="badge badge-light">#{data?.data?.id}</span></h3>
									<h2 className="player-info-title h1">{data?.data?.name}</h2>                                    
									<div className="row">
										<div className="col-6 col-md-6 col-xl-4">
											<div className="player-info-detail">
												<div className="player-info-detail__label">Price</div>
												<div className="player-info-detail__title">
												<PriceDisplay pre={true} sign="$" value={data?.data?.price ?? 0} />
                                                    
                                                </div>
											</div>
										</div>
										<div className="col-6 col-md-6 col-xl-4">
											<div className="player-info-detail">
												<div className="player-info-detail__label">Change 24Hr</div>
												<div className="player-info-detail__title">{data?.data?.change24Hr?.toFixed(3)}%</div>
											</div>
										</div>
										<div className="col-6 col-md-6 col-xl-4">
											<div className="player-info-detail">
												<div className="player-info-detail__label">ATH</div>
												<div className="player-info-detail__title">{data?.data?.ath}</div>
											</div>
										</div>
										<div className="col-6 col-md-6 col-xl-4">
											<div className="player-info-detail">
												<div className="player-info-detail__label">Market Cap</div>
												<div className="player-info-detail__title"><PriceDisplay pre={true} sign="$" value={(data?.data?.cirSup! * data?.data?.price! )} /></div>
											</div>
										</div>
										<div className="col-6 col-md-6 col-xl-4">
											<div className="player-info-detail">
												<div className="player-info-detail__label">Suppy</div>
												<div className="player-info-detail__title">{data?.data?.cirSup?.toFixed(0)} <br /> {data?.data?.maxSup}</div>
											</div>
										</div>
										<div className="col-6 col-md-6 col-xl-4">
											<div className="player-info-detail">
												<div className="player-info-detail__label">Links</div>
												<ul className="social-menu social-menu--default text-small">
													{
														data?.data?.links?.map( 
															(m)=> <li key={m.id!}>
																		<a href={m.url!} title={m.lType!}  target="_blank">{' '} {m.name!}</a></li>)
													}
													
												</ul>
											</div>
										</div>
									</div>
                                    <div className="row">
                                        <div className="col-12" >
											<div>
												Pairs : <select onChange={e => setTWValue(e.target.value)}>
													{
													data?.data?.exchangePairs?.map( (mp, ind) => 
													{
														return(<option key={ind} value={mp.exchangeUpper!} >{mp.exchangeName} - {mp.pairName}</option>)
													})
													
												}</select>
											</div>
											<div ref={refTradingView} /><TradingViewWidget symbol={TWValue} />
                                        </div>
                                    </div>                                    
									{data?.data?.description}
								</div>
							</div>
						</div>
						
					
                        </div>						
                        </div>
                        
                        
                        <ul className="slick-dots" role="tablist">
                    <li   className={activeButton === 1 ? 'slick-active' : ''}  onClick={e => { makeActiveLi(1); document.getElementsByTagName("body")[0].scrollIntoView(); } } role="presentation">
						<svg role="img" className="df-icon df-icon--lineups">
							<use xlinkHref="/assets/img/necromancers.svg#stats"></use>
						</svg>
					</li>                    
                    <li role="presentation" className={activeButton === 2 ? 'slick-active' : ''} onClick={e => {  makeActiveLi(2); (refTradingView?.current as any).scrollIntoView(); }}>
						<svg role="img" className="df-icon df-icon--hardware">
							<use xlinkHref="/assets/img/necromancers.svg#hardware"></use>
						</svg></li>
                    </ul>


					</div>
				</div>

            
				<div className="team-player">
					<div className="team-player__photo">
						{ data?.data?.icon && 
						<img src={data?.data?.icon} alt="" className="rounded mx-auto d-block" />
						}
					</div>
					<div className="team-player__base">
						
						<div className="ncr-page-decor ncr-page-decor--color-1">
							<div className="ncr-page-decor__layer-1">
								<div className="ncr-page-decor__layer-bg"></div>
							</div>
							<div className="ncr-page-decor__layer-2"></div>
							<div className="ncr-page-decor__layer-3">
								<div className="ncr-page-decor__layer-bg"></div>
							</div>
							<div className="ncr-page-decor__layer-4"></div>
							<div className="ncr-page-decor__layer-5"></div>
						</div>
						
					</div>
				</div>
			</div>
			}
		</main>
    )

}

export default CurrencyPage;