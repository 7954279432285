import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

type ClickHandler = (arg: any) => void;
const DebounceInput = ( { value, onChange, placeHolder, bounce}: { bounce:number, value:string, placeHolder:string, onChange: ClickHandler }) => {
	const [iValue, setIValue] = useState<string>(value);
	useEffect(()=>{
		setIValue(value);
	},[value]);

	const handleChange = useCallback( debounce(onChange, bounce),[onChange]);
	const handleIChange = useCallback((event:any)=> {
		setIValue(event.target.value);
		handleChange(event.target.value);
	},[setIValue, handleChange]);

	return (<input className="form-control text-white form-control-sm" onChange={handleIChange} placeholder={placeHolder} value={iValue}/>);
}

export default DebounceInput;