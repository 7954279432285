import { datatickerApiBase as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiDataTickerAddOrUpdateDataTicker: build.mutation<
      PostApiDataTickerAddOrUpdateDataTickerResp,
      PostApiDataTickerAddOrUpdateDataTickerReq
    >({
      query: (queryArg) => ({
        url: `/api/DataTicker/AddOrUpdateDataTicker`,
        method: "POST",
        params: {
          pairId: queryArg.pairId,
          exchangeId: queryArg.exchangeId,
          price: queryArg.price,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as datatickerApi };
export type PostApiDataTickerAddOrUpdateDataTickerResp =
  /** status 200 Success */ StringQrRead;
export type PostApiDataTickerAddOrUpdateDataTickerReq = {
  pairId?: number;
  exchangeId?: number;
  price?: number;
};
export type StringQr = {};
export type StringQrRead = {
  data?: string | null;
  ok?: boolean;
  status?: number;
  errors?: {
    [key: string]: string[];
  } | null;
};
export const { usePostApiDataTickerAddOrUpdateDataTickerMutation } =
  injectedRtkApi;
