import { useEffect, useState } from "react";
import Decors from "../partials/Decors";

interface PNLOpt {
    average_price : number;
    total_q: number;
    is_long:boolean;
}

const ToolOpenPrice = () => {



    const [t, sett] = useState<PNLOpt>({       
            average_price:0,
            is_long:true,
            total_q: 0              
    });
    const [items, setItems] = useState([{ price: '', quantity: '' }]);
    const handleAddItem = () => {
        setItems([...items, { price: '', quantity: '' }]);
      };
    const handlePriceChange = (event:any, index:number) => {
        const newItems = [...items];
        newItems[index].price = event.target.value;
        setItems(newItems);
      };

    const handleQuantityChange = (event:any, index:number) => {
        const newItems = [...items];
        newItems[index].quantity = event.target.value;
        setItems(newItems);
    };
    

    const handleRemoveItem = (index:number) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
      };
    

    const goodNum = (n:number) =>  {
        if(!n) return "";
       return new Intl.NumberFormat("en-US", {
            signDisplay: "exceptZero",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8
        }).format(n);

    }

    useEffect(()=> {
        const cpt = {...t};
        const total = items.reduce((acc, item) => {  
                if(parseFloat(item.quantity)>0 && parseFloat(item.price)>0) 
                    return acc + (parseFloat(item.quantity)*parseFloat(item.price)); 
                return acc;
            }, 
        0);
        const total2 = items.reduce((acc, item) => {  
                if(parseFloat(item.quantity)>0 && parseFloat(item.price)>0) 
                    return acc + (parseFloat(item.quantity)); 
                return acc;
            }, 
        0);
        const aver = total / total2;
        cpt.average_price = aver;
        cpt.total_q= total2;
        sett(cpt);               
    },[items]);


    return (
        <main className="site-content" id="wrapper">	

			<div className="site-content__inner">
                <div className="site-content__holder">

                    <Decors>                        
                            The "open price" refers to the price of a financial instrument, such as a stock or cryptocurrency, at the beginning of a trading session or a specific time period. For example, in the context of the stock market, the open price is the price at which the first transaction occurred when the market opened for a particular trading day. It is an essential element in financial analysis, often used in conjunction with other price points (such as high, low, and close) to assess the overall performance of an asset during a given time frame. Traders and analysts commonly use open prices to analyze market trends, volatility, and make informed decisions about buying or selling assets.
                    </Decors>

                    <div className="page-content">
                <h4 className="mb-5">Open Price</h4>
                <div className="row ">
                    <div className="col-md-8 col-xs-12">


                        <div className="form-group">

                            <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" defaultChecked 
                             onChange={e => sett({...t, is_long:true } )} 
                            />
                            <label className="btn btn-outline-success" htmlFor="success-outlined">Long</label>

                            <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" 
                            onChange={e => sett({...t, is_long:false } )} 
                            />
                            <label className="btn btn-outline-danger" htmlFor="danger-outlined">Short</label>

                        </div>

                        {items.map((item, index) => (
                            <div key={index} className="form-group">
                                <div className="input-group mb-3">
                                    <label className="col-sm-2 col-form-label" htmlFor="success-outlined">Price: </label>
                                    <input className="form-control" type="text" value={item.price} onChange={(event) => handlePriceChange(event, index)} />
                                    <label className="col-sm-2 col-form-label" htmlFor="success-outlined">Qty: </label>
                                    <input className="form-control" type="text" value={item.quantity} onChange={(event) => handleQuantityChange(event, index)} />
                                    <button className="btn btn-sm btn-outline-danger" onClick={() => handleRemoveItem(index)}>Remove</button>
                                </div>
                            </div>
                        ))}
                        <button className="btn btn-primary" onClick={handleAddItem}>Add</button>

                                            

                    </div>
                    <div className="col-md-4 col-xs-12 mb-4">
                    <h4>Result</h4>
                    <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Average Price ($)</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(t.average_price)} {t.average_price>0 && "$"}</div>
                            <div className="text-warning"  style={{'fontWeight':'normal'}}>Total Amount {t.total_q}</div>
                        </div>                        
                            </div>
                        </div>
                    </div>


        <hr />        

<article>

<h6>Open Long Position:</h6>
<p>
    An open long position in the futures market involves an investor or trader buying a futures contract with the expectation that the price of the underlying asset will increase over time. When you open a long position, you commit to purchasing the underlying asset at the agreed-upon price when the contract matures. Profit is realized if the market price rises above the contract price, while losses occur if the market price falls below it.
</p>
<h6>Open Short Position:</h6>
<p>
    An open short position in the futures market involves an investor or trader selling a futures contract with the expectation that the price of the underlying asset will decrease. When you open a short position, you agree to sell the underlying asset at the contracted price when the contract expires. Profit is made if the market price falls below the contract price, but losses are incurred if the market price rises above it. Short positions are often used for speculation on declining asset prices and for hedging against price drops.
</p>
<h6>Calculating Average Price for Multiple Long Positions:</h6>
<p>
    When you open multiple long positions in a futures contract at different prices, you can calculate your average price to determine your overall cost basis. To calculate the average price, follow these steps:
    <br />
    Calculate Total Cost: For each long position, multiply the quantity of contracts by the entry price to find the total cost of that position.
    <br />
    Sum Total Costs: Add up the total costs of all your long positions.
    <br />
    Sum Total Quantities: Add up the quantities of contracts for all your long positions.
    <br />
    Calculate Average Price: Divide the total cost by the total quantity of contracts. The result is your average price for all your long positions.
</p>
<p>    Here's the formula:
    <br />
    <b>Average Price = Total Cost / Total Quantity</b>
    <br />
    Understanding your average price is essential for managing your risk and making informed decisions regarding when to sell your long positions to maximize profits or minimize losses in the futures market.
</p>

</article>
                  
                </div>
            </div>
        </main>    
        )
}

export default ToolOpenPrice;