// userNickSlice.ts
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userUpdateProfileOpen: false,
};

const userNickSlice = createSlice({
  name: 'userNick',
  initialState,
  reducers: {
    openUserUpdateProfile: (state) => {
        console.warn("setting open.....");
        state.userUpdateProfileOpen = true;
    },
    closeUserUpdateProfile: (state) => {
      state.userUpdateProfileOpen = false;
    },
  },
});

export const { openUserUpdateProfile, closeUserUpdateProfile } = userNickSlice.actions;
export default userNickSlice.reducer;
