import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi, useGetApiAuthRefreshTokenQuery, useLazyGetApiAuthRefreshTokenQuery } from '../../app/api/authApi';
import { IUser, IAuthState } from '../types';

const initialState: IAuthState = {
  user: null,
  userToken:null,
  askForLogin: 0,
  ctr : 0
};

export const userSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {

    askForLogin:(state, action:PayloadAction<any>) => {
      state.askForLogin = action.payload as number;
    },
    tokenReceived: (state, action: PayloadAction<any>) => {
      console.warn("new token received", action.payload);
        state.userToken = action.payload.data;
        localStorage.setItem("cs.tkn", JSON.stringify(state.userToken));
    },
    logout: () => {
      localStorage.setItem("cs.tkn", JSON.stringify(null));
      return initialState
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },    
    changeDisplay: (state, action: PayloadAction<string>) => {
      if(state.user)
        state.user.displayname = action.payload;
      if(state.userToken)
        state.userToken.displayName = action.payload;
    }
  },
  extraReducers: (builder) => {
   
    builder.addMatcher(authApi.endpoints.postApiAuthUpdateProfile.matchFulfilled,  (state, { payload })  =>  {
        console.log("profile updated", state, payload.ok==true);    
        if(payload.ok  === true) state.ctr = state.ctr+1;
    });
    builder.addMatcher(authApi.endpoints.postApiAuthCreateToken.matchFulfilled, (state, { payload }) => {
      console.log("ok",state, payload);
      var tkn = payload.data!;
      state.userToken = tkn;
      state.user = {
        displayname: tkn.displayName!,
        email : tkn.email!,
        username : tkn.userName!,
        web3 : tkn.web3!,
        avatar: '',
        id: tkn.id!
      }
      localStorage.setItem("cs.tkn", JSON.stringify(tkn));
    });
    builder.addMatcher(authApi.endpoints.postApiAuthCreateToken.matchRejected, (state, { payload }) => {
      console.log("rejected",state, payload);
      state.userToken=null;
      state.user=null;
    })
  }
});

export default userSlice.reducer;

export const { logout, setUser , tokenReceived, askForLogin, changeDisplay } = userSlice.actions;

