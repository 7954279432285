import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { greetApi } from '../../app/api/greetApi';
import { RootState, AppThunk } from '../../app/store';

interface IGreetState {
    spotTop:number,
    market:any
}

const initialState: IGreetState = {
    market:null,
    spotTop:100
};

const greetSlice = createSlice({
    initialState,
    name: 'greet',    
    reducers: {
      setSpotTop:(state, param) => {        
        state.spotTop = parseInt(param.payload);
        return state;
      }

    },
    extraReducers: (builder) => {
      builder.addMatcher(
        greetApi.endpoints.getApiGreetGetMarket.matchPending,
        (state) => {
          // set loading state
          
        }
      );
      builder.addMatcher(
        greetApi.endpoints.getApiGreetGetMarket.matchFulfilled,
        (state, action) => {
          // update state with data          
          console.log("response data from market", action.payload);
          state.market = action.payload.data;
          
        }
      );
    },
  });
  
  export const { actions: greetActions } = greetSlice;  
  export default greetSlice.reducer;