import { useEffect, useState } from "react";
import { Position, PositionEx } from "../../api/positionApi";
import { useLatestPrices } from '../trader/trader.page.context';
import PriceDisplay from "../../components/PriceDisplay";
import CurrencyView, { CurrencyViewWithColorStyle } from "../../components/CurrencyView";
import { TraderPagePositionCloseModal } from "./trader.page.position.closemodal";
import { TraderPagePositionStoplossModal } from "./trader.page.position.stoplossmodal";
import { Button, ButtonGroup } from "react-bootstrap";
import { TraderPagePositionTPModal } from "./trader.page.position.tpmodal";
import React from "react";
import PositionTypeBox from "../../components/PositionTypeBox";
import { TraderPagePositionHistoryModal } from "./trader.page.position.historymodal";
import PnlFrame from "../../components/PnlBox/PnlFrame";
import { useMediaQuery  } from 'react-responsive'

const TraderPagePositionsRow = (props: {gotoPair: (exchangeId: string, exchangeName: string, pairId: string, pairName: string) => void, pos: PositionEx; pig : () => void }) => {
	const [tppcm, setTppcm] = useState(false);
	const [tppsm, setTppsm] = useState(false);
	const [tpptp, setTpptp] = useState(false);
	const [tpphm, setTpphm] = useState(false);
	const [tpphn, setTpphn] = useState(false);
	const pos = props.pos;
	const latestPrices = useLatestPrices();

	const k = pos.exchangeId! + ":" + pos.pairId!;
	const lp = latestPrices ? latestPrices[k] : undefined;
	useEffect(()=>{
		props.pig();
	},[tppcm,tppsm,tpptp, pos.id])

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 

	const mobileRender = () => {
		if(isTabletOrMobile)
			return (<>
				<td  className="p-2">
					<table style={{'width':'100%'}} className="table-dark table-sm">
						<tr>
							<td colSpan={2}>
								<div className="p-0" onClick={e => props.gotoPair(pos.exchangeId!.toString(), pos.exchangeName!, pos.pairId!.toString(), pos.pairName!)}>
								{pos.pairName} <br/> <PositionTypeBox typ={pos.type!} /> {pos.leverageMax}x
								{' '}<span>{pos.exchangeName}</span>

								<Button variant="link" size="sm"  onClick={() => setTpphm(true)}>
									<i className="fa-solid fa-clock-rotate-left" style={{'margin': 0}}></i>
								</Button>
								{tpphm && <TraderPagePositionHistoryModal isOpen={tpphm}  price={pos.entryPrice!} posId={pos.id!} 
									qty={pos.qty!}  onHide={() => setTpphm(false)} />}
									</div>
							</td>
						<td colSpan={2} className="text-right">
							<div style={{'display':'table', 'float':'right'}}>
								<div style={{'display':'table-row'}}>
									<div style={{'display':'table-cell', 'padding':'3px'}}><label className="text-muted">UPnl</label></div>
									<div style={{'display':'table-cell', 'padding':'3px'}}><label className="text-muted">Pnl</label></div>
								</div>
								<div style={{'display':'table-row'}}>
									<div style={{'display':'table-cell', 'padding':'3px'}}>
										<CurrencyViewWithColorStyle 
										value={pos.type === 1 ? (pos.qty! * (pos.entryPrice! - lp?.price!)) : (pos.qty! * (lp?.price! - pos.entryPrice!))} />
									</div>
									<div style={{'display':'table-cell', 'padding':'3px'}}><CurrencyViewWithColorStyle value={pos.pnl!}/></div>
								</div>
							</div>
							</td></tr>
						<tr>
							<td className="">
								<label className="text-muted">Size</label><br/>
								<span >{pos.qty?.toFixed(3)}</span>
							</td>
							<td className="text-center">
								<label className="text-muted">Entry Price</label><br/>
								<PriceDisplay pre={false} sign={''} value={pos.entryPrice!} />
							</td>
							<td className="text-center">
								<label className="text-muted">Price</label><br/>
								<PriceDisplay pre={false} sign={''} value={lp?.price!} />
							</td>
							<td className="text-right">
								<label>Liq. Price</label><br/>
								<span className="text-warning"><PriceDisplay pre={false} sign={''} value={lp?.liqPrice!<0 ? 0 : lp?.liqPrice} /></span>
							</td>
						</tr>
						<tr>
							<td colSpan={2}>
							<ButtonGroup className="btn-group btn-group-sm">
								<Button variant="outline-warning" size="sm"  onClick={() => setTppcm(true)}>close</Button>
								<TraderPagePositionCloseModal isOpen={tppcm} price={pos.entryPrice!} posId={pos.id!} qty={pos.qty!} onHide={() => setTppcm(false)} />
								<Button variant="warning"  size="sm"  onClick={() => setTpphn(true) }>PNL {' '}
									<i className="fa-solid fa-up-right-from-square" style={{'fontSize':'10px'}}></i>
								</Button>
								{tpphn && <PnlFrame id={pos.id!} isOpen={tpphn} onHide={() => setTpphn(false)} />}
							
							</ButtonGroup>

							</td>
							<td colSpan={2} className="text-end">							
								<div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'end' }}>
									<label className="text-muted">Take Profit</label>
									{pos.takeProfits?.length == 0 && <div style={{'flexGrow':1}}><b>N/A</b></div>}
									{pos.takeProfits?.length !== 0 && <div style={{'flexGrow':1}}><b>({pos.takeProfits?.length})</b></div>}
									<Button className="p-2" variant="link" size="sm"><i className="fa-solid fa-pen-to-square" style={{ 'fontSize': '12px' }} onClick={() => setTpptp(true)}></i></Button>
									<TraderPagePositionTPModal  isOpen={tpptp} onHide={()=> setTpptp(false)} posId={pos.id!} qty={pos.qty!} price={pos.entryPrice!}  />
								</div>
								<div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'end' }}>
									<label className="text-muted">Stoploss</label>
									<div style={{'flexGrow':1}}>{pos.stopLoss! > 0 ? "$" + pos.stopLoss : "N/A"} </div>
									<Button className="p-2" variant="link" size="sm"><i className="fa-solid fa-pen-to-square" style={{ 'fontSize': '12px' }} onClick={() => setTppsm(true)}></i></Button>
									<TraderPagePositionStoplossModal isOpen={tppsm} onHide={()=>setTppsm(false)} qty={pos.qty!}  posId={pos.id!} price={pos.entryPrice!} />
								</div>
							</td>
						</tr>
					</table>
				</td>
				</>)
	}

	const notMobileRender = () => {
		if(!isTabletOrMobile)
			return (<><td>
			<span onClick={e => props.gotoPair(pos.exchangeId!.toString(), pos.exchangeName!, pos.pairId!.toString(), pos.pairName!)}>
				{pos.pairName} <br/> <PositionTypeBox typ={pos.type!} /> {pos.leverageMax}x
			</span>
			</td>
		<td>{pos.exchangeName}</td>	
		<td className="text-center"><span className="text-muted">{pos.qty?.toFixed(3)}</span></td>
		<td className="text-center"><CurrencyView value={pos.entryPrice! * pos.qty!} /></td>
		<td className="text-center"><PriceDisplay pre={false} sign={''} value={pos.entryPrice!} /></td>
		<td className="text-center"><PriceDisplay pre={false} sign={''} value={lp?.price!} /></td>
		<td className="text-center text-warning"><PriceDisplay pre={false} sign={''} value={lp?.liqPrice!<0 ? 0 : lp?.liqPrice} /></td>
		<td className="text-center"><CurrencyView value={(pos.qty! * pos.entryPrice!) / pos.leverageMax!} /></td>
		<td className="text-center">
			<CurrencyViewWithColorStyle value={pos.type === 1 ? (pos.qty! * (pos.entryPrice! - lp?.price!)) : (pos.qty! * (lp?.price! - pos.entryPrice!))} />
		</td>
		<td className="text-center"><CurrencyViewWithColorStyle value={pos.pnl!}/></td>
		<td className="text-end">
			<div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-evenly' }}>
				<div style={{'flexGrow':1}}>{pos.takeProfits && pos.takeProfits.map(t => {
					return (<div key={t.id!} className="text-nowrap text-end">${t.protfitPrice} - %{t.profitPercentageClosed?.toFixed(2)}</div>);
				})}
				</div>
				{pos.takeProfits?.length == 0 && <span><b>N/A</b></span>}
				<Button variant="link" size="sm"><i className="fa-solid fa-pen-to-square" style={{ 'fontSize': '12px' }} onClick={() => setTpptp(true)}></i></Button>
				<TraderPagePositionTPModal  isOpen={tpptp} onHide={()=> setTpptp(false)} posId={pos.id!} qty={pos.qty!} price={pos.entryPrice!}  />
			</div>
		</td>
		<td className="text-end">
			<div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-evenly' }}>
				<div style={{'flexGrow':1}}>{pos.stopLoss! > 0 ? "$" + pos.stopLoss : "N/A"} </div>
				<Button variant="link" size="sm"><i className="fa-solid fa-pen-to-square" style={{ 'fontSize': '12px' }} onClick={() => setTppsm(true)}></i></Button>
				<TraderPagePositionStoplossModal isOpen={tppsm} onHide={()=>setTppsm(false)} qty={pos.qty!}  posId={pos.id!} price={pos.entryPrice!} />
			</div>
		</td>
		<td>
			<ButtonGroup className="btn-group btn-group-sm">
				<Button variant="warning" size="sm"  onClick={() => setTppcm(true)}>close</Button>
				<TraderPagePositionCloseModal isOpen={tppcm} price={pos.entryPrice!} posId={pos.id!} qty={pos.qty!} onHide={() => setTppcm(false)} />
				<Button variant="secondary" size="sm"  onClick={() => setTpphn(true) }>PNL {' '}
					<i className="fa-solid fa-up-right-from-square" style={{'fontSize':'10px'}}></i>
				</Button>
				{tpphn && <PnlFrame id={pos.id!} isOpen={tpphn} onHide={() => setTpphn(false)} />}
				<Button variant="info" size="sm"  onClick={() => setTpphm(true)}>
					<i className="fa-solid fa-clock-rotate-left" style={{'margin': 0}}></i>
				</Button>
				{tpphm && <TraderPagePositionHistoryModal isOpen={tpphm}  price={pos.entryPrice!} posId={pos.id!} 
					qty={pos.qty!}  onHide={() => setTpphm(false)} />}
			</ButtonGroup>
		</td></>)
	}

	return (<tr key={pos.id!}>
		{notMobileRender()}
		{mobileRender()}
	</tr>);
};
const MemoTraderPagePositionsRow = React.memo(TraderPagePositionsRow);
export { TraderPagePositionsRow, MemoTraderPagePositionsRow };