import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CurrencyInfo, useGetApiGreetGetCurrenciesQuery } from "../api/greetApi";
import SelectFx from "../components/SelectFx/SelectFx";
import PriceDisplay from "../components/PriceDisplay";
import CurrencyView from "../components/CurrencyView";
import { debounce } from 'lodash';
import { useFetch } from "usehooks-ts";
import { Link } from "react-router-dom";
import DebounceInput from "../components/DebounceInput";
import { useAppDispatch, useAppSelector } from "../store";
import { greetActions } from "../../features/greet/greetSlice";
import SelectFxWithState from "../components/SelectFxWithState";
import useBodyClassnames from "../components/useBodyClassnames";
import PageHeading from "../components/pageHeading/PageHeading";
const CurrenciesPage = () => {

	const spotTop  =  useAppSelector(state => state.greet.spotTop);
	const [searchTerm, setSearchTerm] = useState("");

	const [sortField, setSortField] = useState("id"); //
	const [sortDir, setSortDir] = useState(true); //trıu asc
		
	const { data, isLoading, isError, isSuccess, error } = useGetApiGreetGetCurrenciesQuery({ most: spotTop}, 
		{
			pollingInterval:15000,
			refetchOnMountOrArgChange:true
		  });

	useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active"]);

	useEffect( () => {

		

	},[isError]);

    return(
        <main className="site-content site-content--center page" id="wrapper">
            <div className="container container--large">
                
			<PageHeading title="Coin" subTitle="Rankings" smallSubTitle={true}  />
				
                <div className="matches-filter">
                    <label className="matches-filter__label">Ranking</label>
					<SelectFxWithState />
                </div>

				<div className="row">
					<div className="col-sm text-start">
						<div className="form-group" style={{'background':'#13151e'}}>
							<label className="control-label" htmlFor="input-default">Search</label>							
							<DebounceInput onChange={e =>  setSearchTerm(e)} placeHolder="search" value={searchTerm} bounce={200} key={'a'} />
						</div>
						</div>
					<div className="col-sm text-end">
						<button className="btn btn-sm btn-secondary position-relative">Data provided by Exchanges and CoinGecko</button>
					</div>
				</div>

			{isLoading && <div className="alert alert-info" role="alert">Loading now...</div>}
			{false && isSuccess && <div className="alert alert-success" role="alert">refresh automatically...</div>}
			{isError && <div className="alert alert-danger d-flex align-items-center" role="alert">Something went wrong, please try again later!</div>}
			<div className="table-responsive mb-sm-auto">
			<table className="table matches-table standings-table">
				<thead>
					<tr>
						<th onClick={e => { if(sortField!="id") setSortField('id'); else setSortDir(!sortDir);   }   }>Rank</th>
						<th onClick={e => { if(sortField!="name") setSortField('name'); else setSortDir(!sortDir);   }}>Name</th>
						<th onClick={e => { if(sortField!="price") setSortField('price'); else setSortDir(!sortDir);   } }>Price</th>
						<th onClick={e => { if(sortField!="change24Hr") setSortField('change24Hr'); else setSortDir(!sortDir);   } }>24h %</th>
						<th>Market Cap</th>
						<th>Total Supply</th>
						<th>Circulating Supply</th>						
					</tr>
				</thead>
				<tbody>
					{data && (data as any).data.filter( (t:CurrencyInfo) => 
						(t.symbol && t.symbol.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())>-1) ||
						(t.name && t.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())>-1) || searchTerm==="")
						.sort( (a:any, b:any ) => 
								sortField=="name" ?  sortDir ?  a[sortField].localeCompare(b[sortField]) :  b[sortField].localeCompare(a[sortField]) :
								sortDir ? a[sortField] - b[sortField] : b[sortField] - a[sortField]  )
						.map( (cur: CurrencyInfo) => ( 
							<tr key={cur.name}>
								<td>{cur.id}</td>
								<td><Link to={`/currencies/detail/${cur.symbol}`}>
									<figure className="match-team" role="group">
										<figure className="match-team-logo match-team-logo--team-4" role="group">
											<img 
											src={cur.icon ? cur.icon : ""} 
											alt="" />
										</figure>
										<figcaption>
											<div className="match-team__name">{cur.name}</div>
												<div className="match-team__country ">{cur.symbol}</div>
										</figcaption>
									</figure></Link>
										 </td>
								<td><PriceDisplay pre={true} sign={'$'} value={cur.price} /></td>
								<td className={(cur.change24Hr && cur.change24Hr > 0 ? 'standings-table__wins' : 'standings-table__losses')}>
									{cur.change24Hr && cur.change24Hr.toFixed(2)}%</td>
								<td>{cur.price != undefined && cur.cirSup!= undefined ? <CurrencyView value={cur.price * cur.cirSup} /> : <span/>}</td>
								<td>{cur.maxSup != undefined  && cur.maxSup >0 && cur.symbol} {cur.maxSup != undefined  && cur.maxSup >0 && cur.maxSup.toFixed(0)}</td>
								<td>{cur.symbol} {cur.cirSup && cur.cirSup.toFixed(0)}</td>
							</tr>
					))}

				</tbody>
			</table>
		</div>


            </div>
        </main>
    )

}


export default CurrenciesPage;