import { useEffect, useRef, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { NewOrderModel, usePostApiPositionNewOrderMutation } from "../../api/positionApi";
import { toast } from "react-toastify";
import { ITraderPageOrderBox } from "./interfaces/ITraderPage";
import { useGetApiGreetGetMarketPriceQuery } from "../../api/greetApi";
import { useAppDispatch, useAppSelector } from "../../store";
import useWindowTitle from "../../hooks/useWindowTitle";
import { traderActions } from "../../../features/trader/traderSlice";
const TraderPageOrderBox = (props: {channelId: string | undefined, exchangeName:string, pairName:string, exchangeId: string,  pairId: string}) => {
	const userAccessTokenExpiration = useAppSelector(p=>p.auth?.userToken?.accessTokenExpiration);
	 
	const dispatch = useAppDispatch();

    const [pageState, setPageState] = useState<ITraderPageOrderBox>({ 
		
		leverage:10,		
		orderType: 'MARKET',
		iOrderPrice:'',
		iOrderValue:'',
		iStoploss:'',		
		tpList:[],
		tpNew: { id:undefined,closePerc:undefined, closePrice:undefined},
	});

    const pricFetchInterval = useRef<NodeJS.Timer>();
	const { refetch:gr_refetch, data :gr_data, isLoading: gr_isloading, isError: gr_iserror, isSuccess: gr_issuccess, error: gr_error, isFetching: gr_isfetching  } 
    = useGetApiGreetGetMarketPriceQuery({ exchangeName: props.exchangeName, pairName:props.pairName  }, 
        { skip: props.pairName=='' || !userAccessTokenExpiration }) 
    

	
	useWindowTitle(`${gr_data?.data?.dValue?.toString()} ▲ ▼ ${props.pairName}`);
    
	useEffect(()=> {
		/*
		if(pageState.orderType=='LIMIT') {
			console.log("clearing latest price check...")
			clearInterval(pricFetchInterval.current);
			pricFetchInterval.current = undefined;
		}
		else if(pageState.orderType=='MARKET') {
			console.log("creating latest price check...", props.pairName);
			if(props.pairName!='' && props.pairName!=null) {
                console.log("setting price fetch interval current...");

				pricFetchInterval.current = setInterval(() => {
					if(userAccessTokenExpiration)
                    	gr_refetch();
                    
                }, 1000);
            }
		}*/

		if(props.pairName!='' && props.pairName!=null) {
			console.log("setting price fetch interval current...");

			pricFetchInterval.current = setInterval(() => {
				if(userAccessTokenExpiration)
					gr_refetch();
				
			}, 1000);
		}

		return () => { console.log("***clearing***"); clearInterval(pricFetchInterval.current); }
	},[pageState.orderType, props.exchangeName, props.pairName])

	useEffect(()=>{
		if(pageState.orderType=='MARKET')
			setPageState({...pageState, iOrderPrice: gr_data?.data?.dValue?.toString()});
		
			
	},[gr_data])





	const removeTPItem = (index: number) => {
		const updatedTpList = [...pageState.tpList];
		updatedTpList.splice(index, 1);
		setPageState({ ...pageState, tpList: updatedTpList });
	};

	const addTPItem = () => {
		if (pageState.tpNew.closePerc != undefined && pageState.tpNew.closePrice != undefined) {
			if(parseFloat(pageState.tpNew.closePerc)>0 && parseFloat(pageState.tpNew.closePrice)>0) {
				setPageState({
				...pageState,
				tpList: [...pageState.tpList, pageState.tpNew],
				tpNew: { id:undefined,closePerc: undefined, closePrice: undefined }
				});
			}
		}
	};
		
	const handleTPInputChange = (value:any, name:any, ...rest:any[]) => {
		console.log("change", name, value, rest);
		if(name==undefined) return ;
		if(value > 1000 && name==="closePerc") value=1000;
		setPageState({
			...pageState,
			tpNew: {
			...pageState.tpNew,
			[name]: value
			}
		});
	};


    const [ NewOrderPost, { isError: neworder_iserror, error: neworder_error, isLoading: neworder_isloading }] = usePostApiPositionNewOrderMutation();
    const  NewOrder = async (orderType:string) => {

		if(!userAccessTokenExpiration) {
			toast.error(   'Please login to create new order.', { autoClose:500,   } );
			return
		}

		if(!props.exchangeId) {
			toast.error('Please select Exchange and Pair first!');
			return;
		}
		if(!props.pairId) {
			toast.error('Please select Exchange and Pair first!');
			return;
		}

		const nwo : NewOrderModel = {
			exchangeId: parseInt(props.exchangeId), 
			isMarket: pageState.orderType == "MARKET",
			leverage: pageState.leverage,
			pairId: parseInt(props.pairId),
			positionSide: orderType =="LONG" ? 0 : 1,
			price: pageState.iOrderPrice ? parseFloat(pageState.iOrderPrice) : undefined,
			quantity: (((parseFloat(pageState.iOrderValue??"")) / parseFloat(pageState.iOrderPrice??""))||0),
			stoploss: pageState.iStoploss ? parseFloat(pageState.iStoploss) : undefined,
			takeProfits: pageState.tpList.map( c  => {  return { price: parseFloat(c.closePrice!), perc: parseFloat(c.closePerc!) }  }  ),
			channelId: props.channelId ? parseInt(props.channelId) : undefined
		}

		if(nwo.quantity == 0) 
		{			
			toast.error('Quantity can not be zero!');
			return;
		}
		if(nwo.price == 0 && nwo.isMarket==true) {
			toast.error('Price can not be zero!');
			return;
		}

		
		try { 
			const  resp = await NewOrderPost({newOrderModel: nwo}).unwrap();	
			if(resp.ok) {
				
				toast.success("Order created "+resp.data?.id);
				setPageState({...pageState, iOrderPrice: undefined, iOrderValue: undefined, iStoploss: undefined });
				if(nwo.isMarket)
					dispatch(traderActions.positionCreated());
				else {
					dispatch(traderActions.positionCreated());
					dispatch(traderActions.orderCreated());
				}
			} 
		}
		catch(ex :any){
			
			console.warn(ex.data.errors);
			
			ex.data.errors["$"].map((a:any,b:any) => {
					toast.error(a);
			});

		}

	}
    const getButton1Classes = (s:string) :string  => 
	{
		if(pageState.orderType===s)
			return `btn btn-sm btn-outline-warning active`;
		else return `btn btn-sm btn-outline-warning`;
	}

    const [ isOpen, setIsOpen] = useState(false);

	const openTPModal = () => setIsOpen(true);
	const closeTPModal = () => setIsOpen(false);


	const ModalWinTP = () => {
		return(<>			
			<Modal show={isOpen} centered={true} onHide={closeTPModal} className="match-stats-widget match-stats-widget--general" >
			  <Modal.Header  closeButton style={{'padding':'.5rem', 'border':0, 'background':'#151720'}}>
				<div className="match-stats-widget__header" style={{'color':'white'}}>
					Add Take Profit Levels
					</div>
			  </Modal.Header>
			  <Modal.Body style={{ 'background':'#151720'}}>	
			  	<div className="table-responsive-sm">
					<table className="table matches-table table-dark table-insite table-order">
					<tbody>
					<tr>
						<td>Quantity: {(((parseFloat(pageState.iOrderValue??"")) / parseFloat(pageState.iOrderPrice??""))||0).toFixed(11)}</td>
						<td style={{'textAlign':'right'}}>Order Price: ${pageState.iOrderPrice}</td>
					</tr>
					<tr><td colSpan={2}>
					<table className="match-overview__table w-100">
						<thead>
							<tr>
								<th>Close Percentage</th>
								<th>Close Price</th>
							</tr>
						</thead>
						<tbody>
						{pageState.tpList.map((item, index) => (
						<tr key={index}>
							<td style={{'paddingLeft':'1rem'}}>%{item.closePerc}</td>
							<td style={{'paddingLeft':'1rem'}}>${item.closePrice}</td>
							<td>
								<button className="btn btn-sm btn-danger" onClick={() => removeTPItem(index)}>Remove</button>
							</td>
						</tr>						
						))}
						</tbody>
					</table>
					</td></tr>

					<tr><td>				
					<label className="text-white d-block p-2">Take Profit - Trigger Price</label>
					<div className="input-group">

					<CurrencyInput
					id="trigger-price"
					name="closePrice"
					placeholder="trigger value"
					className="form-control form-control-sm text-white"
					defaultValue={0}
					value={pageState.tpNew.closePrice}
					decimalsLimit={8}
					prefix="$"
					onValueChange={handleTPInputChange}
					/>
					<span className="input-group-text" id="basic-addon2">$</span>
					</div>								
					<input type="range" className="form-range" 
					onChange={e => 
					{ 
						if(pageState.iOrderPrice && parseFloat(pageState.iOrderPrice)>0.0) {
							handleTPInputChange( ( parseFloat(pageState.iOrderPrice) * parseFloat(e.target.value)) / 100.0, 'closePrice');
						}
					}
				 } min="0" max="500" step="1" id="customRange3"></input>
					</td><td>
					<label className="text-white d-block p-2">Close Percentage</label>
					<div className="input-group">

					<CurrencyInput
					id="trigger-perc"
					name="closePerc"
					placeholder="trigger perc value"
					className="form-control form-control-sm text-white"
					defaultValue={0}
					max={1000}
					value={pageState.tpNew.closePerc}
					decimalsLimit={2}
					
					onValueChange={handleTPInputChange}
					/>
					<span className="input-group-text" id="basic-addon2">%</span>
					</div>									
					<input type="range" className="form-range" value={pageState.tpNew.closePerc||0} 
					onChange={e => handleTPInputChange(e.target.value, 'closePerc') } min="0" max="1000" step="0.1" id="customRange3"></input>
			
					</td></tr>	
			
			<tr><td colSpan={2}>	<Button size="sm" variant="warning" onClick={e => addTPItem() }>Add </Button></td></tr>

				</tbody>
					</table>
				</div>				
					
			  </Modal.Body>
			  <Modal.Footer style={{'background':'#151720', 'border':0}}>				
				<Button size="sm" variant="secondary" onClick={closeTPModal}>
				  Close
				</Button>
			  </Modal.Footer>
			</Modal>
		  </>)
	}

    
    return (<>
    {ModalWinTP()}
    <table style={{'backgroundColor':'#212529'}} className="table matches-table table-dark table-insite table-order table-responsive-sm">
    <tbody>
    <tr style={{'display':'none'}}>
            <td style={{'textAlign':'center', 'color':'rgb(255, 204, 0)', 'borderBottom':'1px solid rgb(255, 204, 0)'}}>
                Oneway Mode
            </td>
            <td style={{'textAlign':'center', 'color':'lightgray'}}>
                Hedge Mode
            </td>
        </tr>
    
        <tr>
            <td style={{'textAlign':'center', 'color':'rgb(255, 204, 0)', 'borderBottom':'1px solid rgb(255, 204, 0)'}}>
                Open
            </td>
            <td style={{'textAlign':'center', 'color':'lightgray'}}>
                Hedge Mode
            </td>
        </tr>
    
        <tr>
            <td colSpan={2}  >
                <div className="d-flex justify-content-evenly">
                    <button  className={getButton1Classes('LIMIT')} onClick={e => setPageState({...pageState, orderType:'LIMIT' }) } >Limit</button>
                    
                    <button className={getButton1Classes('MARKET')} onClick={e => setPageState({...pageState, orderType:'MARKET' }) }>Market</button>
                </div>
            </td>
        </tr>
        {  
        <><tr>
            <td colSpan={2}>
                <label className="text-white d-block p-1">Order Price</label>
                <div className="input-group">
                <CurrencyInput
                    id="order-price"
                    name="order-price"
                    placeholder="order price"
                    readOnly={pageState.orderType == 'MARKET'}
                    className="form-control form-control-sm text-white"
                    defaultValue={0}
                    value={pageState.iOrderPrice}
                    decimalsLimit={8} prefix="$"
                    onValueChange={(value, name) =>  setPageState({...pageState, iOrderPrice: value }  ) }
                    />
                     
                    <span className="input-group-text" id="basic-addon2">$</span>
                </div>									
            </td>
        </tr>
        <tr></tr>
        </>}
        <tr>
            <td colSpan={2}>
                <label className="text-white d-block p-1">Order By Value</label>
                    <div className="input-group">

                    <CurrencyInput
                    id="order-price"
                    name="order-price"
                    placeholder="order value"
                    className="form-control form-control-sm text-white"
                    defaultValue={0}
                    value={pageState.iOrderValue}
                    decimalsLimit={8}
                    prefix="$"
                    onValueChange={(value, name) =>  setPageState({...pageState, iOrderValue: value }  ) }
                    />
                    <span className="input-group-text" id="basic-addon2">$</span>
                </div>		
            </td>
        </tr>
        <tr>
            <td colSpan={2}>
                <div className="d-flex" style={{'justifyContent': 'space-between', 'alignItems':'center'}}>
                    <span onClick={(e)=> setPageState({...pageState, iOrderValue:'100'}) }>10%</span>	
                    <span onClick={(e)=> setPageState({...pageState, iOrderValue:'250'}) }>25%</span>
                    <span onClick={(e)=> setPageState({...pageState, iOrderValue:'500'}) }>50%</span>
                    <span onClick={(e)=> setPageState({...pageState, iOrderValue:'750'}) }>75%</span>
                    <span onClick={(e)=> setPageState({...pageState, iOrderValue:'1000'}) }>100%</span>
                </div>
            </td>
        </tr>
        <tr>
            <td >
            <label className="text-muted d-block d-flex justify-content-evenly ">
                <span>Qty:</span><span className="text-success">{  (((parseFloat(pageState.iOrderValue??"")) / parseFloat(pageState.iOrderPrice??""))||0).toFixed(8) }</span></label> 
            </td>
        
            <td >
                <label className="text-muted d-block d-flex justify-content-evenly">
                    <span>Cost:</span><span className="text-success">{ ((parseFloat(pageState.iOrderValue??"") / pageState.leverage)||0).toFixed(3) } $</span></label> 
            </td>
        </tr>
        
        <tr>
            <td colSpan={2}>

            

                <label className="text-white d-block p-2">Stoploss</label>
                    <div className="input-group">

                    <CurrencyInput
                    id="stoploss-price"
                    name="stoploss-price"
                    placeholder="stoploss value"
                    className="form-control form-control-sm text-white"
                    defaultValue={0}
                    value={pageState.iStoploss}
                    decimalsLimit={8}
                    prefix="$"
                    onValueChange={(value, name) =>  setPageState({...pageState, iStoploss: value }  ) }
                    />
                    <span className="input-group-text" id="basic-addon2">$</span>
                </div>		
            </td>
        </tr>
        <tr>
            <td colSpan={2}>
            
            <div className="d-flex justify-content-evenly ">
                <button onClick={ (e)=> NewOrder('LONG')} className="btn btn-sm btn-success">Buy / Long</button> 
                <button title="Leverage" className="btn btn-sm btn-secondary">{pageState.leverage}X</button>		
                <button title="Take Profits" className="btn btn-sm btn-info" onClick={openTPModal}>TP
				{ pageState.tpList.length>0 && ' ('+pageState.tpList.length.toString()+') ' }
				</button>							
                <button onClick={ (e)=> NewOrder('SHORT')} className="btn btn-sm btn-danger">Sell / Short</button></div>
            </td>
        </tr>
        </tbody>
    </table></>)
};

export default TraderPageOrderBox;