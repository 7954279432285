import { ReactNode, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { Position, PositionEx, useLazyGetApiPositionGetPositionsOfQuery } from "../../api/positionApi";
import { toast } from "react-toastify";
import CurrencyView, { CurrencyViewWithColorStyle } from "../../components/CurrencyView";
import PositionTypeBox from "../../components/PositionTypeBox";
import { useAppSelector } from "../../store";
import PnlFrame from "../../components/PnlBox/PnlFrame";
import React from "react";
import TimeAgo from 'react-timeago'
import { TraderPagePositionHistoryModal } from "./trader.page.position.historymodal";
import { useMediaQuery } from "react-responsive";

const TraderPageClosedRow = ( {pos} : { pos: PositionEx }) => {
	const [tpphm, setTpphm] = useState(false);
	const [tpph1m, setTpph1m] = useState(false);
	const [tpphsm, setTpphsm] = useState(false);

	const user_id = useAppSelector(p => p.auth.user?.id);

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 


	
	const mobileRender = () => {
		if(isTabletOrMobile)
			return (<>
			<table style={{'width':'100%'}} className="table-dark table-sm">

				<tr>
				<td colSpan={2}><div className="p-2 pb-0">{pos.pairName} <br /><PositionTypeBox typ={pos.type!} /> {pos.leverageMax}x 
				{' '}{pos.exchangeName}
					<Button variant="link" size="sm"  onClick={() => setTpph1m(true)}>
						<i className="fa-solid fa-clock-rotate-left" style={{'margin': 0}}></i>
					</Button>
					{tpph1m && <TraderPagePositionHistoryModal isOpen={tpph1m}  price={pos.entryPrice!} posId={pos.id!} 
									qty={pos.qty!}  onHide={() => setTpph1m(false)} />}
				</div></td>
				<td className="p-2 text-end" colSpan={2}>
				<div style={{'display':'table', 'float':'right'}}>
				<div style={{'display':'table-row'}}>
									<div style={{'display':'table-cell', 'padding':'3px'}}><label className="text-muted">Pnl</label></div>
									<div style={{'display':'table-cell', 'padding':'3px'}}><label className="text-muted"><CurrencyViewWithColorStyle value={pos.pnl!}/></label></div>
								</div>
					</div>
					
				</td>
				</tr>
				<tr>
				<td className="text-start p-2" style={{'width':'30%'}}>
					<div><label className="text-muted">Amount</label></div>
					<span>{pos.orderQty?.toFixed(5)}</span>
				</td>
				<td className="text-center" style={{'width':'30%'}}>
					<div><label className="text-muted">Price</label></div>
					<CurrencyView value={pos.entryPrice!}/>
				</td>
				<td></td>
				<td className="text-center" style={{'width':'99px'}}>
				{ user_id == pos.ownerId && <ButtonGroup className="btn-group btn-group-sm">
							<Button variant="secondary" size="sm"  onClick={() => setTpphm(true) }>PNL{' '}
								<i className="fa-solid fa-up-right-from-square" style={{'fontSize':'10px'}}></i>
							</Button>
							{tpphm && <PnlFrame id={pos.id!} isOpen={tpphm} onHide={() => setTpphm(false)} />}
						</ButtonGroup> }
					</td>
				</tr>
			</table>
			

			</>)
	}

	
	const notMobileRender = () => {
		if(!isTabletOrMobile)
			return (<><td>{pos.pairName} <br /><PositionTypeBox typ={pos.type!} /> {pos.leverageMax}x</td>
				<td>{pos.exchangeName}</td>
									
				<td className="text-center"><span className="text-muted">{pos.orderQty?.toFixed(5)}</span></td>
				<td className="text-center"><CurrencyView value={pos.entryPrice!}/></td>
				<td className="text-center"><CurrencyView value={pos.exitPrice!}/></td>
				
				<td className="text-center"><CurrencyViewWithColorStyle value={pos.pnl!}/></td>
				<td className="text-center"><TimeAgo date={pos.createdAt!} /></td>
				<td className="text-center"><TimeAgo date={pos.completedAt!} /></td>
				<td>{ user_id == pos.ownerId && 
					<ButtonGroup className="btn-group btn-group-sm">
					<Button variant="secondary" size="sm"  onClick={() => setTpphm(true) }>PNL{' '}
						<i className="fa-solid fa-up-right-from-square" style={{'fontSize':'10px'}}></i>
					</Button>
					{tpphm && <PnlFrame id={pos.id!} isOpen={tpphm} onHide={() => setTpphm(false)} />}
				
					<Button variant="info" size="sm"  onClick={() => setTpphsm(true)}>	
						<i className="fa-solid fa-clock-rotate-left" style={{'margin': 0}}></i>
					</Button>
					{tpphsm && 
						<TraderPagePositionHistoryModal isOpen={tpphsm}  
						price={pos.entryPrice!} posId={pos.id!} qty={pos.qty!}  onHide={() => setTpphsm(false)} />
					}
				</ButtonGroup>
				}
				</td></>)
	}

	return (<tr>
		{mobileRender()}
		{notMobileRender()}
	</tr>)
}

const MemoTraderPageClosedRow = React.memo(TraderPageClosedRow);
export { TraderPageClosedRow, MemoTraderPageClosedRow };