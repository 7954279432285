import { useEffect, useState } from "react";


export interface ISelectFxProps  {    
    placeHolder?: string,
    options: Array<ISelectOpt>,
    selected?: string | null    
    onChange?: (selectedValue: string | null) => void
};

export interface ISelectOpt {
    value: string,
    text: string
}

const SelectFx = (props: ISelectFxProps) => {

    const [selected,setSelected] = useState<string|null>(null);
    const [active, setActive] = useState(false);
    
    useEffect(()=>{

        if(props.selected) {
            setSelected(props.selected);
        }

    },[])

    const toggleSelect = () => {
        setActive(!active);
    }

    const s = (opt: ISelectOpt) => {
        setSelected(opt.value);   
        setActive(false);
    }

    useEffect( () => {

        if(props.onChange)
            props.onChange(selected)

    },[selected]);

    const class1 = "cs-select "+(active ? "cs-active" : "");

    let selectedText = "";
    if(selected != null) {
        selectedText = props.options.filter( c => c.value === selected)[0].text;
    }


    return(<div className={class1}>
        <span className="cs-placeholder" onClick={toggleSelect} >{ selected ? selectedText :  props.placeHolder??"placeholder"}</span>
        <div className="cs-options">
            <ul>
                {props.options.map( opt => {
                    return(<li className={ selected===opt.value ? "cs-selected" : ""  } key={opt.value}><span onClick={e => s(opt)} >{opt.text}</span></li>)
                })}                
            </ul>
        </div>        
    </div>);



}

export default SelectFx;