import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup,  FormControl } from 'react-bootstrap';
import { ChannelInviteModel, usePostApiChannelInviteMutation } from '../../api/channelApi';
import { FormLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import useApiErrorHandling, { ApiResponseError } from '../../hooks/useApiErrorHandling';

export interface ChannelInviteModalProps {
    show: boolean;
    onHide: () => void;
    inviteType: "fromUser" | "fromOwner";
    channelId: number;
    inviting: 'user' | 'trader'
  }
  

const TraderChannelPageInviteModal = ({ show, onHide, inviteType, inviting, channelId }: ChannelInviteModalProps) => {
  const { handleApiError } = useApiErrorHandling(); // Use the hook
    const [formData, setFormData] = useState<ChannelInviteModel>({
     
    });

    const [createChannelInvite, { isLoading, isError, isSuccess, data }] = usePostApiChannelInviteMutation();
  
    const handleClose = () => {
      onHide();
      setFormData({
        // Reset the form data when the modal is closed
      });
    };

    useEffect(()=> {

      if(data) {
        console.warn("createChannelInvite",isError, isSuccess)

        if(data?.ok == true) 
          toast.success("Invitation has been sent.");
        else toast.error("There is an error occured while sending invitation");
      }
    },[isError, isSuccess]);
  
    useEffect(()=> {
      if(show) {
        setFormData({ 
          toChannelId : channelId, 
          inivteAsTrader: inviting === 'trader' ? true : false
        })
      }
    },[show]);
  
    const handleFormSubmit = async ()  =>  {
      
      if(formData.inivteAsTrader == undefined || formData.subExpireDaysAfter! < 1 || formData.toChannelId! < 1 || formData.toUserId == null) 
      {

        toast.error("Please check form for required fields.")
        return;
      }
      console.warn("handleFormSubmit")
      const r = await createChannelInvite({ channelInviteModel: formData });
      if('error' in r) 
        if('data' in r.error)          
          handleApiError(r.error.data as any);
      else {
        handleClose();
        toast.success("Invitation sent.");
        
      }
    };
  
    return (
      <>
     
        <Modal show={show} onHide={onHide} size='lg' centered={true} scrollable={true}>
          <Modal.Header closeButton>
            <Modal.Title>Invite { 'trader' === inviting ? 'Trader' : 'Member' }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* Render different form fields based on the invite type (prop) */}
              {inviteType === 'fromOwner' && (
                <>
                  <FormGroup>
                    <FormLabel>Invitation Valid Until (Number of Days)</FormLabel>
                    <FormControl
                      type="text"
                      required={true}
                      defaultValue={10}
                      value={formData.subExpireDaysAfter!}
                      onChange={(e) =>
                        setFormData({ ...formData, subExpireDaysAfter: parseInt(e.target.value) })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>To User ID or Email</FormLabel>
                    <FormControl
                      type="text"
                      required={true}
                      value={formData.toUserId!}
                      onChange={(e) => setFormData({ ...formData, toUserId: e.target.value })}
                    />
                  </FormGroup>                 
                </>
              )}
              {inviteType === 'fromUser' && (
                <>
                  <FormGroup>
                    <FormLabel>From User ID or Email</FormLabel>
                    <FormControl
                      type="text"
                      required={true}
                      value={formData.toUserId!}
                      onChange={(e) => setFormData({ ...formData, toUserId: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Invite As Trader</FormLabel>
                    <Form.Check
                      type="checkbox"
                      value={formData.inivteAsTrader===true ? "1":"0"}
                      checked={formData.inivteAsTrader}
                      onChange={(e) =>
                        setFormData({ ...formData, inivteAsTrader: e.target.checked })
                      }
                    />
                  </FormGroup>
                </>
              )}
              <FormGroup>
                <FormLabel>Channel ID</FormLabel>
                <h3>0x{channelId}</h3>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFormSubmit}>
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  
  export default TraderChannelPageInviteModal;
  