import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { usePostApiPositionClosePositionPartialByIdClosepartialpositionMutation } from "../../api/positionApi";
import CurrencyView from "../../components/CurrencyView";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";

export const TraderPagePositionCloseModal = ({ isOpen, qty, price, posId, onHide }: { isOpen: boolean; price: number; posId: number; qty: number; onHide: () => void; }) => {

	const [closePFunc, { isSuccess: closePSuc, isError: closePErr, error: closePError }] = usePostApiPositionClosePositionPartialByIdClosepartialpositionMutation();

	const [mdlState, setMdlState] = useState<{ closePerc: number; }>({ closePerc: 0 });

	const handleCloseInputChange = (value: any, name: any, ...rest: any[]): void => {
		if (value > 100 && name === "closePerc") value = 100;
		setMdlState({ ...mdlState, closePerc: value });
	};

	const closePositionNow = (event: any): void => {
		onHide();
	};

	const CloseBut = async (event: any) => {
		const resp = await closePFunc({ id: posId, perc: mdlState.closePerc });
	};
	useEffect(() => {

		if (closePSuc) {
			onHide();
		}
		if (closePErr) {
			toast.error("Unable to close position!");
		}
	}, [closePErr, closePSuc]);


	
	return (<>
		<Modal show={isOpen} onHide={() => closePositionNow(null)}  centered={true} className="match-stats-widget match-stats-widget--general">
			<Modal.Header closeButton style={{ 'padding': '.5rem', 'border': 0, 'background': '#151720' }}>
				<div className="match-stats-widget__header" style={{ 'color': 'white' }}>
					Close Position
				</div>
			</Modal.Header>
			<Modal.Body style={{ 'background': '#151720' }}>
			<div className="table-responsive-sm">
				<table className="table matches-table table-dark table-insite table-order">
					<tbody>
						<tr>
							<td>Quantity: {qty}</td>
							<td style={{ 'textAlign': 'right' }}>
								Order Price: <CurrencyView value={price!} /></td>
						</tr>
						<tr><td colSpan={2}>
							<label className="text-white d-block p-2">Close Percentage</label>
							<div className="input-group">

								<CurrencyInput
									id="trigger-perc"
									name="closePerc"
									placeholder="trigger perc value"
									className="form-control form-control-sm text-white"
									defaultValue={100}
									max={100}
									prefix="%"
									style={{'fontSize':'1.125rem', 'textAlign':'center', 'fontWeight':'bold'}}
									value={mdlState.closePerc}
									decimalsLimit={2}
									onValueChange={handleCloseInputChange} />								
							</div>
							<input type="range" className="form-range" value={mdlState.closePerc || 0}
								onChange={e => handleCloseInputChange(e.target.value, 'closePerc')} min="0" max="100" step="0.1" id="customRange3" />
							<div className="d-flex" style={{'justifyContent': 'space-between','display': 'flex','flexWrap': 'wrap'}}>
							<button className="btn btn-sm btn-outline-warning" onClick={e => handleCloseInputChange(0, 'closePerc')} >0%</button>
								<button className="btn btn-sm btn-outline-warning" onClick={e => handleCloseInputChange(25, 'closePerc')} >25%</button>
								<button className="btn btn-sm btn-outline-warning" onClick={e => handleCloseInputChange(50, 'closePerc')} >50%</button>
								<button className="btn btn-sm btn-outline-warning" onClick={e => handleCloseInputChange(75, 'closePerc')} >75%</button>
								<button className="btn btn-sm btn-outline-warning" onClick={e => handleCloseInputChange(100, 'closePerc')} >100%</button>
							</div>
						</td></tr>
					</tbody>
				</table>
			</div>

			</Modal.Body>
			<Modal.Footer style={{ 'background': '#151720', 'border': 0 }}>
				<Button size="sm" variant="danger" onClick={() => onHide()}>
					Cancel
				</Button>
				<Button size="sm" variant="primary" onClick={CloseBut}>
					Close Position
				</Button>
			</Modal.Footer>
		</Modal>
	</>);
};
