import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePostApiAuthResetPasswordMutation } from '../../api/authApi';
import { useEffect } from 'react';
import FormInput from '../../components/FormInput';
import { FormProvider, SubmitHandler, useForm,  } from 'react-hook-form';
import { Turnstile } from '@marsidev/react-turnstile'
import type { TurnstileInstance } from '@marsidev/react-turnstile'
import React from 'react';

export type ResetPasswordModel = {
    password: string;
    password_again: string;
    
}

const TURNSTILESITEKEY = process.env.REACT_APP_TURNSTILESITEKEY as string;

const ForgotPasswordAcceptPage : React.FC = () => {
    
    const [searchParams] = useSearchParams();
	
    const email = searchParams.get("email");
    const code  = searchParams.get("code");
    const userId  = searchParams.get("userId");
    
	const navigate = useNavigate();

    const [resetPassword, {isLoading, isSuccess, isError }] = usePostApiAuthResetPasswordMutation()

    useEffect(()=>{
        if(isSuccess==true)
            navigate('/login');

    },[isSuccess]);

    const  fpMethods = useForm<ResetPasswordModel>({
        defaultValues:{
            password:'',
            password_again:''
        }
        
      });

    

  const { reset, handleSubmit, formState } = fpMethods;

  const onSubmitResetPasswordHandler: SubmitHandler<ResetPasswordModel> = (values) => {
    try {
        const t = ref.current?.getResponse();
        resetPassword({ 
            resetPassword: {
                email: email!, 
                password: values.password, 
                confirmPassword:values.password_again, 
                code: code,
                token:t
            }
        });
    }
    catch(ex) {
        console.warn(ex);
        console.trace();
      }
    ref?.current?.reset();
  };
  const ref = React.useRef<TurnstileInstance>(null);
    return (
    
        <main className="site-content login-page" id="wrapper">
            <Row>             
                <Col md={6} className="d-none d-sm-block center-logo">
                        <img onClick={e => console.log('suicide')} src="/assets/img/logo@2x.png" alt="" />             
                </Col>
                <Col md={6} style={{'justifyContent':'center'}}>
            <FormProvider {...fpMethods}>
                <Form className="form login-form"            
                    onSubmit={ handleSubmit(onSubmitResetPasswordHandler) }
                    noValidate
                    autoComplete='off'                 
                >
                        <h4>Reset your Password</h4>
                        <h5>{email}</h5>
                        <FormInput name='password'  label='' type='password' placeholder={'Password *'} invalid={''} />
                        <FormInput name='password_again' label='' type='password' placeholder={'Password Again'} invalid={''} />
                        <Turnstile ref={ref} siteKey={TURNSTILESITEKEY} />
                        <button className="btn btn-block login-form__button" disabled={isLoading} >Reset Password
                        {isLoading &&  <span className="spinner-grow spinner-grow-sm"></span>}
                        </button>
                </Form>
            </FormProvider>

                    { isSuccess && 
                        <h2 className="h4">Password Reset Completed. Please login now.</h2>
                    }
                    {
                        isError && 
                        <h2 className="h4">Password Reset Failed! Please try again later.</h2>
                    }
                    { isLoading &&
                        <h2 className='h4'>Please wait...</h2>
                    }
                    {
                        (code == undefined ) &&
                            <h2 className='h4'>Missing Arguments...</h2>
                    }
                </Col>                     
            </Row>        
        </main>
    )

};


export default ForgotPasswordAcceptPage;