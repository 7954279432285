import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLazyGetApiChannelGetPositionProviderByIdProvidersQuery, 
    useLazyGetApiPositionGetPositionByIdQuery, 
    usePostApiPositionClosePositionPartialByIdClosepartialpositionMutation, 
    usePostApiPositionCreatePositionTakeProfitByIdTakeprofitMutation, 
    usePutApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitIdMutation} from "../../api/positionApi";
import CurrencyView from "../../components/CurrencyView";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import { ITraderPagePositionTPModal, ITakeProfit } from "./interfaces/ITraderPage";


export const TraderPagePositionTPModal = ({ isOpen, qty, price, posId, onHide }: { isOpen: boolean; price: number; posId: number; qty: number; onHide: () => void; }) => {

	const [createPosTP, { isSuccess: createPTPSuc, isError: createPTPErr, error: createPTPError }] = usePostApiPositionCreatePositionTakeProfitByIdTakeprofitMutation();	
    const [updatePosTP, { isSuccess: updatePTPSuc, isError: updatePTPErr, error: updatePTPError }] = usePutApiPositionUpdatePositionTakeProfitByIdTakeprofitAndTakeProfitIdMutation();	
    const [getPosById, { data: getPostData, isSuccess: getPosSuc, isError: getPosIsErr, error: getPosErr  }] = useLazyGetApiPositionGetPositionByIdQuery();
    
    const [pageState, setPageState] = useState<ITraderPagePositionTPModal>({ 				
		tpList:[],
		tpNew: { id:undefined, closePerc:undefined, closePrice:undefined},
	});


    useEffect( ()=> {
        if(getPosSuc && getPostData && getPostData.data) {
            const posed = getPostData.data;
            if(posed.takeProfits &&  posed.takeProfits.length>0) {
                const ts = posed.takeProfits.map( (p) => ({
                        closePerc: p.profitPercentageClosed,
                        closePrice: p.protfitPrice,
                        id: p.id
                    } as ITakeProfit))
                    setPageState({ 
                        tpList: ts, 
                        tpNew: {id:undefined, closePerc: undefined, closePrice: undefined }
                     });
            }
        }
    },[getPosSuc]);
    useEffect( () =>  {
		if(isOpen==true)
        	getPosById({ id: posId}); 
    },[isOpen]);


	const removeTPItem = async (index: number) => {
		const updatedTpList = [...pageState.tpList];
        const tp = pageState.tpList[index];
        const tpr = await updatePosTP({ id: posId, takeProfitId: tp.id!, positionTakeProfitModel: { active:false } })
        if('data' in tpr) {
            if(tpr.data.ok===true) { 
		    updatedTpList.splice(index, 1);
		    setPageState({ ...pageState, tpList: updatedTpList });
            }
        }
	};

	const addTPItem = async () => {
		if (pageState.tpNew.closePerc != undefined && pageState.tpNew.closePrice != undefined) {
			if(parseFloat(pageState.tpNew.closePerc)>0 && parseFloat(pageState.tpNew.closePrice)>0) {

                const res = await createPosTP({ id: posId, positionTakeProfitModel: {
                    protfitPrice : parseFloat(pageState.tpNew.closePrice),
                    profitPercentageClosed: parseFloat(pageState.tpNew.closePerc)
                }});

                if('data' in res ){
                    if(res.data.ok===true) {
                        setPageState({
                            ...pageState,
                            tpList: [...pageState.tpList, pageState.tpNew],
                            tpNew: { id:undefined,closePerc: undefined, closePrice: undefined }
                        });
                    }
                }
			}
		}
	};

    const handleTPInputChange = (value:any, name:any, ...rest:any[]) => {
		console.log("change", name, value, rest);
		if(name==undefined) return ;
		if(value > 100 && name==="closePerc") value=100;
		setPageState({
			...pageState,
			tpNew: {
			...pageState.tpNew,
			[name]: value
			}
		});
	};

	useEffect(() => {

		if (createPTPSuc) {
			//onHide();
		}
		if (createPTPErr) {
			toast.error("Unable to update position!");
		}
	}, [createPTPErr, createPTPSuc]);


	
	return (<>
		<Modal show={isOpen} centered={true} onHide={onHide} className="match-stats-widget match-stats-widget--general" >
			  <Modal.Header  closeButton style={{'padding':'.5rem', 'border':0, 'background':'#151720'}}>
				<div className="match-stats-widget__header" style={{'color':'white'}}>
					Add Take Profit Levels
					</div>
			  </Modal.Header>
			  <Modal.Body style={{ 'background':'#151720'}}>				
			  <div className="table-responsive-sm">
				<table className="table matches-table table-dark table-insite table-order">
					<tbody>
					<tr>
						<td></td>
						<td style={{'textAlign':'right'}}>Price: ${price}</td>
					</tr>
					<tr><td colSpan={2}>
					<table className="match-overview__table w-100">
						<thead>
							<tr>
								<th>Close Percentage</th>
								<th>Close Price</th>
							</tr>
						</thead>
						<tbody>
						{pageState.tpList.map((item, index) => (
						<tr key={index}>
							<td style={{'paddingLeft':'1rem'}}>%{item.closePerc}</td>
							<td style={{'paddingLeft':'1rem'}}>${item.closePrice}</td>
							<td>
								<button className="btn btn-sm btn-danger" onClick={() => removeTPItem(index)}>Remove</button>
							</td>
						</tr>						
						))}
						</tbody>
					</table>
					</td></tr>

					<tr><td>				
					<label className="text-white d-block p-2">Take Profit - Trigger Price</label>
					<div className="input-group">

					<CurrencyInput
					id="trigger-price"
					name="closePrice"
					placeholder="trigger value"
					className="form-control form-control-sm text-white"
					defaultValue={0}
					value={pageState.tpNew.closePrice}
					decimalsLimit={8}
					prefix="$"
					onValueChange={handleTPInputChange}
					/>
					<span className="input-group-text" id="basic-addon2">$</span>
					</div>								
					<input type="range" className="form-range" 
					onChange={e => 
					{ 
						if(price>0.0) {
							handleTPInputChange( ( price * parseFloat(e.target.value)) / 100.0, 'closePrice');
						}
					}
				 } min="0" max="500" step="1" id="customRange3"></input>
					</td><td>
					<label className="text-white d-block p-2">Close Percentage</label>
					<div className="input-group">

					<CurrencyInput
					id="trigger-perc"
					name="closePerc"
					placeholder="trigger perc value"
					className="form-control form-control-sm text-white"
					defaultValue={0}
					max={100}
					value={pageState.tpNew.closePerc}
					decimalsLimit={2}
					
					onValueChange={handleTPInputChange}
					/>
					<span className="input-group-text" id="basic-addon2">%</span>
					</div>									
					<input type="range" className="form-range" value={pageState.tpNew.closePerc||0} 
					onChange={e => handleTPInputChange(e.target.value, 'closePerc') } min="0" max="100" step="0.1" id="customRange3"></input>
			
					</td></tr>	
			
			<tr><td colSpan={2}>	<Button size="sm" variant="warning" onClick={e => addTPItem() }>Add </Button></td></tr>

				</tbody>
				</table>
				</div>	
					
			  </Modal.Body>
			  <Modal.Footer style={{'background':'#151720', 'border':0}}>				
				<Button size="sm" variant="secondary" onClick={onHide}>
				  Close
				</Button>
			  </Modal.Footer>
			</Modal>
	</>);
};
