import React from "react";
import useAnimationFrame from "../hooks/useAnimationFrame";
import useMousePosition from "../hooks/useMousePosition";

const Preloader = () => {

    const ump = useMousePosition();
  
    useAnimationFrame(() => {      
      const  el = document.getElementById("df-custom-cursor")
      if(el != null) {        
        el.style.transform = "translate("+ump.x+"px,"+ump.y+"px)";
      } else console.warn("df-custom-cursor it not found.");
      
  });
  

  
    return(<React.Fragment><div className="preloader-overlay">
    <div id="js-preloader" className="preloader">
      <div className="preloader-inner fadeInUp">
        <div className="pong-loader"></div>
        <svg role="img" className="df-icon df-icon--preloader-arcade">
          <use xlinkHref="/assets/img/necromancers.svg#preloader-arcade"/>
        </svg>
      </div>
    </div>
  </div>
   <p style={{'display':'none'}}>{ump.x}-{ump.y}</p>
   <div className="df-custom-cursor-wrap">
     <div id="df-custom-cursor"></div>
   </div></React.Fragment>)
}

export default Preloader;