import { Container } from "react-bootstrap"
import { Link } from "react-router-dom";
import NavLinkC from "../../components/NavLinkC";

const LandingNavigationRight = () => {
    return(<nav className="main-nav">
	<ul className="main-nav__list">

		<li className="">
			<NavLinkC to={'./tools'}>Tools</NavLinkC>
			<ul className="main-nav__sub">				
				<li className=""><NavLinkC to={'./tools/poster'}>Poster</NavLinkC></li>				
				<li className=""><NavLinkC to={'./tools/pnl'}>PNL</NavLinkC></li>				
				<li className=""><NavLinkC to={'./tools/target-price'}>Target Price</NavLinkC></li>				
				<li className=""><NavLinkC to={'./tools/liquidation-price'}>Liquidation Price</NavLinkC></li>				
				<li className=""><NavLinkC to={'./tools/max-open'}>Max Open</NavLinkC></li>				
				<li className=""><NavLinkC to={'./tools/open-price'}>Open Price</NavLinkC></li>				
				<li className=""><NavLinkC to={'./tools/risk-reward'}>Risk Reward</NavLinkC></li>				
			</ul>
		</li>	

		<li className=""><NavLinkC to={'./learn'}>Learn</NavLinkC>
			<ul className="main-nav__sub">				
				<li className=""><NavLinkC to={'./learn/guides'}>Guides</NavLinkC></li>				
				<li className=""><NavLinkC to={'./learn/glossary'}>Glossary</NavLinkC></li>				
			</ul>
            <span className="main-nav__toggle">&nbsp;</span>
		</li>

		<li className=""><NavLinkC to={'./trader'}>Trader</NavLinkC>
                <ul className="main-nav__sub">
                    <li className=""><NavLinkC to={'./trader/best'}>Hall of Fame</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./trader/getting-started'}>Trade Now</NavLinkC></li>
					<li className=""><NavLinkC to={'./trader/channels'}>My Channels</NavLinkC></li>	
                   				                    
                </ul>
				<span className="main-nav__toggle">&nbsp;</span>
            </li>
		


	</ul>
</nav>);
}

export default LandingNavigationRight;