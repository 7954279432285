import { Container } from "react-bootstrap"

const SearchPanel = () => {

    return(<div className="search-panel">
			<div className="search-panel__content">
				
				<span>Write what you are looking for, choose the filters and press enter to begin your search!</span>
			</div>
		</div>)
    }

export default SearchPanel;


/*
<form action="#" id="header-search-form" className="search-form search-form--header">
					<input type="text" className="form-control" value="" placeholder="What are you looking for...?" />
				</form> */