import React from 'react';
import SimplePage from './simple.page';

const PrivacyPolicy: React.FC = () => {
  return (
    <SimplePage title='Privacy Policy'>
      <div>
      <h1>Privacy Policy</h1>
      <p>Last Updated: 2024-07-15</p>

      <p>This Privacy Policy explains how coinsynthesis.com ("we," "our," or "us") collects, uses, discloses, and protects your information when you use coinsynthesis.com ("the App").</p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> When you register on the App, we may collect personal information such as your name, email address, profile photo, Twitter account, and Telegram account.</li>
        <li><strong>Usage Data:</strong> We may collect information on how you access and use the App, including your interactions with other users, the channels you create, and the positions you publish.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>To provide and maintain the App.</li>
        <li>To notify you about changes to the App.</li>
        <li>To allow you to participate in interactive features of the App when you choose to do so.</li>
        <li>To provide customer support.</li>
        <li>To gather analysis or valuable information so that we can improve the App.</li>
        <li>To monitor the usage of the App.</li>
        <li>To detect, prevent, and address technical issues.</li>
      </ul>

      <h2>3. Disclosure of Your Information</h2>
      <ul>
        <li>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law.</li>
        <li>We may disclose your information to comply with a legal obligation or protect and defend our rights or property.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We use commercially reasonable measures to protect your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

      <h2>5. Third-Party Links</h2>
      <p>The App may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.</p>

      <h2>6. Children's Privacy</h2>
      <p>The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from children under 13, we will take steps to delete such information.</p>

      <h2>7. User Responsibilities</h2>
      <ul>
        <li>Users are fully responsible for all content they upload, create, write, or post on the App.</li>
        <li>Users are solely responsible for any actions they take on the App and any consequences resulting from those actions. This includes any damages, slogans, or bad behaviors.</li>
      </ul>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at info@coinsynthesis.com.</p>
    </div>
    </SimplePage>
  );
};

export default PrivacyPolicy;
