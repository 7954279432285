import { useEffect } from "react";


const useBodyClassnames = (classnames:string[]) => {
    useEffect(() => {
      // Add classnames on mount
      console.log("adding classes...")
      classnames.forEach((classname) => {
        document.body.classList.add(classname);
      });
  
      // Remove classnames on unmount
      return () => {
        console.log("removing  classes...")
        classnames.forEach((classname) => {
          document.body.classList.remove(classname);
        });
      };
    }, []);
  };

export default useBodyClassnames;