import { Container } from "react-bootstrap"

const CartPanel = () => {

    return(<div className="cart-panel">
			<h4 className="cart-panel__title text-white">Shopping Cart (<span className="cart-panel__items-count">4</span>)</h4>
			
		</div>
        )
}


export default CartPanel;    

/*<div className="cart-panel__content">
				<div className="table-responsive">
					<table className="table shop-table">
						<thead>
							<tr>
								<th className="product__info">Product</th>
								<th className="product__quantity">Quantity</th>
								<th className="product__total">Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="product__info">
									<figure className="product__thumb">
										<a href="#"><img src="assets/img/cart-panel-img-01.jpg" alt=""/></a>
									</figure>
									<div className="product__info-content">
										<h5 className="product__name"><a href="#">Magimons Black Frame Print</a></h5>
										<span className="product__cat">Framed Prints</span>
										<span className="product__info-details">24"x12" | Glossy</span>
									</div>
								</td>
								<td className="product__quantity">
									<div className="quantity-control">
										<button className="quantity-control__minus">&nbsp;</button>
										<input type="number" step="1" value="1" size={4}/>
										<button className="quantity-control__plus">&nbsp;</button>
									</div>
								</td>
								<td className="product__total"><span className="product__total-currency">$</span>38.00</td>
								<td className="product__remove"><a href="#" className="product__remove-icon"></a></td>
							</tr>
							<tr>
								<td className="product__info">
									<figure className="product__thumb">
										<a href="#"><img src="assets/img/cart-panel-img-02.jpg" alt=""/></a>
									</figure>
									<div className="product__info-content">
										<h5 className="product__name"><a href="#">Necromancers Men's T-Shirt</a></h5>
										<span className="product__cat">Clothing</span>
										<span className="product__info-details">Black | Medium</span>
									</div>
								</td>
								<td className="product__quantity">
									<div className="quantity-control">
										<button className="quantity-control__minus">&nbsp;</button>
										<input type="number" step="1" value="2" size={4} />
										<button className="quantity-control__plus">&nbsp;</button>
									</div>
								</td>
								<td className="product__total"><span className="product__total-currency">$</span>49.98</td>
								<td className="product__remove"><a href="#" className="product__remove-icon"></a></td>
							</tr>
							<tr>
								<td className="product__info">
									<figure className="product__thumb">
										<a href="#"><img src="assets/img/cart-panel-img-03.jpg" alt=""/></a>
									</figure>
									<div className="product__info-content">
										<h5 className="product__name"><a href="#">Necromancers Ennamel Mug</a></h5>
										<span className="product__cat">Coffee Mugs</span>
										<span className="product__info-details">White</span>
									</div>
								</td>
								<td className="product__quantity">
									<div className="quantity-control">
										<button className="quantity-control__minus">&nbsp;</button>
										<input type="number" step="1" value="1" size={4} />
										<button className="quantity-control__plus">&nbsp;</button>
									</div>
								</td>
								<td className="product__total"><span className="product__total-currency">$</span>16.00</td>
								<td className="product__remove"><a href="#" className="product__remove-icon"></a></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="cart-panel__totals">
					<div className="table-responsive">
						<table className="table">
							<tfoot>
								<tr className="cart-panel__subtotal">
									<th>Cart Subtotal</th>
									<td>
										<span className="amount"><span className="amount-currency">$</span>103.98</span>
									</td>
								</tr>
								<tr className="cart-panel__shipping">
									<th>Estimated Shipping</th>
									<td><span className="amount"><span className="amount-currency">$</span>14.00</span></td>
								</tr>
								<tr className="cart-panel__order-total">
									<th>Cart Total</th>
									<td><span className="amount"><span className="amount-currency">$</span>117.98</span></td>
								</tr>
								</tfoot>
						</table>
						<a href="shop-checkout.html" className="btn btn-primary">Go to checkout</a>
					</div>
				</div>
			</div> */