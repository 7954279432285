import React, { useState, useEffect } from 'react';

type PriceDisplayProps = {
  value: number | undefined;
  sign: string,
  pre: boolean,
  sta?: boolean
};

const customComparator = (prevProps:PriceDisplayProps, nextProps:PriceDisplayProps) => {
    return nextProps.value === prevProps.value;
  };

const PriceDisplay: React.FC<PriceDisplayProps> = React.memo(({ value, sign, pre, sta }) => {
  const [previousValue, setPreviousValue] = useState<number | undefined>();
  const [color, setColor] = useState<string>('black');
  const [cn,setCn] = useState("");
  const [timeId, setTimeId] = useState<NodeJS.Timeout | null>(null);
  
  const formatter2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits:2,
    //notation:'compact'
  });
  const formatter4 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits:4,
    //notation:'compact'
  });
  const formatter8 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits:10,
    //notation:'compact'
  });

  const sequ = (val: number) : string  => {
    if(sign=='%') return val.toFixed(2);
    let sz =8;
    if(val>100) sz = 2;
    if(val>1) sz=4;
    
    return sz == 2 ? formatter2.format(val) : sz == 4 ?  formatter4.format(val) :  formatter8.format(val)  ;
  
  }


  useEffect(() => {
    if(sta === true) {
      return;
    }
    if(timeId!=null)
        clearTimeout(timeId);

    if (previousValue !== null && value !== previousValue) {
        const m = previousValue && value && value > previousValue ? 'green' : value == previousValue ? 'white' : 'red'
      setColor(m);
      setCn(m =="green" ? "bgup" : m=="red" ? "bgdown" : "")
      setTimeId( setTimeout(()=> setCn(""),600) );
    }
    setPreviousValue(value);
  }, [value]);

  const c = 'standings-table__ani '+cn+' '+ ( color == "red" ? "standings-table__losses" : color=="green" ? "standings-table__wins":"standings-table__none")

  if(pre)
    return <span className={c} style={{'padding':'5px'}} >{value &&  (sign=='$' ? '' : sign)+''+sequ(value)}</span>;
   else
    return <span className={c} style={{'padding':'5px'}}>{value &&  sequ(value)+''+sign}</span>;
}, customComparator);

export default PriceDisplay;
