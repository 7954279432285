import { Button, ButtonGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { usePostApiChannelGetInviteMutation, usePostApiChannelInviteResponseMutation } from "../../api/channelApi";
import { useEffect, useState } from "react";
import TimeAgo from 'react-timeago'
import { toast } from "react-toastify";
const InvitePage = () => {


    const { invitecode } = useParams();

    const [ getInvite, {data , error, isError, isLoading }] = usePostApiChannelGetInviteMutation({

    });

    const [resped, setResped] = useState<boolean>(false);

    const [ reply, { isLoading: replyL, isSuccess: replyS, isError: replyE} ] = usePostApiChannelInviteResponseMutation();

    useEffect(()=>{
        getInvite({ inviteCode: invitecode });
    },[invitecode]);

    const submitReply = async (res: boolean) => {
         await reply({ accepted: res, comment:res? 'Accepted':'Rejected', inviteCode: invitecode });
        
    }

    useEffect(()=>{
        if(replyS){
            toast.success("Invitation accepted")
        }
        if(replyE){
            toast.error("Invitation rejected")
        }
    },[replyS, replyE])

    useEffect(()=>{
        if(error){
            console.warn("err", error);
        }
        if(data){
            console.warn("data",data,data.data?.answer !== null);
            setResped( data.data?.answerDate != null);

        }
        
    },[data, error]);

    return (<>
    <main className="site-content team-info-page page" id="wrapper">
                <div className="container container--large">   
				<div className="team-carousel">
                <div className="team-carousel__content slick-initialized slick-slider slick-dotted slick-vertical">
                    <div className="team-carousel__item slick-slide slick-current slick-active">
            <div className="row">
                <div className="col-lg-11">
                    <h3 className="player-info-subtitle h4 text-uppercase">Trader</h3>
                    <h2 className="player-info-title h1 text-uppercase">Invitation</h2>
                    <div className="row">
                        <div className="col-6 col-md-6 col-xl-4">
                            <div className="player-info-detail">
                                <div className="player-info-detail__label">Channel</div>
                                <div className="player-info-detail__title">{data?.data?.toChannel}</div>
                            </div>
                        </div>
                    <div className="col-6 col-md-6 col-xl-4">
                        <div className="player-info-detail">
                            <div className="player-info-detail__label">Join As</div>
                            <div className="player-info-detail__title">{isError ? "" :  data?.data?.inivteAsTrader ? "Trader" : "Member"}</div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-xl-4">
                        <div className="player-info-detail">
                            <div className="player-info-detail__label">Until</div>
                            <div className="player-info-detail__title"><TimeAgo date={data?.data?.expireDate!}  /></div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        
                <div className="col-12 col-md-12 col-xl-12">
                {isError && <h3 className="text-danger">Invitation code is invalid</h3>}
                {resped && <h3 className="text-danger">Invitation code responsed already</h3>}
                    <div className="player-info-detail">
                        <ButtonGroup>
                            <Button size="sm" disabled={isError || resped || replyL} onClick={() => submitReply(true)} variant="primary">Accept</Button>
                           
                            <Button size="sm" disabled={isError || resped || replyL} onClick={()=> submitReply(false)} variant="danger">Reject</Button>
                            </ButtonGroup>
                    </div>
                    <h5 className="text-muted">{invitecode}</h5>
                </div>
                </div>
            
            
            </div>
        </div>
    
        
        </div>
        </div>
                </div>
              
        </div>
        </main>
    </>)


};


export default InvitePage;