import { Container } from "react-bootstrap"
import { Link, NavLink } from "react-router-dom";
import NavLinkC from "../../components/NavLinkC";

const LandingNavigationLeft = () => {
    return(<nav className="main-nav">
    <ul className="main-nav__list">
		<li className=""><NavLinkC to={'./'}>Home</NavLinkC></li>			
		<li className=""><NavLinkC to={'./currencies'}>Cryptocurrencies</NavLinkC>
			<ul className="main-nav__sub">
				<li className=""><NavLinkC to={'./currencies/spot'}>Ranking</NavLinkC></li>								
				<li className=""><NavLinkC to={'./currencies/currenciesgainerlooser'}>Gainers & Losers</NavLinkC></li>				
				<li className=""><NavLinkC to={'./currencies/changes'}>Live Changes</NavLinkC></li>				
			</ul>
			<span className="main-nav__toggle">&nbsp;</span>
		</li>		
	<li className=""><NavLinkC to={'./exchanges'}>Exchanges</NavLinkC>
			<ul className="main-nav__sub">
					<li className=""><NavLinkC to={'./exchanges/cryptoexchanges'}>Crypto Exchanges</NavLinkC></li>				
                    <li className=""><NavLinkC  to={'./exchanges/dexexchanges'}>Decentralized Exchanges</NavLinkC></li>
                    						
			</ul>
			<span className="main-nav__toggle">&nbsp;</span>
		</li>			
	</ul>
    </nav>);
}

export default LandingNavigationLeft;