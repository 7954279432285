import React from 'react';

const DURATION = 10;

const throttle = (function() {
  let timeout: NodeJS.Timeout | undefined = undefined;
  return function throttle(callback:any) {
    if (timeout === undefined) {
      callback();
      timeout = setTimeout(() => {
        // allow another call to be throttled
        timeout = undefined;
      }, DURATION);
    }
  }
})();

/**
 * Wraps callback in a function and throttles it.
 * @returns Wrapper function
 */
function throttlify(callback:any) {
  return function throttlified(event:any) {
    throttle(() => {
      callback(event);
    });
  }
}

function createMousePosition(x:number, y:number) {
  return { x, y };
}

function useMousePosition(){
  const [mousePosition, setMousePosition] = React.useState(createMousePosition(0, 0));

  // i absolutely don't want to rerun this hook at any other time
  // then initial mount and last unmount
  React.useEffect(() => {
    const saveMousePosition = throttlify((event:any) => {
      setMousePosition(createMousePosition(event.clientX, event.clientY));
    });

    document.addEventListener('mousemove', saveMousePosition);
    return () => {
      document.removeEventListener('mousemove', saveMousePosition);
    };
  }, [setMousePosition]);

  return mousePosition;
}

export default useMousePosition;