import { configureStore, ThunkAction, Action, Middleware } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import traderReducer from '../features/trader/traderSlice';
import greetReducer from '../features/greet/greetSlice';
import configReducer from '../features/config';
import { authApi } from './api/authApi';
import { channelApi } from './api/channelApi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { tagApi } from './api/tagApi';
import { exchangeApi } from './api/exchangeApi';
import { coinApi } from './api/coinApi';
import { datatickerApi } from './api/datatickerApi';
import { greetApi } from './api/greetApi';
import { positionApi } from './api/positionApi';
import { telegramApi } from './api/telegramApi';
import { debounce } from 'lodash';
import loggerMiddleware from './loggerMiddleware';

import userNickReducer from '../features/userNick/userNickSlice';

const KEY = "cs.appstate";
function loadState() {
  
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    const o = JSON.parse(serializedState);
    return  {
      auth: o["auth"],
      config: o["config"]
    };
  } catch (e) {
    return undefined;
  }
}

async function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}




export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [channelApi.reducerPath]: channelApi.reducer,
    [coinApi.reducerPath]: coinApi.reducer,
    [exchangeApi.reducerPath]: exchangeApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
    [datatickerApi.reducerPath]: datatickerApi.reducer,
    [greetApi.reducerPath]: greetApi.reducer,    
    [positionApi.reducerPath]: positionApi.reducer,    
    [telegramApi.reducerPath]: telegramApi.reducer,    
    counter: counterReducer,
    auth: authReducer,
    greet: greetReducer,
    config: configReducer,
    tader: traderReducer,
    userNick: userNickReducer
  },
  devTools: process.env.NODE_ENV === 'development',
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(
      [
        authApi.middleware,
        channelApi.middleware,
        coinApi.middleware,
        exchangeApi.middleware,
        tagApi.middleware,
        datatickerApi.middleware,
        positionApi.middleware,
        greetApi.middleware,
        telegramApi.middleware,
        loggerMiddleware
      ]),
});

store.subscribe(  
  debounce(() => {  
    const st = store.getState();      
      saveState(st);    
  }, 500)
);



export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


